import { collection, deleteField, doc, onSnapshot, query, QuerySnapshot, serverTimestamp, updateDoc } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-bootstrap'
import { Helmet } from 'react-helmet-async'
import { ButtonTW } from 'src/components/Buttons/ButtonTW'
import { CheckboxSwitch } from 'src/components/Buttons/CheckboxSwitch'
import { EditableField } from 'src/components/EditableField/EditableField'
import { EditableFieldTypeahead } from 'src/components/EditableFieldTypeahead/EditableFieldTypeahead'
import { TypeaheadUserList } from 'src/components/FormControls/TypeaheadUserList'
import { getLoadingSpinnerOrNull } from 'src/components/Spinner/util_getLoadingSpinnerOrNull'
import { useAppContext } from 'src/hooks/useAppContext'
import { userDepartmentList, UserDetailsType, UserPermissionType, UserSimpleTeamUidType, userTeamCanHaveDepartmentList, userTeamList, userTeamNonEmployeeList } from 'src/types/types_user'
import { dateformatMDasMMMD, dateutcFormatMD } from 'src/util/dateformattools'
import { dateFormatUserFriendly } from 'src/util/datelayouttools'
import { getDateutc } from 'src/util/datetools'
import { getPermission_from_roles, permissionListAll, userrole_isDev, UserRoleOthersType, userRolesOtherList } from 'src/util/user_roles'
import { convertUserDetailsDates, serverTimestampAsDate } from 'src/util/util_firestoredates'
import { log_db_read, log_db_write } from 'src/util/util_log'
import { luhnCodePoints } from 'src/util/util_luhn'
import { saveProfilePhotoBase } from 'src/util/util_saveprofilephoto'
import { getUserListSimpleUid } from '../ExpenseSheet/util_getuserlist'
import { UserListEditPermissions } from './UserListEditPermissions'
import { UserListPermissionsSummary } from './UserListPermissionsSummary'
import './userlist.css'
import { useEmergencyPhoneList } from './util_emergencyphonelist'
import { getUserComparer } from './util_userlist'


function findDuplicates(arr: string[]) {
  // https://stackoverflow.com/a/840808/
  // edited to not return empty values
  const sorted_arr = arr.slice().sort();
  const results = [];
  for (let i = 0; i < sorted_arr.length - 1; i++) {
    if (sorted_arr[i] && sorted_arr[i] === sorted_arr[i + 1]) {
      results.push(sorted_arr[i]);
    }
  }
  return results;
}

export function UserList() {

  const { db, userDetails, storage, setDbError, cloudFunctions } = useAppContext()

  const [userDetailsList, setUserDetailsList] = useState<UserDetailsType[]>()
  const [permissionDict, setPermissionDict] = useState<Map<string, UserPermissionType>>()

  const [editedCell, setEditedCell] = useState<string | null>(null) // id of table + '_' + id of invoice being edited + '_' + name of field being edited

  const [showUserIds, setShowUserIds] = useState(false)
  const [showForcePhotoRefreshButton, setShowForcePhotoRefreshButton] = useState(false)

  const [filterList, setFilterList] = useState<string[] | null>(null) // null = show all

  // exclude 9 as it's reserved for users with no assigned code
  const possibleSingleCharacterCodes = luhnCodePoints.replace('9', '').split('')
  possibleSingleCharacterCodes.unshift('\u00A0') // to delete a user's single character code

  // get user list
  useEffect(() => {
    log_db_read({ db, userDetails, logkey: 'db_read.list_users', desc: 'List users' })

    const processSnapshot = function (snapshot: QuerySnapshot) {
      const users = snapshot.docs.map((doc) => {
        const user = { ...doc.data(), id: doc.id } as UserDetailsType
        convertUserDetailsDates(user)
        return user
      })
      users.sort(getUserComparer({ guidesFirst: false, sortByDepartment: true }))
      setUserDetailsList(users)
    }

    const q = query(collection(db, 'users'));
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting user list', err));

    return unsubscribe
  }, [db, setDbError, userDetails])


  // get permissions
  useEffect(() => {
    const processSnapshot = function (snapshot: QuerySnapshot) {
      // store permissions in dictionary
      const permissionDict = new Map<string, UserPermissionType>()

      for (const doc of snapshot.docs) {
        const permission = { ...doc.data(), id: doc.id } as UserPermissionType
        permissionDict.set(doc.id, permission)
      }

      setPermissionDict(permissionDict)
    }

    const q = query(collection(db, 'permissions'));
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting permission list', err));

    return unsubscribe
  }, [db, setDbError])

  const userListSimple = useMemo(() => getUserListSimpleUid(userDetailsList), [userDetailsList])

  const emergencyPhoneList = useEmergencyPhoneList()

  // *** all hooks above ***

  const loadingSpinner = getLoadingSpinnerOrNull([
    ['user list', userDetailsList && userListSimple],
    ['permissions', permissionDict],
    ['emergency phones', emergencyPhoneList],
  ])
  if (!userDetailsList || !userListSimple || !permissionDict || !emergencyPhoneList)
    return loadingSpinner

  const dupeSingleCharacterCodes = findDuplicates(userDetailsList.map((user) => user.singleCharacterCode || ''))


  const hasAllPermissionsDisabled = (user: UserDetailsType) => {
    if (user.roles.mainRole !== 'none') {
      return false
    }
    for (const permBool of Object.values(user.roles.roleFlags)) {
      if (permBool) {
        return false
      }
    }
    return true
  }

  const elevatedEmployees: { user: UserDetailsType, roles: { mainRole: string, roleFlags: UserRoleOthersType } }[] = []
  const issueList: { user: UserDetailsType, userIssues: string[] }[] = []
  for (const user of userDetailsList) {
    const userIssues = []

    const isNonEmployee =
      (userTeamNonEmployeeList as readonly string[]).includes(user.teamName)
      || user.email === 'support@80days.co.jp'
    // non-employee doesn't need freee number or name

    if (isNonEmployee) {
      // non-employee issues
      if (user.singleCharacterCode)
        userIssues.push(`Single character code [${user.singleCharacterCode}] assigned to non-employee`)
    } else {
      // employee issues
      if (!user.teamName)
        userIssues.push('No team assigned')
      if (!user.freeeNumber)
        userIssues.push('No Freee number assigned')
      if (!user.freeeName)
        userIssues.push('No Freee name assigned')
    }

    let isOfficeEmployee = false

    if (user.teamName === 'Guide') {
      if (user.roles?.mainRole !== 'role_guide') {
        userIssues.push('Guide should have permissions set to role_guide')
      }
    } else if (user.teamName === 'External accountant') {
      if (user.roles?.mainRole !== 'external_accountant') {
        userIssues.push('External accountant should have permissions set to external_accountant')
      }
    } else if (user.teamName === 'Former employees') {
      if (!hasAllPermissionsDisabled(user)) {
        userIssues.push('Former employees should have all permissions disabled')
      }
    } else if (user.teamName === 'Freelance guides') {
      if (!hasAllPermissionsDisabled(user)) {
        userIssues.push('Freelance guides should have all permissions disabled')
      }
    } else if (user.teamName === 'Other') {
      // mainly test accounts
    } else {
      // office employees
      isOfficeEmployee = true
    }

    if (!user.roles) {
      userIssues.push('No roles assigned')
    } else {
      const elevatedRoles: { mainRole: string, roleFlags: UserRoleOthersType } = { mainRole: '', roleFlags: {} }
      let isElevated = false
      if (isOfficeEmployee && user.roles.mainRole !== 'inputter') {
        elevatedRoles.mainRole = user.roles.mainRole
        isElevated = true
      }
      for (const key of userRolesOtherList) {
        if (user.roles.roleFlags[key]) {
          elevatedRoles.roleFlags[key] = true
          isElevated = true
        }
      }
      if (isElevated) {
        elevatedEmployees.push({ user, roles: elevatedRoles })
      }
    }


    if (userIssues.length > 0) {
      issueList.push({ user, userIssues })
    }
  }


  const userDetailsListByTab = new Map<string, UserDetailsType[]>()
  userDetailsListByTab.set('All', userDetailsList)
  userDetailsListByTab.set('Employees', userDetailsList.filter((user) => !(userTeamNonEmployeeList as ReadonlyArray<string>).includes(user.teamName)))
  userDetailsListByTab.set('CEO+Admin', userDetailsList.filter((user) => user.teamName === 'CEO' || user.teamName === 'Administration'))
  for (const teamName of ['Travel Designer', 'Operations']) {
    userDetailsListByTab.set(teamName, userDetailsList.filter((user) => user.teamName === teamName))
    for (const deptName of userDepartmentList.filter(x => x)) {
      userDetailsListByTab.set(`${teamName}/${deptName}`, userDetailsList.filter((user) => user.teamName === teamName && user.departmentName === deptName))
    }
  }
  userDetailsListByTab.set('Tour Leaders', userDetailsList.filter((user) => user.teamName === 'Guide'))

  userDetailsListByTab.set('Non-Employees', userDetailsList.filter((user) => (userTeamNonEmployeeList as unknown as string[]).includes(user.teamName)))
  for (const teamName of userTeamNonEmployeeList) {
    userDetailsListByTab.set(teamName, userDetailsList.filter((user) => user.teamName === teamName))
  }
  const unclassifiedUsers: UserDetailsType[] = []
  for (const user of userDetailsList) {
    if (![...userDetailsListByTab.entries()].some(([tabName, sublist]: [string, UserDetailsType[]]) => tabName !== 'All' && sublist.includes(user))) {
      unclassifiedUsers.push(user)
    }
  }
  if (unclassifiedUsers.length > 0) {
    userDetailsListByTab.set('Unclassified', unclassifiedUsers)
  }


  const userDetailsListFiltered = userDetailsList.filter((user) => {
    if (!filterList) return true

    for (const tabName of filterList) {
      if (userDetailsListByTab.get(tabName)!.includes(user)) return true
    }
    return false
  })


  return (
    <div className='p-4'>
      <Helmet><title>Admin Users</title></Helmet>
      <h3 className='mb-3'>User list</h3>

      <div>
        <h5>Single character codes table</h5>
        <table className='table singleCharacterCodesTable'>
          <thead>
            <tr>
              {luhnCodePoints.split('').map((code, index) => <th key={code}>{code}</th>)}
            </tr>
          </thead>
          <tbody>
            <tr>
              {luhnCodePoints.split('').map((code, index) => {
                const usersWithCode = userDetailsList.filter((user) => user.singleCharacterCode === code)
                let userString = usersWithCode.map((user) => user.displayNameEn).join(', ')
                if (code === '9')
                  userString = '[Reserved]'
                return <td key={code} className={usersWithCode.length > 1 ? 'text-danger' : ''}>{userString}</td>
              })}
            </tr>
          </tbody>
        </table>
      </div>

      <div style={{ display: 'flex', gap: '2em' }}>
        <CheckboxSwitch id='switchShowIds' label='Show user IDs' className='mb-3' checked={showUserIds} onChange={(e) => setShowUserIds(e.target.checked)} />

        {userrole_isDev(userDetails.roles) && (
          <CheckboxSwitch id='switchShowForceButton' label='Show button to force photo refresh' className='mb-3' checked={showForcePhotoRefreshButton} onChange={(e) => setShowForcePhotoRefreshButton(e.target.checked)} />
        )}
      </div>

      {issueList.length > 0 && (
        <section className='tw-py-8'>
          <h5 className='tw-text-red-800'>Outstanding issues that must be addressed</h5>
          <table className='tw-bg-red-200 [&>*>tr>*]:tw-p-3 [&>*>tr>*]:tw-border [&>*>tr>*]:tw-border-solid [&>*>tr>*]:tw-border-slate-500'>
            <thead>
              <tr className='tw-bg-red-300'>
                <th>User</th>
                <th>Role</th>
                <th>Department</th>
                <th>Issues</th>
              </tr>
            </thead>
            <tbody>
              {issueList.map((userEntry) => (
                <tr key={userEntry.user.id}>
                  <td>
                    <div>{userEntry.user.displayNameEn}</div>
                  </td>
                  <td>
                    {userEntry.user.teamName}
                  </td>
                  <td>
                    {userEntry.user.departmentName}
                  </td>
                  <td>
                    <ul>
                      {userEntry.userIssues.map((issue, index) => {
                        return <li key={index}>{issue}</li>
                      })}
                    </ul>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      )}

      <div className='tw-flex tw-gap-4 tw-flex-grow'>
        <nav className='__tabs tw-w-64 tw-basis-64 tw-shrink-0'>
          {[...userDetailsListByTab].map(([tabName, userList]) => {
            const isSubItem = tabName.includes('/')
            const label = isSubItem ? tabName.split('/')[1] : tabName
            let isSelected: boolean
            if (!filterList) {
              isSelected = tabName === 'All'
            } else {
              isSelected = filterList.includes(tabName)
            }
            const depth =
              tabName === 'All' ? 0
                : (tabName === 'Employees' || tabName === 'Non-Employees') ? 1
                  : isSubItem ? 3 : 2

            return (
              <div
                key={tabName}
                className={`tw-cursor-pointer ${isSelected ? 'tw-bg-blue-300 tw-font-bold' : ''}`}
                style={{ paddingLeft: `${depth * 0.75}em` }}
                onClick={(e) => {
                  if (tabName === 'All') {
                    setFilterList(null)
                  } else {
                    if (e.ctrlKey || e.metaKey) {
                      if (!filterList) {
                        setFilterList([tabName])
                      } else if (filterList.includes(tabName)) {
                        // remove it from list
                        setFilterList(filterList.filter((item) => item !== tabName))
                      } else {
                        // add it to list
                        setFilterList([...filterList, tabName])
                      }
                    } else {
                      setFilterList([tabName])
                    }
                  }
                }}>
                <i className='bi bi-chevron-right'></i>
                {label} ({userList.length})
              </div>
            )

          })}

          <div className='tw-pt-4'>
            Ctrl+Click to select multiple teams
          </div>
        </nav>

        <table className='table user-list'>
          <thead>
            <tr>
              {showUserIds && (
                <th>ID</th>
              )}
              <th>Photo</th>
              <th>Display name</th>
              <th>Full name</th>
              <th>E-mail</th>
              <th>Single character code</th>
              <th>Role</th>
              <th>Department</th>
              <th>Reports to</th>
              <th>Guide data</th>
              <th>Permissions</th>
              <th>Emergency phone</th>
              <th>Freee num.</th>
              <th>Freee name</th>
              <th>Date created</th>
              <th>Date modified</th>
              <th>Last login</th>
            </tr>
          </thead>
          <tbody>
            {userDetailsListFiltered.map((user, index) => {

              let permissionError = '';
              const permissionData = permissionDict.get(user.id)
              if (!permissionData) {
                // happens for new users just after they sign in for the first time
                permissionError += '[New user: No permissions defined] '
              } else {
                // 1. check the expected permissions are there and have the correct values
                for (const perm of permissionListAll) {
                  if (!(perm in permissionData)) {
                    permissionError += `[${perm} is missing] `
                  } else {
                    const expectedPerm = getPermission_from_roles(perm, user.roles)
                    if (permissionData[perm] !== expectedPerm) {
                      permissionError += `[${perm} is incorrect, expecting ${expectedPerm} but currently has ${permissionData[perm]}] `
                    }
                  }
                }

                // 2. check there are no excess permissions
                const keys = Object.keys(permissionData)
                const otherKeys = ['email', 'id', 'dateModified', '_aliasUid']
                for (const key of keys) {
                  if (!permissionListAll.find((p) => p === key) && !otherKeys.includes(key)) {
                    permissionError += `[excess permission ${key}] `
                  }
                }
              }

              const isDifferentTeam = index === 0 || userDetailsListFiltered[index - 1].teamName !== user.teamName

              const largePhotoDownloadURL =
                user.photoLargeFileDownloadURL
                || user.photoFileDownloadURL

              const isEmployee = user.teamName !== 'Other'
                && user.teamName !== 'External accountant'
                && user.teamName !== 'Former employees'

              return (
                <tr key={user.id} className={`${permissionError ? 'permission-error' : !user.email.endsWith('@80days.co.jp') ? 'non-80-days' : ''} ${isDifferentTeam ? 'thick-top-border' : ''}`}>
                  {showUserIds && (
                    <td className='__col_userid'>
                      <div>{user.id}</div>
                      <div>
                        <button className='tw-border-0 tw-underline tw-text-blue-700 tw-bg-transparent tw-p-0 tw-m-0' onClick={() => {
                          const updateObj: Partial<UserPermissionType> = {
                            _aliasUid: user.id,
                          }
                          updateDoc(doc(db, 'permissions', userDetails.id), updateObj)
                            .then(() => {
                              location.href = '/'
                            })
                        }}>Browse as this user</button>
                      </div>

                      <ButtonTW variant='link' className='tw-text-xs' onClick={() => {
                        setShowUserIds(false)
                      }}>hide id</ButtonTW>

                    </td>
                  )}
                  <td className='__col_photo'>
                    {/*we used to use user.photoURL but google was not happy and sending 403 'rate limit' errors, so now we get the image from cache*/}
                    {user.photoFileDownloadURL ? (
                      <a href={largePhotoDownloadURL} target='_blank' rel='noreferrer'>
                        <img width={48} height={48} style={{ border: '1px solid #888', width: '4em', height: '4em', overflow: 'hidden' }} src={user.photoFileDownloadURL} className='rounded-circle' alt={'' /*`Google profile pic of ${user.displayNameEn}`*/} />
                      </a>
                    ) : (

                      // button to manually cache the photo
                      // (used at the beginning when most users hadn't logged in since caching was implemented)
                      // (also used just after transfering 'users' collection from PROD to TEST and restoring the profile pics on TEST)
                      <button className='btn btn-outline-secondary btn-sm' onClick={(e) => {
                        const doOnServer = true
                        // When getting the photo on the client, we sometimes get CORS errors from Google.
                        // Doing it on the server bypasses these errors.
                        if (doOnServer) {
                          const func = httpsCallable(cloudFunctions, 'refreshPhotoIfNeeded')
                          func({ uid: user.id })
                            .then((result) => console.log('refreshPhotoIfNeeded result', result.data))
                        } else {
                          window.setTimeout(() => {
                            saveProfilePhotoBase(db, storage, user.id, user.email, user.photoURL)
                              .catch((err) => alert(`Error:\n  [${err}]\nURL:\n  [${user.photoURL}]`))
                          }, 1000)
                        }
                      }}>save</button>

                    )}

                    {showForcePhotoRefreshButton && (
                      <button className='btn btn-outline-secondary btn-sm' onClick={(e) => {
                        const func = httpsCallable(cloudFunctions, 'refreshPhotoIfNeeded')
                        func({ uid: user.id })
                          .then((result) => console.log('refreshPhotoIfNeeded result', result.data))
                      }}>force</button>
                    )}

                    {!showUserIds && (
                      <ButtonTW variant='link' className='tw-text-xs' onClick={() => {
                        setShowUserIds(true)
                      }}>show id</ButtonTW>
                    )}
                  </td>
                  <td className='__col_displayname'>
                    <EditableField
                      tableid='userlist'
                      rowid={user.id}
                      fieldname='displayNameEn'
                      validationType=''
                      currentValue={user.displayNameEn}
                      isClickableToEdit={userrole_isDev(userDetails.roles)}
                      editedCell={editedCell}
                      setEditedCell={setEditedCell}
                      useSpan={false}
                      callbackCommitChange={(value) => {
                        if (!value)
                          return

                        const updateObj: Partial<UserDetailsType> = {
                          displayNameEn: value,
                          dateModified: serverTimestampAsDate(),
                        }

                        updateDoc(doc(db, 'users', user.id), updateObj)
                        log_db_write({ db, userDetails, logkey: 'db_write.users.set_displayNameEn', desc: `Set user displayNameEn to [${value}] for user ${user.id} ${user.email}` })

                        setEditedCell('')
                      }}
                      hasButtonForEditing={true}
                    />
                  </td>
                  <td className='__full_name'>
                    <EditableField
                      tableid='userlist'
                      rowid={user.id}
                      fieldname='displayName'
                      validationType=''
                      currentValue={user.displayName}
                      isClickableToEdit={userrole_isDev(userDetails.roles)}
                      editedCell={editedCell}
                      setEditedCell={setEditedCell}
                      useSpan={false}
                      callbackCommitChange={(value) => {
                        if (!value)
                          return

                        const updateObj: Partial<UserDetailsType> = {
                          displayName: value,
                          dateModified: serverTimestampAsDate(),
                        }

                        updateDoc(doc(db, 'users', user.id), updateObj)
                        log_db_write({ db, userDetails, logkey: 'db_write.users.set_displayName', desc: `Set user displayName to [${value}] for user ${user.id} ${user.email}` })

                        setEditedCell('')
                      }}
                      hasButtonForEditing={true}
                    />
                  </td>
                  <td className='__col_email'>{user.email}{permissionError && (
                    <div>
                      <b>PERMISSION ERROR {permissionError}</b>
                    </div>
                  )}</td>
                  <td className={`__col_single_character_code ${(user.singleCharacterCode && dupeSingleCharacterCodes.includes(user.singleCharacterCode)) ? 'text-danger' : ''}`}>
                    <EditableFieldTypeahead
                      tableid='userlist'
                      rowid={user.id}
                      fieldname='singleCharacterCode'
                      currentValue={user.singleCharacterCode ?? ''}
                      isClickableToEdit={true}
                      editedCell={editedCell}
                      setEditedCell={setEditedCell}
                      callbackCommitChange={(dbvalue) => {
                        if (dbvalue === '\u00A0')
                          dbvalue = ''

                        const updateObj: Partial<UserDetailsType> = {
                          singleCharacterCode: dbvalue,
                          dateModified: serverTimestampAsDate(),
                        }
                        updateDoc(doc(db, 'users', user.id), updateObj)
                        log_db_write({ db, userDetails, logkey: 'db_write.users.set_singleCharacterCode', desc: `Set singleCharacterCode to [${dbvalue}] for user ${user.id} ${user.email}` })
                        setEditedCell('')
                      }}
                      typeaheadOptionsList={possibleSingleCharacterCodes}
                      // menuMinWidth='7.5rem'
                      hasButtonForEditing={true}
                    />
                  </td>
                  <td className='__col_role'>
                    <select
                      className='
                      tw-bg-gray-50 tw-border tw-border-solid tw-border-gray-300
                      tw-text-gray-900 tw-rounded-lg
                      focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-52 tw-p-2.5
                      dark:tw-bg-gray-700 dark:tw-border-gray-600 dark:tw-placeholder-gray-400 dark:tw-text-white dark:focus:tw-ring-blue-500 dark:focus:tw-border-blue-500
                    '
                      id={`dropdown_teamname_${user.id}`}
                      style={{ width: '10em' }}
                      value={user.teamName || ''}
                      onChange={(e) => {
                        const teamName = userTeamList.find((team) => team === e.target.value)
                        if (!teamName)
                          return
                        const updateObj: Partial<UserDetailsType> = {
                          teamName,
                          dateModified: serverTimestampAsDate(),
                        }
                        if (!userTeamCanHaveDepartmentList.includes(teamName)) {
                          updateObj.departmentName = ''
                        }
                        updateDoc(doc(db, 'users', user.id), updateObj)
                        log_db_write({ db, userDetails, logkey: 'db_write.users.set_teamName', desc: `Set teamName to [${e.target.value}] for user ${user.id} ${user.email}` })
                      }}>
                      {['', ...userTeamList].map((team, index) => {
                        return <option key={team} value={team}>{team}</option>
                      })}
                    </select>
                  </td>
                  <td className='__col_department'>
                    {userTeamCanHaveDepartmentList.includes(user.teamName) ? (
                      <select
                        className='
                        tw-bg-gray-50 tw-border tw-border-solid tw-border-gray-300
                        tw-text-gray-900 tw-rounded-lg
                        focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-52 tw-p-2.5
                        dark:tw-bg-gray-700 dark:tw-border-gray-600 dark:tw-placeholder-gray-400 dark:tw-text-white dark:focus:tw-ring-blue-500 dark:focus:tw-border-blue-500
                      '
                        value={user.departmentName || ''}
                        onChange={(e) => {
                          const deptName = userDepartmentList.find((dept) => dept === e.target.value)
                          if (!deptName)
                            return
                          const updateObj: Partial<UserDetailsType> = {
                            departmentName: deptName,
                            dateModified: serverTimestampAsDate(),
                          }
                          updateDoc(doc(db, 'users', user.id), updateObj)
                          log_db_write({ db, userDetails, logkey: 'db_write.users.set_departmentName', desc: `Set departmentName to [${e.target.value}] for user ${user.id} ${user.email}` })
                        }}>
                        {userDepartmentList.map((dept) => {
                          return (
                            <option key={dept}>{dept}</option>
                          )
                        })}
                      </select>
                    ) : user.departmentName ? (
                      <div className='tw-bg-red-300 tw-p-2'>
                        {user.departmentName}
                      </div>
                    ) : null}
                  </td>
                  <td className='__col_manager'>
                    <TypeaheadUserList
                      id={`select-manager-${user.id}`}
                      multiple={false}
                      onChange={(array: UserSimpleTeamUidType[]) => {
                        // remove from previous manager
                        if (user.manager) {
                          updateDoc(doc(db, 'users', user.manager.uid), {
                            [`directreports.${user.id}`]: deleteField(),
                          })
                        }
                        const selected = array.length ? array[0] : null
                        const updateObj: Partial<UserDetailsType> = {
                          manager: selected ? {
                            uid: selected.uid,
                            email: selected.email,
                            name: selected.name,
                          } : null,
                          dateModified: serverTimestampAsDate(),
                        }
                        updateDoc(doc(db, 'users', user.id), updateObj)

                        // add to new manager
                        if (selected) {
                          updateDoc(doc(db, 'users', selected.uid), {
                            [`directreports.${user.id}`]: true,
                          })
                        }

                        log_db_write({ db, userDetails, logkey: 'db_write.users.set_manager', desc: `Set manager to [${selected?.uid} ${selected?.email}] for user ${user.id} ${user.email}` })
                      }}
                      userList={userListSimple}
                      selected={user.manager ? userListSimple.filter((u) => u.uid === user.manager!.uid) : []}
                    />
                  </td>
                  <td className='__col_guide_data'>
                    {user.teamName === 'Guide' && (
                      <>
                        <div><span className='tw-text-slate-400'>Num:</span>{' '}
                          <EditableField
                            tableid='userlist'
                            rowid={user.id}
                            fieldname='guideNumer'
                            validationType='number'
                            currentValue={user.guideNumber ?? ''}
                            isClickableToEdit={true}
                            editedCell={editedCell}
                            setEditedCell={setEditedCell}
                            callbackCommitChange={(value) => {
                              const updateObj: Partial<UserDetailsType> = {
                                guideNumber: value,
                                dateModified: serverTimestampAsDate(),
                              }
                              updateDoc(doc(db, 'users', user.id), updateObj)
                              log_db_write({ db, userDetails, logkey: 'db_write.users.set_guide_number', desc: `Set guide number to [${value}] for user ${user.id} ${user.email}` })

                              setEditedCell('')
                            }}
                            hasButtonForEditing={false}
                            useSpan={true}
                          />
                        </div>
                        <div><span className='tw-text-slate-400'>Year starts on:</span>{' '}
                          <EditableField
                            tableid='userlist'
                            rowid={user.id}
                            fieldname='guideYearStartsOn'
                            validationType=''
                            currentValue={user.guideYearStartsOn ?? ''}
                            isClickableToEdit={true}
                            editedCell={editedCell}
                            setEditedCell={setEditedCell}
                            useSpan={true}
                            callbackCommitChange={(value) => {

                              const getFormatted = (value: string) => {
                                if (!value)
                                  return
                                const match = value.match(/^(\d{1,2})\/(\d{1,2})$/)
                                if (!match)
                                  return
                                const month = Number(match[1])
                                const day = Number(match[2])
                                if (month < 1 || month > 12 || day < 1 || day > 31)
                                  return
                                const formatted = `${month}/${day}`
                                const dateutc = getDateutc(2024, month, day)
                                const formatted2 = dateutcFormatMD(dateutc)
                                if (formatted !== formatted2)
                                  // prevents inputs such as 4/31
                                  return
                                return formatted
                              }

                              const formatted = getFormatted(value)
                              if (!formatted) {
                                alert('Invalid format. Please use MM/DD')
                                return
                              }
                              const updateObj: Partial<UserDetailsType> = {
                                guideYearStartsOn: formatted,
                                dateModified: serverTimestampAsDate(),
                              }
                              updateDoc(doc(db, 'users', user.id), updateObj)
                              log_db_write({ db, userDetails, logkey: 'db_write.users.set_guide_yearStart', desc: `Set guide year start to [${formatted}] for user ${user.id} ${user.email}` })

                              setEditedCell('')
                            }}
                            hasButtonForEditing={false}
                            getDisplayValue={(value) => {
                              return dateformatMDasMMMD(value)
                            }}
                          />
                        </div>
                        <div>
                          <span className='tw-text-slate-400'>PTO:</span>{' '}
                          <EditableField
                            tableid='userlist'
                            rowid={user.id}
                            fieldname='guidePto'
                            validationType='number'
                            currentValue={user.guidePaidTimeOff ?? ''}
                            isClickableToEdit={true}
                            editedCell={editedCell}
                            setEditedCell={setEditedCell}
                            useSpan={true}
                            callbackCommitChange={(value) => {
                              if (typeof value !== 'number') {
                                alert('must be a number')
                                return
                              }
                              if (value < 10 || value > 20) {
                                alert('must be between 10 and 20')
                                return
                              }

                              const updateObj: Partial<UserDetailsType> = {
                                guidePaidTimeOff: value,
                                dateModified: serverTimestampAsDate(),
                              }
                              updateDoc(doc(db, 'users', user.id), updateObj)
                              log_db_write({ db, userDetails, logkey: 'db_write.users.set_guide_paidTimeOff', desc: `Set guide paid time off to [${value}] for user ${user.id} ${user.email}` })

                              setEditedCell('')
                            }}
                            hasButtonForEditing={false}
                          />
                        </div>
                      </>
                    )}
                  </td>
                  <td className='__col_permissions'>
                    <UserListEditPermissions
                      user={user}
                    />
                  </td>
                  <td className='__col_emergency_phone'>
                    <Form.Select id={`dropdown_emergencyphone_${user.id}`} style={{ width: '10.5em' }} value={user.preferences?.defaultEmergencyPhone || ''} onChange={(e) => {
                      updateDoc(doc(db, 'users', user.id), {
                        ['preferences.defaultEmergencyPhone']: e.target.value,
                        dateModified: serverTimestamp(),
                      })
                      log_db_write({ db, userDetails, logkey: 'db_write.users.set_defaultEmergencyPhone', desc: `Set default emergency phone to [${e.target.value}] for user ${user.id} ${user.email}` })
                    }}>
                      {['', ...emergencyPhoneList].map((phoneName, index) => {
                        return <option key={phoneName} value={phoneName}>{phoneName}</option>
                      })}
                    </Form.Select>
                  </td>
                  <td className='__col_freee_number' style={userDetailsList.filter((u) => u.freeeNumber === user.freeeNumber).length > 1 ? { color: 'red' } : undefined}>
                    <div style={isEmployee && !user.freeeNumber ? { backgroundColor: 'orange' } : undefined}>
                      <EditableField
                        tableid='userlist'
                        rowid={user.id}
                        fieldname='freeeNumber'
                        validationType='number'
                        currentValue={user.freeeNumber ?? ''}
                        isClickableToEdit={true}
                        editedCell={editedCell}
                        setEditedCell={setEditedCell}
                        callbackCommitChange={(value) => {
                          const updateObj: Partial<UserDetailsType> = {
                            freeeNumber: value,
                            dateModified: serverTimestampAsDate(),
                          }
                          updateDoc(doc(db, 'users', user.id), updateObj)
                          log_db_write({ db, userDetails, logkey: 'db_write.users.set_freeeNumber', desc: `Set freee number to [${value}] for user ${user.id} ${user.email}` })

                          setEditedCell('')
                        }}
                        hasButtonForEditing={false}
                      />
                    </div>
                  </td>
                  <td className='__col_freee_name' style={{ whiteSpace: 'nowrap' }}>
                    <div style={isEmployee && !user.freeeName ? { backgroundColor: 'orange' } : undefined}>
                      <EditableField
                        tableid='userlist'
                        rowid={user.id}
                        fieldname='freeeName'
                        validationType=''
                        currentValue={user.freeeName ?? ''}
                        isClickableToEdit={true}
                        editedCell={editedCell}
                        setEditedCell={setEditedCell}
                        callbackCommitChange={(value) => {
                          const updateObj: Partial<UserDetailsType> = {
                            freeeName: value.trim(),
                            dateModified: serverTimestampAsDate(),
                          }
                          updateDoc(doc(db, 'users', user.id), updateObj)
                          log_db_write({ db, userDetails, logkey: 'db_write.users.set_freeeName', desc: `Set freee name to [${value.trim()}] for user ${user.id} ${user.email}` })

                          setEditedCell('')
                        }}
                        hasButtonForEditing={false}
                      />
                    </div>
                  </td>
                  <td>{dateFormatUserFriendly(user.dateCreated)}</td>
                  <td>{dateFormatUserFriendly(user.dateModified)}</td>
                  <td>{dateFormatUserFriendly(user.dateLastLoggedIn)}</td>
                </tr>
              )
            }
            )}
          </tbody>
        </table>
      </div>

      {userrole_isDev(userDetails.roles) && (
        <UserListPermissionsSummary
          userList={userDetailsList}
        />
      )}

      {elevatedEmployees.length > 0 && (
        <div className='tw-p-2 tw-border tw-border-solid tw-border-slate-500 tw-rounded tw-my-4'>
          <h5>Elevated permissions</h5>
          <p>These users have permissions that are not standard for their role.</p>
          <table className='[&>*>tr>*]:tw-p-2
           [&>*>tr>*]:tw-border [&>*>tr>*]:tw-border-solid [&>*>tr>*]:tw-border-slate-500
           '>
            <thead>
              <tr>
                <th className='tw-bg-blue-300'>Role</th>
                <th className='tw-bg-blue-300'>Dept</th>
                <th className='tw-bg-blue-300'>User</th>
                <th className='tw-bg-yellow-300'>Main role</th>
                {userRolesOtherList.map((s) => (
                  <th key={s} className='tw-bg-yellow-300'>{s}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {elevatedEmployees.map((entry) => (
                <tr key={entry.user.id}>
                  <td>{entry.user.teamName}</td>
                  <td>{entry.user.departmentName}</td>
                  <td>{entry.user.displayNameEn}</td>
                  <td>{entry.roles.mainRole}</td>
                  {userRolesOtherList.map((s) => <td key={s}>{entry.roles.roleFlags[s] ? 'Yes' : ''}</td>)}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}
