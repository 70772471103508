import dateFormat from 'dateformat'
import { isMidnightJST, isMidnightUTC, utc0_from_iso, utc0_from_jst0 } from './datetools'
import { isDevMode } from './util_getbuildtime'


const ONE_DAY = 86_400_000 // (24 * 60 * 60 * 1000)
const NINE_HOURS = 32_400_000 // (9 * 60 * 60 * 1000)
//const ONE_HOUR = 3_600_000 // (60 * 60 * 1000)
const ONE_MINUTE = 60_000 // (60 * 1000)

function assert_not_null(date: any) {
  if (!date && isDevMode()) throw new Error('date is null')
}



// below for date formatting that includes only the date and no time,
// we force UTC based formatting to ensure the same date appears on all
// users' screens regardless of their local timezone setting

export function dateFormatJp(datejst: Date | null | undefined) {
  if (!datejst) return ''
  return dateFormat(utc0_from_jst0(datejst), 'UTC:yyyy/mm/dd')
}

export function dateutcFormatJp(dateutc: Date | null | undefined) {
  if (!dateutc) return ''
  return dateFormat(dateutc, 'UTC:yyyy/mm/dd')
}

export function dateisoFormatJp(dateiso: string | null | undefined) {
  if (!dateiso) return ''
  return dateutcFormatJp(utc0_from_iso(dateiso))
}

export function dateutcFormatMD(dateutc: Date) {
  assert_not_null(dateutc)
  return dateutc && dateFormat(dateutc, 'UTC:m/d')
}

export function dateutcFormatMMMD(dateutc: Date) {
  assert_not_null(dateutc)
  return dateutc && dateFormat(dateutc, 'UTC:mmm d')
}

export function dateformatMDasMMMD(value: string) {
  if (!value)
    return ''
  const match = value.match(/^(\d{1,2})\/(\d{1,2})$/)
  if (!match)
    return 'error'
  const month = Number(match[1])
  const day = Number(match[2])
  const dateutc = new Date(Date.UTC(2024, month - 1, day))
  const formatted = dateutcFormatMMMD(dateutc)
  return formatted
}

export function dateisoFormatMMMYYYY(dateiso: string) {
  if (!dateiso) return ''
  const dateutc = utc0_from_iso(dateiso)
  return dateFormat(dateutc, 'UTC:mmmm yyyy')
}

export function dateFormatJpShort(datejst: Date | null | undefined) {
  // this function formats a date/time for display, so we allow the argument to be null
  // (in particular, a date set using serverTimestamp will initially be null on the first
  // refresh before receiving the time back from the Firestore server)
  if (!datejst) return ''
  return dateFormat(utc0_from_jst0(datejst), 'UTC:yyyy/m/d')
}

export function dateFormatJpShortWithTime(datejst: Date | null | undefined) {
  // this function formats a date/time for display, so we allow the argument to be null
  // (in particular, a date set using serverTimestamp will initially be null on the first
  // refresh before receiving the time back from the Firestore server)
  if (!datejst) return ''
  return dateFormat(new Date(datejst.getTime() + NINE_HOURS), 'UTC:yyyy/m/d HH:MM')
}

export function dateutcFormatJpShort(dateutc: Date) {
  assert_not_null(dateutc)
  return dateutc && dateFormat(dateutc, 'UTC:yyyy/m/d')
}

export function dateisoFormatJpShort(dateiso: string) {
  if (!dateiso) return ''
  try {
    return dateutcFormatJpShort(utc0_from_iso(dateiso))
  } catch (e) {
    // happened on 2024-08-02. string was: '+020255-01'
    console.error(`Invalid date format: [${dateiso}]`)
    return dateiso
  }
}

export function dateFormatJpWithTime(date: Date) {
  if (!date) return ''
  return dateFormat(date, 'yyyy/m/d H:MM')
}

export function dateFormatJpWithTimeAndSeconds(date: Date) {
  if (!date) return ''
  return dateFormat(date, 'yyyy/m/d H:MM:ss')
}






export function dateFormatJpWithOrWithoutTime(date: Date) {
  assert_not_null(date)

  if (isMidnightJST(date)) {
    return dateFormat(date, 'yyyy/m/d')
  } else {
    return dateFormat(date, 'yyyy/m/d H:MM')
  }
}

export function dateFormatCsvDate(datejst: Date) {
  // doesn't need to be midnight
  const dateutc = new Date(datejst.getTime() + NINE_HOURS)
  return dateFormat(dateutc, 'UTC:yyyy-mm-dd')
}

export function dateFormatCsvDateTime(datejst: Date) {
  // doesn't need to be midnight
  const dateutc = new Date(datejst.getTime() + NINE_HOURS)
  return dateFormat(dateutc, 'UTC:yyyy-mm-dd HH:MM:ss')
}

export function dateutcFormatLong(dateutc: Date) { // date passed is already midnight UTC
  assert_not_null(dateutc)
  if (!isMidnightUTC(dateutc))
    throw new Error('date is not midnight UTC')
  return dateFormat(dateutc, 'UTC:dddd d mmmm yyyy')
}

export function dateutcFormatWithWeekday(dateutc: Date) { // date passed is already midnight UTC
  assert_not_null(dateutc)
  if (!isMidnightUTC(dateutc))
    throw new Error('date is not midnight UTC')
  return dateFormat(dateutc, 'UTC:yyyy/m/d ddd')
}


export function dateFormatIso(date: Date) {
  //return date.getFullYear() + "/" + (date.getMonth() - 1) + "/" + date.getDate();
  // dateFormat will use current date if passed date is empty. here we return empty if date is empty
  assert_not_null(date)
  return date && dateFormat(date, 'yyyy-mm-dd HH:MM:ss')
}

export function dateFormatCompact(date: Date) {
  //return date.getFullYear() + "/" + (date.getMonth() - 1) + "/" + date.getDate();
  // dateFormat will use current date if passed date is empty. here we return empty if date is empty
  assert_not_null(date)
  return date && dateFormat(date, 'yyyymmdd_HHMMss')
}

export function dateFormatExcelExport(dateiso: string) {
  if (!dateiso) return ''
  return dateFormat(utc0_from_iso(dateiso), 'UTC:dd-mmm-yyyy')
}

export function dateisoFormatDay(dateiso: string) {
  if (!dateiso) return ''
  return dateFormat(utc0_from_iso(dateiso), 'UTC:d')
}

export function dateisoFormatDayMonth(dateiso: string) {
  if (!dateiso) return ''
  return dateFormat(utc0_from_iso(dateiso), 'UTC:d-mmm')
}
