

// NOTE:
// In this project 'eightydays-webapp', DATE ONLY timestamps are represented using the time 00:00 in JST timezone.
// Javascript default behavior is somewhat inconsistent on this,
//   sometimes it uses UTC 00:00 -> e.g. new_Date(2023-01-01) will be midnight UTC or 9AM JST
//   sometimes it uses JST 00:00 -> e.g. new_Date(2023-1-01) or new_Date(2023/01/01) will be midnight JST


const ONE_DAY = 86_400_000 // (24 * 60 * 60 * 1000)
const NINE_HOURS = 32_400_000 // (9 * 60 * 60 * 1000)
//const ONE_HOUR = 3_600_000 // (60 * 60 * 1000)
const ONE_MINUTE = 60_000 // (60 * 1000)

export function getUtcMidnightBasedOnJstTime(datejst: Date) {
  const midnightUTC = Math.floor((datejst.getTime() + NINE_HOURS) / ONE_DAY) * ONE_DAY
  return new Date(midnightUTC)
}

export function getJstMidnightBasedOnJstTime(datejst: Date) {
  const midnightUTC = getUtcMidnightBasedOnJstTime(datejst).getTime()
  const midnightJST = midnightUTC - NINE_HOURS
  return new Date(midnightJST)
}

export function getTodayJST() { // returns midnight JST, based on current date in JST
  const now = new Date()
  //const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  const todayJST = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), (now.getUTCHours() >= 15 ? 0 : -1) + now.getUTCDate(), 15, 0, 0, 0))

  // method 2
  const todayTimeJST = getJstMidnightBasedOnJstTime(now)
  if (todayTimeJST.getTime() !== todayJST.getTime())
    throw new Error('inconsistency in getToday')

  return todayJST
}

export function getTodayUTC() { // returns midnight UTC, based on current date in JST
  return utc0_from_jst0(getTodayJST())
}

export function getTodayIso() {
  return iso_from_utc0(getTodayUTC())
}

export function iso_from_jst0(datejst: Date) {
  assert_not_null(datejst)
  if (!isMidnightJST(datejst))
    throw new Error('date is not midnight JST')
  // return dateFormat(utc0_from_jst0(datejst), 'UTC:yyyy-mm-dd')
  return utc0_from_jst0(datejst).toISOString().slice(0, 10)
}

export function iso_from_utc0(dateutc: Date) { // date passed is already midnight UTC
  assert_not_null(dateutc)
  if (!isMidnightUTC(dateutc))
    throw new Error('date is not midnight UTC')
  //return dateFormat(dateutc, 'UTC:yyyy-mm-dd')
  return dateutc.toISOString().slice(0, 10)
}

export function dateutcFormatStandardMonth(dateutc: Date) {
  // return the date's month in yyyy-mm format, assuming the date is 00:00 UTC
  assert_not_null(dateutc)
  if (!isMidnightUTC(dateutc))
    throw new Error('date is not midnight UTC')
  return iso_from_utc0(dateutc).substring(0, 7)
}

export function dateFormatStandardMonth(date: Date) {
  // return the date's month in yyyy-mm format, assuming the date is 00:00 JST
  assert_not_null(date)
  if (!isMidnightJST(date))
    throw new Error(`date is not midnight JST [${date}]`)
  return iso_from_jst0(date).substring(0, 7)
}

export function getCurrentMonth() {
  const sToday = getTodayIso()
  const sMonth = sToday.substring(0, 7)
  return sMonth
}

export function getFirstOfMonthIso(dateiso: string) {
  return dateiso.substring(0, 7) + '-01'
}

export function utc0_from_jst0(jst0: Date) {
  // convert a timestamp that is midnight JST (15:00 UTC the previous day) to midnight UTC (09:00 JST) by adding 9 hours
  if (!isMidnightJST(jst0))
    throw new Error(`date is not midnight JST: ${jst0}`)
  const utc0 = new Date(jst0.getTime() + NINE_HOURS)
  const utc0_b = new Date(Date.UTC(jst0.getUTCFullYear(), jst0.getUTCMonth(), jst0.getUTCDate(), jst0.getUTCHours() + 9))
  if (utc0.getTime() !== utc0_b.getTime())
    throw new Error('inconsistency in utc0_from_jst0')
  if (!isMidnightUTC(utc0))
    throw new Error('utc0 is not midnight UTC')
  return utc0
}

export function jst0_from_utc0(utc0: Date) {
  // convert a timestamp that is midnight UTC (09:00 JST) to midnight JST (15:00 UTC) by subtracting 9 hours
  const jst0 = new Date(utc0.getTime() - NINE_HOURS)
  const jst0_b = new Date(Date.UTC(utc0.getUTCFullYear(), utc0.getUTCMonth(), utc0.getUTCDate(), utc0.getUTCHours() - 9))
  if (jst0.getTime() !== jst0_b.getTime())
    throw new Error('inconsistency in jst0_from_utc0')
  if (!isMidnightUTC(utc0))
    throw new Error('utc0 is not midnight UTC')
  return jst0
}

export function utc0_from_local0(local0: Date) {
  // convert a timestamp that is midnight local time to midnight UTC
  const utc0 = new Date(local0.getTime() - local0.getTimezoneOffset() * ONE_MINUTE)
  if (utc0.getTime() % ONE_DAY !== 0)
    throw new Error('local0 is not midnight local time')
  const utc0_b = new Date(Date.UTC(local0.getFullYear(), local0.getMonth(), local0.getDate()))
  if (utc0.getTime() !== utc0_b.getTime())
    throw new Error('inconsistency in utc0_from_local0')
  return utc0
}

export function local0_from_utc0(utc0: Date) {
  // convert a timestamp that is midnight UTC to midnight local time
  if (utc0.getTime() % ONE_DAY !== 0)
    throw new Error('utc0 is not midnight UTC')
  const local0 = new Date(utc0.getTime() + utc0.getTimezoneOffset() * ONE_MINUTE)
  const local0_b = new Date(utc0.getUTCFullYear(), utc0.getUTCMonth(), utc0.getUTCDate())
  if (local0.getTime() !== local0_b.getTime())
    throw new Error('inconsistency in local0_from_utc0')
  return local0
}

export function local0_from_iso(dateiso: string) {
  return local0_from_utc0(utc0_from_iso(dateiso))
}

export function iso_from_local0(datelocal: Date) {
  return iso_from_utc0(utc0_from_local0(datelocal))
}

export function jst0_from_local0(datelocal: Date) {
  return jst0_from_utc0(utc0_from_local0(datelocal))
}

export function local0_from_jst0(datejst: Date) {
  return local0_from_utc0(utc0_from_jst0(datejst))
}

export function local0_or_null_from_jst0(datejst: Date) {
  if (!datejst) return null
  return local0_from_utc0(utc0_from_jst0(datejst))
}

export function dateisoYearMonth(dateiso: string) {
  assert_valid_dateiso(dateiso)
  return dateiso.substring(0, 7)
}

export function utc0_from_iso(dateiso: string): Date {
  const validation_result = validateDateiso(dateiso)
  if (typeof validation_result === 'string')
    throw new Error(validation_result)
  return validation_result
}

export function try_utc0_from_iso(dateiso: string): Date | null {
  const validation_result = validateDateiso(dateiso)
  if (typeof validation_result === 'string') {
    console.error(validation_result)
    return null
  }
  return validation_result
}

export function validateDateiso(dateiso: string): Date | string {
  // returns:
  //  - Date object: dateutc if valid
  //  - string: error message if invalid

  if (!dateiso)
    return 'dateiso is empty'
  // we allow dates in 1900 for dates of birth etc.
  const match = dateiso.match(/^(19[0-9]{2}|20[0-9]{2})-([01][0-9])-([0123][0-9])$/)
  if (!match)
    return `invalid date format: regex not matched [${dateiso}]`
  const year = Number(match[1])
  const month = Number(match[2])
  const day = Number(match[3])
  const dateutc = new Date(Date.UTC(year, month - 1, day))
  if (dateutc.getUTCFullYear() !== year || dateutc.getUTCMonth() !== month - 1 || dateutc.getUTCDate() !== day)
    return `invalid date format: roundtrip failed [${dateiso}]`
  return dateutc
}

export function assert_valid_dateiso(dateiso: string) {
  const validation_result = validateDateiso(dateiso)
  if (typeof validation_result === 'string')
    throw new Error(validation_result)
}

export function isValidDateiso(dateiso: string) {
  const validation_result = validateDateiso(dateiso)
  if (typeof validation_result === 'string')
    return false
  return true
}


export function dateparts_from_utc0(utc0: Date): [number, number, number] {
  return [utc0.getUTCFullYear(), utc0.getUTCMonth() + 1, utc0.getUTCDate()]
}

export function dateparts_from_iso(dateiso: string): [number, number, number] {
  return dateparts_from_utc0(utc0_from_iso(dateiso))
}


export function jst0_from_iso(dateiso: string) {
  return jst0_from_utc0(utc0_from_iso(dateiso))
}


export function isMidnightUTC(dateutc: Date) {
  return dateutc.getTime() % ONE_DAY === 0
}

export function isMidnightJST(datejst: Date) {
  return (datejst.getTime() + NINE_HOURS) % ONE_DAY === 0
}

export function isMidnightLocal(datelocal: Date) {
  return (datelocal.getTime() - datelocal.getTimezoneOffset() * ONE_MINUTE) % ONE_DAY === 0
}


function assert_not_null(date: any) {
  if (!date) throw new Error('date is null')
}


// export function dateFormatFull(date) {
//     return date && dateFormat(date, 'yyyy-mm-dd HH-MM-ss')
// }

export function getSpanDaysFloat(date1: Date, date2: Date) {
  // doesn't always return an integer
  const span = date2.getTime() - date1.getTime()
  return span / ONE_DAY
}

export function getSpanDaysExact(date1: Date, date2: Date) {
  // verifies span is an integer number of days
  const span = date2.getTime() - date1.getTime()
  if (span % ONE_DAY !== 0)
    throw new Error('date span is not a multiple of 1 day')
  return span / ONE_DAY
}

export function getSpanDaysExactIso(date1: string, date2: string) {
  return getSpanDaysExact(utc0_from_iso(date1), utc0_from_iso(date2))
}

export function tryParseDateToUtc0(s_date: string) {

  // we do NOT use javascript's date string parser (by calling new Date(string)) for 2 reasons:
  //  - it will happily parse strings like '2023/'  '2023/8' that we don't want to consider valid;
  //  - it assumes the user's timezone, whereas for date-only (no time) we want to force the UTC timezone (midnight UTC).
  // so instead, we manually parse the date using regex and reconstruct it from its components.
  // we do NOT use the new Date() string parser at any point.

  const regex = /^([0-9]{4})\/([0-9]{1,2})\/([0-9]{1,2})$/
  const match = s_date.match(regex)
  if (!match)
    return null

  const year = Number(match[1])
  const month = Number(match[2])
  const day = Number(match[3])
  const date = new Date(Date.UTC(year, month - 1, day, 0, 0, 0, 0)) // force UTC and ignore user's timezone

  // here we need to check the year, month, date match the originals
  // as e.g. day==0 is laste day of previous month, day=32 is first day of next month, etc., but we should reject those as invalid.
  const isValid = !isNaN(date.getTime()) && date.getUTCFullYear() === year && (1 + date.getUTCMonth()) === month && date.getUTCDate() === day
  return isValid ? date : null
}

export function tryParseDateToJst0(s_date: string) {
  const dateutc = tryParseDateToUtc0(s_date)
  if (!dateutc)
    return null
  return jst0_from_utc0(dateutc)
}

export function tryParseDateToIso(s_date: string) {
  const dateutc = tryParseDateToUtc0(s_date)
  if (!dateutc)
    return null
  return iso_from_utc0(dateutc)
}

export function getJSTDate(year: number, month: number, dayOfMonth: number) { // usual JS convention: month is ZERO indexed
  const jstdate = new Date(Date.UTC(year, month, dayOfMonth, -9, 0, 0, 0))
  return jstdate
}

export function addDays(date: Date, numdays: number) {
  const result1 = new Date(date);
  result1.setUTCDate(result1.getUTCDate() + numdays);

  const result2 = new Date(date.getTime() + numdays * ONE_DAY)

  if (result1.getTime() !== result2.getTime())
    throw new Error('inconsistent addDays')

  return result2;
}

export function addDaysIso(dateiso: string, days: number) {
  return iso_from_utc0(addDays(utc0_from_iso(dateiso), days))
}

export function countDaysIso(dateiso1: string, dateiso2: string) {
  return getSpanDaysExactIso(dateiso1, dateiso2)
}

export function addMonthsUtc(dateutc: Date, months: number) {
  if (!isMidnightUTC(dateutc))
    throw new Error('date is not midnight UTC')
  const result = new Date(dateutc);
  result.setUTCMonth(result.getUTCMonth() + months);
  return result;
}

export function addMonthsJst(datejst: Date, months: number) {
  return jst0_from_utc0(addMonthsUtc(utc0_from_jst0(datejst), months))
}

export function addMonthsIso(dateiso: string, months: number) {
  const dateutc = utc0_from_iso(dateiso)
  dateutc.setUTCMonth(dateutc.getUTCMonth() + months);
  return iso_from_utc0(dateutc)
}

export function addYears(date: Date, years: number) {
  const result = new Date(date);
  result.setFullYear(result.getFullYear() + years);
  return result;
}

export function getSalaryPaymentDateJst(sMonth: string) { // yyyy-mm
  const match = sMonth.match(/^([0-9]{4})-([0-9]{2})$/)
  if (!match)
    throw new Error('invalid month format')
  const dateutcThisMonth = new Date(sMonth + '-01')
  const dateutcNextMonth = addMonthsUtc(dateutcThisMonth, 1)
  dateutcNextMonth.setUTCDate(15)
  return jst0_from_utc0(dateutcNextMonth)
}


export function parseDatePicker(str_date: string) {
  // used for tour start date, end date, etc.
  // standardize all these to be midnight JST

  // date picker date is guaranteed in 2023-01-01 format,
  // but by default Date() parsing will assume UTC in this format,
  // so we specify JST explicitly

  return parseIsoDateToJst(str_date)
}

export function parseIsoDateToJst(str_date: string) {
  assert_not_null(str_date)

  if (!str_date.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/))
    throw new Error('invalid date format')

  // method 1
  const full_str = str_date + 'T00:00:00+09:00'
  const datejst1 = new Date(full_str)

  // method 2
  const dateutc = new Date(str_date)
  const datejst2 = jst0_from_utc0(dateutc)

  if (datejst1.getTime() !== datejst2.getTime())
    throw new Error('date mismatch')

  return datejst1
}

export function parseIsoDateToUTC(str_date: string) {
  assert_not_null(str_date)

  if (!str_date.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/))
    throw new Error('invalid date format')

  return new Date(str_date)
}

export function getMidnightJst(year: number, month: number, day: number) {
  return new Date(Date.UTC(year, month - 1, day) - NINE_HOURS)
}

export function str_from_jst(datejst: Date) {
  const dateutc = new Date(datejst.getTime() + 9 * 60 * 60 * 1000)
  const str = datestr_from_parts(dateutc.getUTCFullYear(), dateutc.getUTCMonth() + 1, dateutc.getUTCDate())
  return str
}

export function dateiso_from_jst(datejst: Date) {
  const dateutc = new Date(datejst.getTime() + 9 * 60 * 60 * 1000)
  return dateiso_from_utc(dateutc)
}

export function dateiso_from_utc(dateutc: Date) {
  const str = dateiso_from_parts(dateutc.getUTCFullYear(), dateutc.getUTCMonth() + 1, dateutc.getUTCDate())
  return str
}

export function dateiso_from_local0(datelocal: Date) {
  return dateiso_from_utc(utc0_from_local0(datelocal))
}

export function dateiso_from_parts(year: number, month: number, day: number) {
  return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
}

export function datestr_from_parts(year: number, month: number, day: number) {
  return `${year}${month.toString().padStart(2, '0')}${day.toString().padStart(2, '0')}`
}

export function getFirstDayOfMonth(dateutc: Date) {
  return new Date(Date.UTC(dateutc.getUTCFullYear(), dateutc.getUTCMonth(), 1))
}

export function getFirstDayOfNextMonth(dateutc: Date) {
  return addMonthsUtc(getFirstDayOfMonth(dateutc), 1)
}

export function getAge(datejst: Date) {
  const today = getTodayUTC()
  const birthDate = utc0_from_jst0(datejst)
  let age = today.getUTCFullYear() - birthDate.getUTCFullYear()
  const m = today.getUTCMonth() - birthDate.getUTCMonth()
  if (m < 0 || (m === 0 && today.getUTCDate() < birthDate.getUTCDate())) {
    age--
  }
  return age
}

export function minDateiso(dateiso1: string, dateiso2: string) {
  return dateiso1 < dateiso2 ? dateiso1 : dateiso2
}

export function maxDateiso(dateiso1: string, dateiso2: string) {
  return dateiso1 > dateiso2 ? dateiso1 : dateiso2
}

export function getDateIso(year: number, month: number, day: number) {
  const dateiso = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
  const dateutc = new Date(dateiso)
  if (dateutc.getUTCFullYear() !== year || dateutc.getUTCMonth() + 1 !== month || dateutc.getUTCDate() !== day)
    throw new Error('invalid date')
  return dateiso
}

export function getDateutc(year: number, month: number, day: number) {
  const date1 = utc0_from_iso(getDateIso(year, month, day))
  const date2 = new Date(Date.UTC(year, month - 1, day))
  if (date1.getTime() !== date2.getTime())
    throw new Error('inconsistent getDateutc')
  return date1
}

export function dateisoMinMax(dates: string[]) {
  const [minDate, maxDate] = dates.reduce((acc, cur) => ([cur < acc[0] ? cur : acc[0], cur > acc[1] ? cur : acc[1]]), ['2100-01-01', '1900-01-01'])
  if (isRealisticTravelDate(minDate) && isRealisticTravelDate(maxDate)) {
    return [minDate, maxDate];
  } else {
    return ['', ''];
  }
}

export function isRealisticTravelDate(dateiso: string) {
  if (dateiso < '2020-01-01') return false;
  if (dateiso > '2030-01-01') return false;
  return true;
}
