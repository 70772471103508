import { QuerySnapshot, collection, onSnapshot, query, where } from 'firebase/firestore'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { BeatLoader } from 'react-spinners'
import { useAppContext } from 'src/hooks/useAppContext'
import { ExpenseSheetType } from 'src/types/types_expensesheet'
import { GeneralExpenseType } from 'src/types/types_generalexpense'
import { InvoiceType } from 'src/types/types_invoices'
import { TourRequestType } from 'src/types/types_tourrequest'
import { convertExpenseSheetDates, convertGeneralExpenseDates, convertInvoiceDates, convertTourRequestDates } from 'src/util/util_firestoredates'
import { useOutsideAlerter } from '../EditableField/util_useOutsideAlerter'
import { RequestQuickSummaryGrid } from './RequestQuickSummaryGrid'
import './requestcodeaggregator.css'


interface RequestCodeAggregatorProps {
  requestCode: string
  show: boolean
  callbackClose: () => void
}

export function RequestCodeAggregator({
  requestCode,
  show,
  callbackClose,
}: RequestCodeAggregatorProps) {

  const { userDetails, db, setDbError, cloudFunctions, _lang } = useAppContext()

  const [initialized, setInitialized] = useState(false)
  if (show && !initialized) setInitialized(true)

  const [listRequests, setListRequests] = useState<TourRequestType[]>()
  useEffect(() => {
    if (!initialized) return

    const processSnapshot = function (snapshot: QuerySnapshot) {
      const list: TourRequestType[] = []
      for (const doc of snapshot.docs) {
        const item = { ...doc.data(), id: doc.id }
        convertTourRequestDates(item)
        list.push(item as TourRequestType)
      }
      setListRequests(list)
    }

    const q = query(collection(db, 'tourrequests'), where('_isDeleted', '==', false), where('requestCode', '==', requestCode))
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting request list', err));

    return unsubscribe

  }, [db, setDbError, requestCode, initialized])


  const [listExpenseSheets, setListExpenseSheets] = useState<ExpenseSheetType[]>()
  useEffect(() => {
    if (!initialized) return

    const processSnapshot = function (snapshot: QuerySnapshot) {
      const list: ExpenseSheetType[] = []
      for (const doc of snapshot.docs) {
        const item = { ...doc.data(), id: doc.id }
        convertExpenseSheetDates(item)
        list.push(item as ExpenseSheetType)
      }
      setListExpenseSheets(list)
    }

    const q = query(collection(db, 'expensesheets'), where('_isDeleted', '==', false), where('tourCode', '==', requestCode))
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting expense sheet list', err));

    return unsubscribe

  }, [db, setDbError, requestCode, initialized])


  const [listGeneralExpenses, setListGeneralExpenses] = useState<GeneralExpenseType[]>()
  useEffect(() => {
    if (!initialized) return

    const processSnapshot = function (snapshot: QuerySnapshot) {
      const list: GeneralExpenseType[] = []
      for (const doc of snapshot.docs) {
        const expense = { ...doc.data(), id: doc.id }
        convertGeneralExpenseDates(expense)
        list.push(expense as GeneralExpenseType)
      }
      setListGeneralExpenses(list)
    }

    const q = query(collection(db, 'generalexpenses'), where('_isDeleted', '==', false), where('requestCode', '==', requestCode))
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting generalexpenses list', err));

    return unsubscribe

  }, [db, setDbError, requestCode, initialized])


  const [listInvoices, setListInvoices] = useState<InvoiceType[]>()
  useEffect(() => {
    if (!initialized) return

    const processSnapshot = function (snapshot: QuerySnapshot) {
      const list: InvoiceType[] = []
      for (const doc of snapshot.docs) {
        const invoice = { ...doc.data(), id: doc.id } as InvoiceType
        convertInvoiceDates(invoice)
        list.push(invoice)
      }
      setListInvoices(list)
    }

    const q = query(collection(db, 'invoices'), where('_isDeleted', '==', false), where('tripcode', '==', requestCode))
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting invoice list', err));

    return unsubscribe

  }, [db, setDbError, requestCode, initialized])


  const popupRef = useRef<HTMLDivElement>(null);
  // _eslint-disable-next-line react-compiler/react-compiler -- https://github.com/facebook/react/issues/30745
  useOutsideAlerter(popupRef, () => {
    // if (show) is important because outsideAlerter will run on
    // ALL the popups of ALL the request codes shown on the page,
    // but we only want the one associated with this popup to be
    // able to close it
    if (show)
      callbackClose()
  });



  return (
    <span className='tw-relative'>
      <div ref={popupRef} className='popupRequestCode' style={show ? undefined : { display: 'none' }}>
        <div className='titleRow'>
          <h3>
            <i className='bi bi-link-45deg'></i>
            {requestCode}
          </h3>
          <div className='closeButton' onClick={(e) => callbackClose()}>
            <i className='bi bi-x-square'></i>
          </div>
        </div>
        <h5>Request</h5>
        {!listRequests ? (
          <BeatLoader />
        ) : !listRequests.length ? (
          <div>None</div>
        ) : (
          <ul>
            {listRequests.map((tourrequest) => (
              <li key={tourrequest.id}>
                <RequestQuickSummaryGrid tourrequest={tourrequest} requestCodeLinksToRequestPage={true} />
              </li>
            ))}
          </ul>
        )}
        <h5>Guide Expense Sheet</h5>
        {!listExpenseSheets ? (
          <BeatLoader />
        ) : !listExpenseSheets.length ? (
          <div>None</div>
        ) : (
          <ul>
            {listExpenseSheets.map((sheet) => (
              <li key={sheet.id}>
                <Link to={`/guide/expenses/sheet/${sheet.id}`}>{sheet.userGuideName}{sheet.sheetTitle ? ` (${sheet.sheetTitle})` : ''}</Link>
              </li>
            ))}
          </ul>
        )}
        <h5>General Expenses (<Link to={`/general-expenses/?requestCode=[${requestCode}]`}>view list</Link>)</h5>
        {!listGeneralExpenses ? (
          <BeatLoader />
        ) : !listGeneralExpenses.length ? (
          <div>None</div>
        ) : (
          <ul>
            {listGeneralExpenses.map((generalExpense) => (
              <li key={generalExpense.id}>
                <Link to={`/general-expenses/edit/${generalExpense.id}`}>{generalExpense.description}</Link>
              </li>
            ))}
          </ul>
        )}
        <h5>Invoices (<Link to={`/invoices/?requestCode=${requestCode}`}>view list</Link>)</h5>
        {!listInvoices ? (
          <BeatLoader />
        ) : !listInvoices.length ? (
          <div>None</div>
        ) : (
          <ul>
            {listInvoices.map((invoice) => (
              <li key={invoice.id}>
                <Link to={`/invoices/?requestCode=${requestCode}`}>{invoice.payeeNameEn} ({invoice.servicePurchased})</Link>
              </li>
            ))}
          </ul>
        )}

      </div>
    </span>
  )
}
