import { IconButton } from './IconButton';


interface SearchButtonProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  dataTooltipId?: string;
  dataTooltipHtml?: string;
  disabled?: boolean;
  className?: string;
}

export function SearchButton({
  onClick,
  dataTooltipId,
  dataTooltipHtml,
  disabled,
  className,
}: SearchButtonProps) {
  return (
    <IconButton
      icon='bi-search'
      onClick={onClick}
      dataTooltipId={dataTooltipId}
      dataTooltipHtml={dataTooltipHtml}
      disabled={disabled}
      className={className}
    />
  )
}
