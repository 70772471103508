import { HotelOccupancyType, HotelPricesType, ServicePriceCatalogType, ServicePriceItemType } from 'src/types/types_supplierprices';
import {
  GeneralInformationType,
  HotelNightRawType,
  HotelNightType,
  HotelRoomRawType,
  HotelRoomType,
  HotelTripQuotationRowRawType,
  PassengerTripQuotationRowRawType,
  Season,
  ServiceTripQuotationRowRawType,
  ServiceType
} from 'src/types/types_tripquotations';
import { addDaysIso, dateiso_from_parts, dateisoMinMax, getSpanDaysExactIso, iso_from_local0 } from 'src/util/datetools';
import { nano_id } from 'src/util/util_nano_id';
import { getMealPlan } from './tripQuotationExportUtils';

const parseDetailsOfService = (serviceType: ServiceType, detailsOfService: string) => {
  const separator = ' - ';
  let sourceServiceName = detailsOfService;
  let serviceDetails = '';
  let numOfPax: number | null = null;
  if (serviceType === 'Activity' || serviceType === 'Transfer') {
    const sep2index = detailsOfService.lastIndexOf(separator);
    const sep1index = detailsOfService.lastIndexOf(separator, sep2index - 1);
    if (sep1index !== -1 && sep2index !== -1) {
      const numOfPaxPart = detailsOfService.slice(sep2index + separator.length).trim();
      serviceDetails = detailsOfService.slice(sep1index + separator.length, sep2index).trim();
      sourceServiceName = detailsOfService.slice(0, sep1index).trim();
      const matchNumOfPax = numOfPaxPart.match(/^(\d+)\s+pax$/i);
      if (matchNumOfPax)
        numOfPax = Number(matchNumOfPax[1])
    }
  }

  if (serviceType === 'Rail' || serviceType === 'Package_Item') {
    const matchNumOfPax = detailsOfService.match(/(\d+)x?\s+person/i);
    if (matchNumOfPax)
      numOfPax = Number(matchNumOfPax[1])
    const sep1index = detailsOfService.indexOf(separator);
    const sep2index = detailsOfService.lastIndexOf(separator);
    if (sep1index !== -1 && sep2index !== -1) {
      const part1 = detailsOfService.slice(0, sep1index).trim();
      const part2 = detailsOfService.slice(sep1index + separator.length, sep2index).trim();
      const part3 = detailsOfService.slice(sep2index + separator.length).trim();
      // most common case: remove superfluous text 'Rail journey within Japan' (in part1) and 'TBD' '00:00' (in part3)
      if (part1 === 'Rail journey within Japan') {
        sourceServiceName = part2;
      } else {
        sourceServiceName = detailsOfService.slice(0, sep2index).trim();
      }
      if (part3 === 'TBD' || part3 === '00:00') {
        serviceDetails = '';
      } else {
        serviceDetails = part3;
      }
    }
  }

  return {
    sourceServiceName,
    serviceDetails,
    numOfPax
  }
}

const getServiceRow = (row: string[], serviceType: ServiceType, dateiso: string, index: number, servicePrices: ServicePriceCatalogType[]) => {
  const parseDetailsResult = parseDetailsOfService(serviceType, row[3]);
  const { sourceServiceName, numOfPax } = parseDetailsResult;
  let { serviceDetails } = parseDetailsResult;

  if (serviceType === 'Rail') {
    const startStation = row[10];
    const endStation = row[11];

    if (startStation && endStation)
      serviceDetails += (serviceDetails ? ' ' : '') + `${startStation} → ${endStation}`
  }

  const serviceRow: ServiceTripQuotationRowRawType = {
    index,
    csvdata: row,
    dateiso,
    sourceServicePlace: row[1],
    serviceType,
    serviceName: '',
    sourceServiceName,
    serviceDetails: '',
    sourceServiceDetails: serviceDetails,
    supplierNote: row[5],
    itemNote: row[6],
    pickUpTime: row[7],
    dropOffTime: row[8],
    passengers: row[9],
    bookingStatus: 'RQ',
    bookingRemarks: '',
    internalMemo: '',
    numOfPax,
    pricePerPax: null,
    totalPrice: null,
    servicePriceId: null,
    servicePriceItemId: null,
  };

  for (const service of servicePrices) {
    const matchingItem = service.priceItems.find(item => {
      if (!item.itemMappings) return false;
      for (const mapping of item.itemMappings) {
        if (mapping.serviceName === sourceServiceName && (mapping.details === serviceDetails || !mapping.details)) {
          return true;
        }
      }
      return false;
    })
    if (matchingItem) {

      let pricePerPax: number | null = null;
      if (numOfPax) {
        pricePerPax = getServicePricePerPax(numOfPax, matchingItem);
      }

      const totalPrice = pricePerPax && numOfPax ? pricePerPax * numOfPax : null

      serviceRow.servicePriceId = service.id;
      serviceRow.servicePriceItemId = matchingItem.id;
      serviceRow.serviceName = `${service.serviceName} ${matchingItem.priceItemName}`.trim();
      serviceRow.pricePerPax = pricePerPax;
      serviceRow.totalPrice = totalPrice;

      break;
    }
  }

  return serviceRow;
}

export const getServicePricePerPax = (numOfPax: number, servicePriceItem: ServicePriceItemType) => {
  let pricePerPax: number | null = null;
  // if only 1 pax, as db does not contain price per person for 1 pax, so we price charge is that of 2 pax
  if (numOfPax === 1) pricePerPax = (servicePriceItem.seasons[0].ages[0].pricePerPerson2pax || 0) * 2;
  if (numOfPax === 2) pricePerPax = servicePriceItem.seasons[0].ages[0].pricePerPerson2pax || 0;
  if (numOfPax === 3) pricePerPax = servicePriceItem.seasons[0].ages[0].pricePerPerson3pax || 0;
  if (numOfPax >= 4) pricePerPax = servicePriceItem.seasons[0].ages[0].pricePerPerson4pax || 0;
  return pricePerPax;
}

/**
 * Gets the accommodation checkout date
 * @param input String to extract date from, eg. 'Check out: 19 Jun 2025'
 * @returns iso date
 */
const getCheckoutDate = (input: string) => {
  const match = input.match(/^Check out: (\d{1,2} \w{3} \d{4})$/);
  if (match) {
    const checkout = match[1];
    const date = new Date(checkout);
    return iso_from_local0(date);
  }
  return '';
}

const getHotelRow = (row: string[], serviceType: 'Accommodation', checkinDateiso: string, index: number, passengerCount: number, hotelPrices: HotelPricesType[]) => {
  // eg. Sheraton Hiroshima - 1x Deluxe room <<35sqm>> - double - 2 nights
  const detailsCell = row[3];
  const passengersCell = row[9];
  const separator = ' - ';
  let sourceHotelName = detailsCell;
  let hotelName = '';
  const sourceHotelPlace = row[1];
  let hotelPlace = '';
  let roomType = '';
  let hotelPriceId = null;
  let hotelRoomList: HotelRoomType[] = [];
  let pricePerPaxPerNight: number | null = null;
  let totalPrice: number | null = null;
  const checkoutDateiso = getCheckoutDate(row[8]);
  let sourceRoomType = '';
  const numOfNights = (checkinDateiso && checkoutDateiso) ? getSpanDaysExactIso(checkinDateiso, checkoutDateiso) : null;
  const sourceMeals = row[4];

  let numOfPax: number | null = null;
  if (passengersCell) {
    if (passengersCell === 'All') {
      numOfPax = passengerCount;
    } else {
      numOfPax = passengersCell.split(';').length
    }
  }

  let numOfRooms: number | null = null;
  const sepFirstIndex = detailsCell.indexOf(separator);
  const sepLastIndex = detailsCell.lastIndexOf(separator);
  if (sepFirstIndex !== -1 && sepLastIndex !== -1) {
    sourceHotelName = detailsCell.slice(0, sepFirstIndex).trim();
    sourceRoomType = detailsCell.slice(sepFirstIndex + separator.length, sepLastIndex).trim();


    const matchNumOfRooms = sourceRoomType.match(/^(\d+)x?\s+(.+)$/i);
    if (matchNumOfRooms) {
      numOfRooms = Number(matchNumOfRooms[1]);

      if (numOfRooms && checkinDateiso && checkoutDateiso && numOfNights) {
        hotelRoomList = getHotelRoomList(
          numOfRooms,
          numOfPax,
          checkinDateiso,
          checkoutDateiso,
        );

        // 1) check `sourceHotelName` vs supplier price database HOTEL name
        const matchingHotels = hotelPrices.filter(hotelPrice => (hotelPrice.hotelNameMappings || []).includes(sourceHotelName));
        if (matchingHotels.length === 1) {
          hotelPriceId = matchingHotels[0].id;
          hotelName = matchingHotels[0].facilityName;
          hotelPlace = matchingHotels[0].city;
          const roomNameLookup = matchNumOfRooms[2].trim();
          const matchingRooms = (matchingHotels[0].roomList || []).filter(room => (room.roomNameMappings || []).includes(roomNameLookup));
          // 2) check the below string vs supplier price database ROOM name
          if (matchingRooms.length === 1 && numOfPax) {
            roomType = matchingRooms[0].roomName;

            const matchingOccupancies = matchingRooms[0].occupancyList.filter(occ => occ.numOfPaxPerRoom === numOfPax);

            if (matchingOccupancies.length === 1) {
              hotelRoomList = applyHotelRoomPrice(hotelRoomList, null, matchingOccupancies[0]);

              totalPrice = 0
              for (const room of hotelRoomList) {
                for (const night of room.hotelNightList) {
                  totalPrice += night.totalPrice || 0;
                }
              }
              pricePerPaxPerNight = Math.round(totalPrice / (numOfPax * numOfNights))
            }
          }
        }
      }
    }
  }

  const breakfastIncluded = sourceMeals.toLowerCase().includes('breakfast');
  const dinnerIncluded = sourceMeals.toLowerCase().includes('dinner');
  const sourceMealCode = getMealPlan(breakfastIncluded, dinnerIncluded);

  const hotelRow: HotelTripQuotationRowRawType = {
    index,
    csvdata: row,
    sourceCheckinDateiso: checkinDateiso,
    checkinDateiso,
    sourceCheckoutDateiso: checkoutDateiso,
    checkoutDateiso,
    sourceHotelPlace,
    hotelPlace,
    serviceType,
    sourceHotelName,
    hotelName,
    sourceRoomType,
    roomType,
    supplierNote: row[5],
    itemNote: row[6],
    passengers: row[9],
    bookingStatus: 'RQ',
    bookingRemarks: '',
    internalMemo: '',
    sourceMeals,
    sourceMealCode,
    breakfastIncluded,
    dinnerIncluded,
    isBarRate: false,
    numOfNights,
    numOfRooms,
    numOfPax,
    pricePerPaxPerNight,
    totalPrice,
    hotelPriceId,
    hotelRooms: getHotelRoomsObject(hotelRoomList),
  };

  return hotelRow;
}

export const parseQuotationRequest = (
  csvParseResult: string[][],
  passengerCount: number,
  hotelPrices: HotelPricesType[],
  servicePrices: ServicePriceCatalogType[]
) => {

  const generalCsvData: Record<string, string[]> = {};
  csvParseResult.slice(0, 8).forEach((row, index) => {
    generalCsvData[`row_${index}`] = row;
  })

  const agencyItineraryRefWithVersion = csvParseResult[3][1].trim();
  const agencyItineraryRef = agencyItineraryRefWithVersion.split('/')[0];
  const generalInfo: GeneralInformationType = {
    agencyItineraryRefWithVersion,
    agencyItineraryRef,
    agencyOwner: csvParseResult[1][1].trim(),
    agencyBusinessUnit: csvParseResult[2][1].trim(),
    agencyPrimaryContact: csvParseResult[4][1].trim(),
    paxName: csvParseResult[5][1].trim(),
    numOfPax: passengerCount,
    tripStartDateiso: '',
    tripEndDateiso: '',
    tripDurationDays: null,
    generalCsvData,
  }

  const serviceRows: Record<string, ServiceTripQuotationRowRawType> = {};
  const hotelRows: Record<string, HotelTripQuotationRowRawType> = {};

  let serviceRowIndex = 0;
  let hotelRowIndex = 0;
  const csvHeaders = csvParseResult[8];
  for (const [_, row] of csvParseResult.slice(9).entries()) {
    const rowDate = new Date(row[0]);
    const dateiso = dateiso_from_parts(rowDate.getFullYear(), rowDate.getMonth() + 1, rowDate.getDate())
    const serviceType = row[2];

    const rowId = nano_id()
    if (serviceType === 'Accommodation') {
      const hotelRow = getHotelRow(row, serviceType, dateiso, hotelRowIndex, passengerCount, hotelPrices);
      hotelRows[rowId] = hotelRow;
      hotelRowIndex++;
    }
    else {
      const serviceRow = getServiceRow(row, serviceType as ServiceType, dateiso, serviceRowIndex, servicePrices);
      serviceRows[rowId] = serviceRow;
      serviceRowIndex++;
    }
  }

  const allDates = [
    ...Object.values(serviceRows).map(row => row.dateiso),
    ...Object.values(hotelRows).map(row => row.checkinDateiso),
    ...Object.values(hotelRows).map(row => row.checkoutDateiso),
  ]

  const [minDate, maxDate] = dateisoMinMax(allDates);
  generalInfo.tripStartDateiso = minDate;
  generalInfo.tripEndDateiso = maxDate;
  const numOfDays = minDate && maxDate ? 1 + getSpanDaysExactIso(minDate, maxDate) : null;
  generalInfo.tripDurationDays = numOfDays;

  return {
    generalInfo,
    services: serviceRows,
    hotels: hotelRows,
    csvHeaders,
  };
}

export const parsePassengers = (csvParseResult: string[][]) => {
  const passengers: Record<string, PassengerTripQuotationRowRawType> = {};

  for (const [index, row] of csvParseResult.slice(6).entries()) {
    // weird bug when saving with LibreOffice, it adds a row with one empty field so last row is [""]
    if (row.length < 20)
      break;
    const sDateOfBirth = row[7].trim();
    const dateOfBirth = sDateOfBirth ? iso_from_local0(new Date(sDateOfBirth)) : '';
    const rowId = nano_id()
    const passengerRow: PassengerTripQuotationRowRawType = {
      index,
      salutation: row[0].trim(),
      firstName: row[1].trim(),
      middleName: row[2].trim(),
      lastName: row[3].trim(),
      suffix: row[4].trim(),
      passengerType: row[5].trim(),
      gender: row[6].trim(),
      dateOfBirth,
      bookingFormReceived: row[8].trim() === 'No' ? false : true,
      mobilityMedical: row[9].trim(),
      dietary: row[10].trim(),
      heightCm: row[11].trim() ? Number(row[11].trim()) : null,
      weightKg: row[12].trim() ? Number(row[12].trim()) : null,
      identityType: row[13].trim(),
      identityNumber: row[14].trim(),
      expiryDate: row[15].trim(),
      nationality: row[16].trim(),
      issueDate: row[17].trim(),
      stayPreferences: row[18].trim(),
      clientClassification: row[19].trim(),
    }
    passengers[rowId] = passengerRow;
  }

  return passengers;
}



const periods: { start: string, end: string, season: Season }[] = [
  {
    start: '2025-01-01',
    end: '2025-01-04',
    season: 'special',
  },
  {
    start: '2025-01-05',
    end: '2025-02-28',
    season: 'low',
  },
  {
    start: '2025-03-01',
    end: '2025-05-10',
    season: 'high',
  },
  {
    start: '2025-05-11',
    end: '2025-05-31',
    season: 'mid',
  },
  {
    start: '2025-06-01',
    end: '2025-07-31',
    season: 'low',
  },
  {
    start: '2025-08-01',
    end: '2025-08-23',
    season: 'high',
  },
  {
    start: '2025-08-24',
    end: '2025-08-31',
    season: 'low', // TO CHECK
  },
  {
    start: '2025-09-01',
    end: '2025-09-12',
    season: 'mid',
  },
  {
    start: '2025-09-13',
    end: '2025-11-23',
    season: 'high',
  },
  {
    start: '2025-11-24',
    end: '2025-12-12',
    season: 'mid',
  },
  {
    start: '2025-12-13',
    end: '2025-12-31',
    season: 'special',
  },
]

export const getSeason = (dateiso: string) => {
  for (const period of periods) {
    if (dateiso >= period.start && dateiso <= period.end) {
      return period.season;
    }
  }
  return null; // date is not in 2025
}

export const getSeasons = (checkin: string, checkout: string) => {
  if (!(checkin < checkout)) {
    console.error(`Check-in date ${checkin} is after check-out date ${checkout}`)
    return null;
  }

  const seasons = new Set<Season>();

  for (let date = checkin; date < checkout; date = addDaysIso(date, 1)) {
    const season = getSeason(date);
    if (!season) {
      console.error(`Season was not found for date ${date}`)
      return null;
    }
    seasons.add(season);
  }

  return [...seasons];
}

export const getSeasonPrice = (occupancy: HotelOccupancyType, season: Season): number | null => {
  switch (season) {
    case 'low': return typeof occupancy.lowSeason === 'number' ? occupancy.lowSeason : null;
    case 'mid': return typeof occupancy.mediumSeason === 'number' ? occupancy.mediumSeason : null;
    case 'high': return typeof occupancy.highSeason === 'number' ? occupancy.highSeason : null;
    case 'special': return typeof occupancy.specialSeason === 'number' ? occupancy.specialSeason : null;
    default: return null;
  }
}

export function getPaxPerRoom(numOfPaxAllRooms: number | null, numOfRooms: number | null) {
  if (!numOfPaxAllRooms || !numOfRooms) {
    return { numOfPaxPerRoom_min: null, excessPax: null };
  }
  const numOfPaxPerRoom_min = Math.floor(numOfPaxAllRooms / numOfRooms);
  const excessPax = numOfPaxAllRooms - (numOfPaxPerRoom_min * numOfRooms);
  return { numOfPaxPerRoom_min, excessPax };
}
export const getHotelRoomList = (
  numOfRooms: number | null,
  numOfPaxAllRooms: number | null,
  checkin: string,
  checkout: string,
) => {

  if (!numOfRooms)
    return [];

  const { numOfPaxPerRoom_min, excessPax } = getPaxPerRoom(numOfPaxAllRooms, numOfRooms);

  const rooms: HotelRoomType[] = [];
  for (let iRoom = 0; iRoom < numOfRooms; iRoom++) {

    const numOfPaxPerRoom = (numOfPaxPerRoom_min && excessPax !== null) ? numOfPaxPerRoom_min + (iRoom < excessPax ? 1 : 0) : null;

    const nights: HotelNightType[] = [];
    for (let date = checkin; date < checkout; date = addDaysIso(date, 1)) {
      const season = getSeason(date);
      nights.push({
        id: nano_id(),
        dateiso: date,
        pricePerPax: null,
        numOfPax: numOfPaxPerRoom,
        totalPrice: null,
        season,
      })
    }

    rooms.push({
      id: nano_id(),
      hotelNightList: nights,
    })
  }
  return rooms;
}

/** Provide either `pricePerPaxPerNight` OR `occupancy` */
export function applyHotelRoomPrice(rooms: HotelRoomType[], pricePerPaxPerNight: number | null, occupancy: HotelOccupancyType | null) {
  return rooms.map(room => {
    return {
      ...room,
      hotelNightList: room.hotelNightList.map(night => {
        const season = getSeason(night.dateiso);
        const pricePerPax = pricePerPaxPerNight || ((season && occupancy) ? getSeasonPrice(occupancy, season) : null);
        return {
          ...night,
          pricePerPax,
          totalPrice: (night.numOfPax && pricePerPax) ? pricePerPax * night.numOfPax : null,
        }
      })
    }
  })
}

const getHotelNightsObject = (array: HotelNightType[]) => {
  const hotelNightsObj: Record<string, HotelNightRawType> = {}
  for (const [index, row] of array.entries()) {
    const rowDb: any = { ...row, index }
    delete rowDb.id
    hotelNightsObj[row.id] = rowDb
  }
  return hotelNightsObj
}
export const getHotelRoomsObject = (array: HotelRoomType[]) => {
  const hotelRoomsObj: Record<string, HotelRoomRawType> = {}
  for (const [index, row] of array.entries()) {
    const rowDb: any = { ...row, index, hotelNights: getHotelNightsObject(row.hotelNightList) }
    delete rowDb.id;
    delete rowDb.hotelNightList;
    hotelRoomsObj[row.id] = rowDb
  }
  return hotelRoomsObj
}
