


interface SupplierNoteAlertProps {
  note: string;
};

export function SupplierNoteAlert({
  note,
}: SupplierNoteAlertProps) {

  if (!note)
    return null;

  const match = note.match(/^\d{1,2} \w{3} \d{4} /);
  const startIndex = match?.[0].length || 0;
  const endIndex = startIndex + 10;

  return (
    <div className='tw-border tw-border-solid tw-border-orange-800/50 tw-bg-orange-200 tw-rounded tw-p-1 tw-text-sm tw-text-orange-800 tw-min-w-32'>
      Supplier note in CSV: {note.slice(startIndex, endIndex)}{note.length > endIndex ? '...' : ''}
    </div>
  );
}

export function ItemNoteAlert({
  note,
}: SupplierNoteAlertProps) {

  if (!note)
    return null;

  return (
    <div className='tw-border tw-border-solid tw-border-yellow-800/50 tw-bg-yellow-200 tw-rounded tw-p-1 tw-text-sm tw-text-yellow-800 tw-min-w-32'>
      Item note in CSV: {note.slice(0, 10)}{note.length > 10 ? '...' : ''}
    </div>
  );
}

