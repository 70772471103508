import { deleteField } from 'firebase/firestore';
import { Dispatch, SetStateAction } from 'react';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { DeleteButton } from 'src/components/Buttons/DeleteButton';
import { QuotegridLineItemType, QuotegridLineItemTypeUnion, QuotegridOverallType } from 'src/types/types_quotegrid';
import { addDaysIso } from 'src/util/datetools';
import { calculateQuotegridOverallNumbers, NEW_ROW_ID } from '../util_calculateQuotegridTotals';
import { UpdateQuotegridType } from '../util_handlepaste';


interface TableRowActionsProps {
  lineItems: QuotegridLineItemType[];
  itemId: string; // '*** NEW ROW ID ***' or 'lineItems.edVfNwYnKjX0Bt4Rsx6IjQ'
  index: number;
  prepareSaveRowsToDb: (newItems: QuotegridLineItemTypeUnion[]) => { updateObj: Record<string, any> }
  updateQuotegrid: UpdateQuotegridType;
  lineItemsAll: QuotegridLineItemTypeUnion[];
  overall: QuotegridOverallType;
  showDebugInfo: boolean;
  blankRowKey: string | null;
  setNumBlankRows: Dispatch<SetStateAction<Record<string, number>>> | null;
}

export function TableRowActions({
  lineItems,
  itemId,
  index: indexTable, // index in whole table. for summary table, this is different from item.index (item.index is index within that day)
  prepareSaveRowsToDb,
  updateQuotegrid,
  lineItemsAll,
  overall,
  showDebugInfo,
  blankRowKey,
  setNumBlankRows,
}: TableRowActionsProps) {

  const item = lineItems[indexTable]

  const disableMoveUpButton = indexTable === 0
  const disableMoveDownButton = indexTable === lineItems.length - 1 || lineItems[indexTable + 1].id === NEW_ROW_ID

  const moveUpChangesDate = !disableMoveUpButton && lineItems[indexTable - 1].dayNum !== item.dayNum
  const moveDownChangesDate = !disableMoveDownButton && lineItems[indexTable + 1].dayNum !== item.dayNum

  return (
    <>

      <DeleteButton onClick={() => {
        if (blankRowKey && setNumBlankRows) {
          setNumBlankRows((obj) => {
            return {
              ...obj,
              [blankRowKey]: obj[blankRowKey] - 1,
            }
          })
          return
        }

        const updateObj: Record<string, any> = {
          [`${itemId}`]: deleteField(),
        }
        // All items after this one need to have their index reduced by 1.
        // We do NOT update the index of the last item (NEW_ROW_ID item) as it is not present
        // in the DB and will be regenerated with the correct index on next render.
        for (let i = indexTable + 1; (i < lineItems.length) && (lineItems[i].dayNum === item.dayNum); i++) {
          if (lineItems[i].id === NEW_ROW_ID)
            continue
          updateObj[`${lineItems[i].id}.index`] = lineItems[i].index - 1
        }

        calculateQuotegridOverallNumbers(overall, lineItemsAll.filter((item) => item.id !== itemId), updateObj)

        updateQuotegrid(updateObj, 'Delete row')
      }}
        dataTooltipId='quotegrid-tooltip'
        dataTooltipHtml='Delete row'
      />
      {' '}
      {!blankRowKey && (
        <>
          <ButtonTW
            variant={disableMoveUpButton ? 'darkgray_outline' : moveUpChangesDate ? 'bsorange_outline' : 'blue_outline'}
            disabled={disableMoveUpButton}
            onClick={() => {
              if (disableMoveUpButton)
                return

              if (moveUpChangesDate) {
                const prevItem = lineItems[indexTable - 1]

                const updateObj = {
                  [`${itemId}.dayNum`]: item.dayNum - 1,
                  [`${itemId}.date`]: addDaysIso(item.date, - 1),
                  [`${itemId}.index`]: prevItem.dayNum === item.dayNum - 1 ? prevItem.index + 1 : 0,
                }

                // reduce index of all items on this day
                for (let i = indexTable + 1; i < lineItems.length; i++) {
                  const curitem = lineItems[i]
                  if (curitem.id === NEW_ROW_ID)
                    break
                  if (curitem.dayNum !== item.dayNum)
                    break
                  updateObj[`${curitem.id}.index`] = curitem.index - 1
                }

                updateQuotegrid(updateObj, 'Move row to previous day')

              } else {
                // move within the same day
                const updateObj = {
                  [`${itemId}.index`]: item.index - 1,
                  [`${lineItems[indexTable - 1].id}.index`]: item.index,
                }
                updateQuotegrid(updateObj, 'Move row up')
              }
            }}
            data-tooltip-id='quotegrid-tooltip'
            data-tooltip-html={moveUpChangesDate ? 'Move row to previous day' : 'Move row up'}
          ><i className='bi bi-arrow-up'></i></ButtonTW>
          {' '}
          <ButtonTW
            variant={disableMoveDownButton ? 'darkgray_outline' : moveDownChangesDate ? 'bsorange_outline' : 'blue_outline'}
            disabled={disableMoveDownButton}
            onClick={() => {
              if (disableMoveDownButton)
                return

              if (moveDownChangesDate) {
                const nextItem = lineItems[indexTable + 1]

                const updateObj = {
                  [`${itemId}.dayNum`]: item.dayNum + 1,
                  [`${itemId}.date`]: addDaysIso(item.date, 1),
                  [`${itemId}.index`]: 0,
                }

                // increase index of all items on this day
                for (let i = indexTable + 1; i < lineItems.length; i++) {
                  const curitem = lineItems[i]
                  if (curitem.id === NEW_ROW_ID)
                    break
                  if (curitem.dayNum !== item.dayNum + 1)
                    break
                  updateObj[`${curitem.id}.index`] = curitem.index + 1
                }

                updateQuotegrid(updateObj, 'Move row to next day')
              } else {
                // move within the same day
                const updateObj = {
                  [`${itemId}.index`]: item.index + 1,
                  [`${lineItems[indexTable + 1].id}.index`]: item.index,
                }
                updateQuotegrid(updateObj, 'Move row down')
              }
            }}
            data-tooltip-id='quotegrid-tooltip'
            data-tooltip-html={moveDownChangesDate ? 'Move row to next day' : 'Move row down'}
          ><i className='bi bi-arrow-down'></i></ButtonTW>

          {' '}
          <ButtonTW
            variant='blue_outline'
            title='Duplicate row'
            // data-tooltip-id='quotegrid-tooltip'
            // data-tooltip-html='Duplicate row'
            // data-tooltip-place='top'
            onClick={() => {

              const newRow = { ...item }
              newRow.index = item.index + 1

              const { updateObj } = prepareSaveRowsToDb([newRow as QuotegridLineItemTypeUnion])

              for (let i = indexTable + 1; (i < lineItems.length) && (lineItems[i].dayNum === item.dayNum); i++) {
                if (lineItems[i].id === NEW_ROW_ID)
                  break
                updateObj[`${lineItems[i].id}.index`] = lineItems[i].index + 1
              }

              console.log('updateobj', { ...updateObj })

              updateQuotegrid(
                updateObj,
                `Duplicate row ${1 + item.index} in table “${newRow.itemType}” on day ${1 + newRow.dayNum}`,
                false
              )

            }}
          >
            {/* <i className='bi bi-plus-circle'></i> */}
            Duplicate row
          </ButtonTW>
        </>
      )}

      {showDebugInfo && (
        <div>
          item.id={item.id}<br />
          item.index={item.index}<br />
          param indexTable={indexTable}
        </div>
      )}

    </>
  )
}
