

export const compare = (a: any, b: any) => a < b ? -1 : a > b ? 1 : 0

export const stringCompare = (a: string, b: string) => a.localeCompare(b, 'en', { sensitivity: 'base', ignorePunctuation: true })

export function openInNewTab(url: string, callback: () => void) {
  // setTimeout is required for Safari on iOS. see https://stackoverflow.com/a/70463940/
  setTimeout(() => {
    if (callback)
      callback() // callback must be called BEFORE window.open
    window.open(url, '_blank')
  })
}

export function delay(ms: number) {
  // https://stackoverflow.com/a/47480429/
  return new Promise((res) => setTimeout(res, ms))
}

export function detectHalfWidthKatakana(text: string) {
  for (let i = 0; i < text.length; i++) {
    const code = text.charCodeAt(i);
    if (code >= 0xFF61 && code <= 0xFF9F) {
      return true
    }
  }
  return false
}

export function getRandomInt(max: number) {
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
  return Math.floor(Math.random() * max);
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function min(a: string, b: string) {
  return a < b ? a : b
}

export function max(a: string, b: string) {
  return a > b ? a : b
}

export function arraySum(arr: number[]) {
  return arr.reduce((acc, cur) => acc + cur, 0)
}

export function memoComponentArePropsEqual(oldProps: Record<string, any>, newProps: Record<string, any>) {
  // debug code to pass as second argument to React.memo()
  const keys = Object.keys(oldProps)
  const changed = []
  for (const key of keys) {
    if (oldProps[key] !== newProps[key]) {
      changed.push(key)
    }
  }
  if (changed.length > 0) {
    console.log(`Prop ${changed.join(';')} changed!!`)
    return false
  }
  console.log('same props')
  return true
}

export function isValidDate(date: any) {
  return (date instanceof Date && !isNaN(date as any))
}

export function laxCalc(result: number, ...operands: (number | null)[]): number | null {
  // returns the result, or null if any of the operands is null/undefined
  for (const operand of operands) {
    if (typeof operand !== 'number') {
      return null
    }
  }
  return result
}

export function escapeRegex(str: string) {
  // https://stackoverflow.com/a/3561711/
  return str.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
}

export function convertToNumberOrNull(value: any) {
  if (typeof value === 'number') {
    return value
  } else {
    // covers: null, undefined, '', and any other string
    return null
  }
}
