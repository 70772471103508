import { collection, deleteField, doc, DocumentSnapshot, onSnapshot, query, where } from 'firebase/firestore';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FaPerson } from 'react-icons/fa6';
import { GoArrowBoth } from 'react-icons/go';
import { MdBedroomParent } from 'react-icons/md';
import { TiWarningOutline } from 'react-icons/ti';
import { useParams } from 'react-router-dom';
import { AddButton } from 'src/components/Buttons/AddButton';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { CheckboxButton } from 'src/components/Buttons/CheckboxButton';
import { CheckboxSwitch } from 'src/components/Buttons/CheckboxSwitch';
import { DeleteButton } from 'src/components/Buttons/DeleteButton';
import { MoveUpDownButtons } from 'src/components/Buttons/MoveUpDownButtons';
import { SearchButton } from 'src/components/Buttons/SearchButton';
import { RequestCodeLinkToAggregator } from 'src/components/ContextMenus/RequestCodeLinkToAggregator';
import { EditableField } from 'src/components/EditableField/EditableField';
import { EditableFieldDatepicker } from 'src/components/EditableFieldDatepicker/EditableFieldDatepicker';
import { TypeaheadUserList } from 'src/components/FormControls/TypeaheadUserList';
import { ModalTW } from 'src/components/Modal/ModalTW';
import { getLoadingSpinnerOrNull } from 'src/components/Spinner/util_getLoadingSpinnerOrNull';
import { TopWhiteBarEditControls } from 'src/hooks/autosave/TopWhiteBarEditControls';
import { useUndoRedo } from 'src/hooks/autosave/useUndoRedo';
import { autosaveDocument } from 'src/hooks/autosave/util_autosave';
import { useAppContext } from 'src/hooks/useAppContext';
import { TourRequestType } from 'src/types/types_tourrequest';
import { bookingStatuses, coll_tripquotations, HotelNightType, HotelRoomRawType, HotelRoomType, HotelTripQuotationRowRawType, HotelTripQuotationRowType, PassengerTripQuotationRowType, Season, ServiceTripQuotationRowRawType, ServiceTripQuotationRowType, TripQuotationRawType, TripQuotationType, TripQuotationUpdateObjType } from 'src/types/types_tripquotations';
import { UserSimpleTeamType, UserSimpleUidType } from 'src/types/types_user';
import { dateFormatJpShort, dateisoFormatDayMonth, dateisoFormatJp, dateisoFormatJpShort } from 'src/util/dateformattools';
import { addDaysIso, getSpanDaysExactIso, getTodayIso, iso_from_jst0, jst0_from_iso } from 'src/util/datetools';
import { userrole_isDev } from 'src/util/user_roles';
import { verifyNotDeleted } from 'src/util/util_db_misc';
import { convertServicePriceDates, convertTourRequestDates, serverTimestampAsDate } from 'src/util/util_firestoredates';
import { formatNum } from 'src/util/util_formatnum';
import { arraySum, convertToNumberOrNull } from 'src/util/util_misc';
import { nano_id } from 'src/util/util_nano_id';
import { HotelOccupancyType, HotelPricesType, HotelRoomPricesType, ServiceMappingType, ServicePriceCatalogType, ServicePriceItemType } from '../../types/types_supplierprices';
import { useUserListSimple } from '../ExpenseSheet/util_getuserlist';
import { addMetadataModifiedHotelPricesTable, addMetadataModifiedServicePricesTable, roundUp10 } from '../Payees/SupplierPrices/util_serviceprices';
import { checkDupesAndSaveNewTourRequest, getTwoCharacterDate } from '../Requests/RequestsCrud/util_db_tourrequests';
import { getBlankTourRequest } from '../Requests/util_tourrequests';
import { HotelSearch } from './HotelSearch';
import { RoomSelector } from './RoomSelector';
import { ServiceSearch } from './ServiceSearch';
import { CsvSourceTable } from './UiComponents/CsvSourceTable';
import { SourceDataGrayBox } from './UiComponents/SourceDataGrayBox';
import { ItemNoteAlert, SupplierNoteAlert } from './UiComponents/SupplierNoteAlert';
import { exportToExcel, getMealPlan, statusColors } from './tripQuotationExportUtils';
import { applyHotelRoomPrice, getHotelRoomList, getHotelRoomsObject, getPaxPerRoom, getSeasons, getServicePricePerPax } from './tripQuotationParsingUtils';

const AUDLEY_ID = 'vbJlvc6SlhCJCZeyv2OP';

const getServicesObject = (array: ServiceTripQuotationRowType[]) => {
  const servicesObj: Record<string, ServiceTripQuotationRowRawType> = {}
  for (const [index, row] of array.entries()) {
    const rowDb: any = { ...row, index }
    delete rowDb.id
    servicesObj[row.id] = rowDb
  }
  return servicesObj
}
const getHotelsObject = (array: HotelTripQuotationRowType[]) => {
  const hotelsObj: Record<string, HotelTripQuotationRowRawType> = {}
  for (const [index, row] of array.entries()) {
    const rowDb: any = { ...row, index }
    delete rowDb.id
    hotelsObj[row.id] = rowDb
  }
  return hotelsObj
}

const hotelsfield = (toplevel: 'hotels', rowid: string, fieldname: keyof HotelTripQuotationRowRawType) => {
  return `${toplevel}.${rowid}.${fieldname}`
}

const servicesfield = (toplevel: 'services', rowid: string, fieldname: keyof ServiceTripQuotationRowType) => {
  return `${toplevel}.${rowid}.${fieldname}`
}

export function PageQuotationEdit() {

  const { quotationId } = useParams()
  const [enableEditing, setEnableEditing] = useState(true)
  const [saveStatus, setSaveStatus] = useState<string>()
  const [quotation, setQuotation] = useState<TripQuotationType>();
  const [hotelPrices, setHotelPrices] = useState<HotelPricesType[]>()
  const [servicePrices, setServicePrices] = useState<ServicePriceCatalogType[]>()
  const [filteredHotelPrices, setFilteredHotelPrices] = useState<HotelPricesType[]>([])
  const [filteredServicePrices, setFilteredServicePrices] = useState<ServicePriceCatalogType[]>([])
  const { db, setDbError, userDetails } = useAppContext()
  const [editedCell, setEditedCell] = useState<string | null>(null)
  const [showCsvSource, setShowCsvSource] = useState(false);
  const [shownPopup, setShownPopup] = useState<string | null>(null)
  const [showRowIds, setShowRowIds] = useState(false)

  const [showHotelSearchModal, setShowHotelSearchModal] = useState(false);
  const [shouldSaveMapping, setShouldSaveMapping] = useState(false);
  const [selectedHotel, setSelectedHotel] = useState<HotelPricesType>();
  const [selectedRoom, setSelectedRoom] = useState<HotelRoomPricesType>();
  const [selectedOccupancy, setSelectedOccupancy] = useState<HotelOccupancyType>();
  const [hotelRowToMatch, setHotelRowToMatch] = useState<HotelTripQuotationRowType>();
  const [currentSeasons, setCurrentSeasons] = useState<Season[]>();
  const [hotelSearchQuery, setHotelSearchQuery] = useState<string>('');
  const [extendedInfoShownIds, setExtendedInfoShownIds] = useState<string[]>([])
  const [nightsFirst, setNightsFirst] = useState(false);

  const [showServiceSearchModal, setShowServiceSearchModal] = useState(false);
  const [serviceRowToMatch, setServiceRowToMatch] = useState<ServiceTripQuotationRowType>();
  const [selectedService, setSelectedService] = useState<ServicePriceCatalogType>();
  const [selectedServiceItem, setSelectedServiceItem] = useState<ServicePriceItemType>();
  const [serviceSearchQuery, setServiceSearchQuery] = useState<string>('');
  const [automatchOnDetails, setAutomatchOnDetails] = useState(false);

  const { getUndoRedoHistoryChanges } = useUndoRedo<TripQuotationType>('tripquotationshistory')
  const userListSimple = useUserListSimple()

  const userSimple = useMemo(() => {
    const userSimple: UserSimpleUidType = {
      uid: userDetails.id,
      email: userDetails.email,
      name: userDetails.displayNameEn,
    };
    return userSimple;
  }, [userDetails]);

  useEffect(() => {
    if (quotationId) {

      const processSnapshot = function (snapshot: DocumentSnapshot) {
        const quotationRaw = { ...snapshot.data(), id: snapshot.id } as TripQuotationRawType
        verifyNotDeleted(snapshot.exists(), quotationRaw, quotationId, setDbError, 'tripquotation')
        const serviceRows: ServiceTripQuotationRowType[] = Object.entries(quotationRaw.services).map(([key, value]) => {
          return { ...value, id: key }
        }).sort((a, b) => a.index - b.index)
          .map(row => {
            const { index, ...remainder } = row
            return remainder
          })

        delete (quotationRaw as Partial<TripQuotationRawType>).services;
        const quotation = quotationRaw as unknown as TripQuotationType
        quotation.serviceList = serviceRows;

        // Note: due to the large number of objects, we mutate the objects returned by Firestore instead of creating new ones with the spread operator.
        const hotelRows: HotelTripQuotationRowType[] = Object.entries(quotationRaw.hotels).map(([keyRow, rawRow]) => {
          const hotelRoomList: (HotelRoomType & { index: number })[] = Object.entries(rawRow.hotelRooms || {}).map(([keyRoom, rawRoom]) => {
            const hotelNightList: (HotelNightType & { index: number })[] = Object.entries(rawRoom.hotelNights).map(([keyNight, rawNight]) => {
              // RAW NIGHT: add id
              const night = rawNight as HotelNightType & { index: number };
              night.id = keyNight;
              return night;
            });
            hotelNightList.sort((a, b) => a.index - b.index)
            // RAW ROOM: add id, add hotelNightList, delete hotelNights
            const room = rawRoom as unknown as HotelRoomType & { index: number };
            room.id = keyRoom;
            room.hotelNightList = hotelNightList;
            delete (room as Partial<HotelRoomRawType>).hotelNights;
            return room;
          });
          hotelRoomList.sort((a, b) => a.index - b.index);
          // RAW ROW: add id, add hotelRoomList, delete hotelRooms
          const row = rawRow as unknown as HotelTripQuotationRowType & { index: number };
          row.id = keyRow;
          row.hotelRoomList = hotelRoomList;
          delete (row as Partial<HotelTripQuotationRowRawType>).hotelRooms;
          return row;
        }).sort((a, b) => a.index - b.index)
        delete (quotationRaw as Partial<TripQuotationRawType>).hotels;
        quotation.hotelList = hotelRows;

        if (quotationRaw.passengers) {

          const passengerRows: PassengerTripQuotationRowType[] = Object.entries(quotationRaw.passengers).map(([key, value]) => {
            return { ...value, id: key }
          }).sort((a, b) => a.index - b.index)
            .map(row => {
              const { index, ...remainder } = row
              return remainder
            })
          delete (quotationRaw as Partial<TripQuotationRawType>).passengers;
          quotation.passengerList = passengerRows;
        }

        setQuotation(quotation)
      }
      const q = doc(db, coll_tripquotations, quotationId)
      const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError(`Getting trip quotation ${quotationId}`, err));

      return unsubscribe
    }
  }, [db, setDbError, quotationId])

  useEffect(() => {
    onSnapshot(
      collection(db, 'hotelprices'),
      (snapshot) => {
        const hotelList: HotelPricesType[] = []
        for (const docu of snapshot.docs) {
          const hotelprice = { ...docu.data(), id: docu.id } as HotelPricesType
          hotelList.push(hotelprice)
        }
        hotelList.sort((a, b) => a.facilityIndex - b.facilityIndex)
        setHotelPrices(hotelList)
        setFilteredHotelPrices(hotelList)
      },
      (err) => setDbError(`Error getting hotel prices: ${err}`)
    )
  }, [db, setDbError])

  useEffect(() => {
    onSnapshot(
      collection(db, 'serviceprices'),
      (snapshot) => {
        const serviceList: ServicePriceCatalogType[] = []
        for (const docu of snapshot.docs) {
          const serviceprice = { ...docu.data(), id: docu.id } as ServicePriceCatalogType
          convertServicePriceDates(serviceprice)
          serviceList.push(serviceprice)
        }
        // serviceList.sort((a, b) => stringCompare(a.serviceName, b.serviceName))
        setServicePrices(serviceList)
        setFilteredServicePrices(serviceList);
      },
      (err) => setDbError(`Error getting service prices: ${err}`)
    )
  }, [db, setDbError])

  const [requestsWithAudleyRef, setRequestsWithAudleyRef] = useState<TourRequestType[]>()
  useEffect(() => {
    if (!quotation) {
      // loading...
      return;
    }
    const audleyRef = quotation.generalInfo.agencyItineraryRef;
    if (!audleyRef) {
      console.log('No Audley ref found in quotation')
      setRequestsWithAudleyRef([]);
      return;
    }
    onSnapshot(
      query(collection(db, 'tourrequests'), where('audleyRef', '==', audleyRef)),
      (snapshot) => {
        const list: TourRequestType[] = []
        for (const docu of snapshot.docs) {
          const request = { ...docu.data(), id: docu.id } as TourRequestType
          convertTourRequestDates(request)
          list.push(request)
        }
        setRequestsWithAudleyRef(list);
      },
      (err) => setDbError(`Error getting requests: ${err}`)
    )
  }, [db, setDbError, quotation, quotation?.generalInfo?.agencyItineraryRef])

  useEffect(() => {
    if (hotelPrices) {
      if (hotelSearchQuery) {
        const words = hotelSearchQuery.trim().split(' ')
        const res = hotelPrices.filter(hotelPrice => {
          let wordMatches = 0;
          for (const word of words) {
            if (hotelPrice.facilityName.toLowerCase().includes(word.toLowerCase())) {
              wordMatches++;
            }
          }
          if (wordMatches === words.length) return hotelPrice;
        })
        setFilteredHotelPrices(res);
      } else {
        setFilteredHotelPrices(hotelPrices);
      }
    }
  }, [hotelSearchQuery, hotelPrices])

  useEffect(() => {
    if (servicePrices) {
      if (serviceSearchQuery) {
        const words = serviceSearchQuery.trim().split(' ')
        const res = servicePrices.filter(servicePrice => {
          let wordMatches = 0;
          for (const word of words) {
            if (servicePrice.serviceName.toLowerCase().includes(word.toLowerCase())) {
              wordMatches++;
            }
          }
          if (wordMatches === words.length) return servicePrice;
        })
        setFilteredServicePrices(res);
      } else {
        setFilteredServicePrices(servicePrices);
      }
    }
  }, [serviceSearchQuery, servicePrices])

  const loadingSpinner = getLoadingSpinnerOrNull([
    ['quotation', quotation],
    ['hotelPrices', hotelPrices],
    ['servicePrices', servicePrices],
    ['user list', userListSimple],
    ['requests', requestsWithAudleyRef],
  ])
  if (!quotation || !hotelPrices || !servicePrices || !userListSimple || !requestsWithAudleyRef)
    return loadingSpinner

  const addMetadataModifiedQuotation = (updateObj: Partial<TripQuotationType>, userSimple: UserSimpleUidType) => {
    updateObj.dateModified = serverTimestampAsDate()
    updateObj.userModified = userSimple
  }

  const autosaveNewStep = async (
    userAction: string,
    updateObj: Partial<TripQuotationType> | TripQuotationUpdateObjType,
    sUndoWall: 'u' | 'UNDOWALL', // u = undoable
  ) => {
    return autosaveAnyStep(userAction, updateObj, false, undefined, sUndoWall)
  }

  const autosaveAnyStep = async (
    userAction: string,
    updateObj: any,
    isUndoRedo: boolean,
    undoRedoTargetStep: number | undefined,
    sUndoWall: 'u' | 'UNDOWALL', // u = undoable
  ) => {

    if (!enableEditing) {
      setDbError('Field change despite editing being disabled')
      throw new Error('Field change despite editing being disabled')
    }

    autosaveDocument(
      updateObj,
      userAction,
      isUndoRedo,
      undoRedoTargetStep,
      sUndoWall,
      quotationId!,
      quotation.history,
      userSimple,
      db,
      coll_tripquotations,
      (updateObj: Partial<TripQuotationType>) => addMetadataModifiedQuotation(updateObj, userSimple),
      setSaveStatus,
    )
      .catch((err) => setDbError(`Autosave [autosaveDocument] quotation id=${quotationId} action=[${userAction}]`, err))
  }

  const autosaveUndoRedoStep = async (action: 'Undo' | 'Redo', targetStep: number) => {

    const undoRedoData = await getUndoRedoHistoryChanges(action, targetStep, quotation.history)
    if (!undoRedoData)
      // failed to retrieve history step from db
      return

    const { updateObjHistory, targetStepObj } = undoRedoData

    // fields that we remove in general:
    //   - id: we never save id as a field
    //   - _isDeleted: should always be false
    //   - history: specifically tweaked in a precise way by updateObjHistory
    //   - dateCreated, userCreated: immutable metadata
    //   - dateModified, userModified: metadata set upon save
    // general expense fields that we remove:
    //   - tbd

    // delete the field we specifically don't want:
    delete targetStepObj.id
    delete targetStepObj._isDeleted
    delete targetStepObj.history
    // @ts-expect-error field parentDocumentId doesn't exist on type GeneralExpenseType
    delete targetStepObj.parentDocumentId

    delete targetStepObj.dateCreated;
    delete targetStepObj.userCreated;
    delete targetStepObj.dateModified;
    delete targetStepObj.userModified;

    // add more immutable fields as needed:
    // here we should basically add all fields that are not editable through the CRUD UI

    const updateObj: Partial<TripQuotationType> = {
      ...targetStepObj,
      ...updateObjHistory,
    }

    autosaveAnyStep(
      action, // this isn't actually used
      updateObj,
      true,
      targetStep,
      'u', // undo/redo step is always undoable
    )
  }

  const servicesTotal = arraySum(quotation.serviceList.map(row => row.totalPrice ?? 0))
  const hotelsTotal = arraySum(quotation.hotelList.map(row => row.totalPrice ?? 0))



  const resetHotelSearch = () => {
    setShowHotelSearchModal(false);
    setSelectedHotel(undefined);
    setSelectedOccupancy(undefined);
    setSelectedRoom(undefined);
    setHotelRowToMatch(undefined);
    setCurrentSeasons(undefined);
    setHotelSearchQuery('');
    setFilteredHotelPrices(hotelPrices);
    setShouldSaveMapping(false);
  }
  const resetServiceSearch = () => {
    setShowServiceSearchModal(false);
    setSelectedService(undefined);
    setSelectedServiceItem(undefined);
    setServiceRowToMatch(undefined);
    setServiceSearchQuery('');
    setFilteredServicePrices(servicePrices);
    setShouldSaveMapping(false);
    setAutomatchOnDetails(false);
  }

  const submitRoomChoice = async () => {
    if (hotelRowToMatch && currentSeasons && selectedOccupancy && selectedRoom && selectedHotel) {
      const numOfRooms = hotelRowToMatch.numOfRooms;
      const numOfPax = hotelRowToMatch.numOfPax;
      const numOfNights = hotelRowToMatch.numOfNights;

      if (!numOfRooms) {
        window.alert('Number of rooms is missing.')
        resetHotelSearch();
        return;
      }
      if (!numOfPax) {
        window.alert('Number of pax is missing.')
        resetHotelSearch();
        return;
      }
      if (!numOfNights) {
        window.alert('Number of nights is missing.')
        resetHotelSearch();
        return;
      }

      const hotelRoomList = applyHotelRoomPrice(hotelRowToMatch.hotelRoomList, null, selectedOccupancy);

      let totalPriceRaw = 0;
      for (const room of hotelRoomList) {
        for (const night of room.hotelNightList) {
          totalPriceRaw += night.totalPrice || 0;
        }
      }

      const totalPricePerPaxPerNight = roundUp10(totalPriceRaw / (numOfPax * numOfNights));
      const totalPrice = totalPricePerPaxPerNight * numOfPax;

      const updateObj: Partial<TripQuotationType> = {
        [hotelsfield('hotels', hotelRowToMatch.id, 'hotelPriceId')]: selectedHotel.id,
        [hotelsfield('hotels', hotelRowToMatch.id, 'hotelPlace')]: selectedHotel.city,
        [hotelsfield('hotels', hotelRowToMatch.id, 'hotelName')]: selectedHotel.facilityName,
        [hotelsfield('hotels', hotelRowToMatch.id, 'roomType')]: selectedRoom.roomName,
        [hotelsfield('hotels', hotelRowToMatch.id, 'pricePerPaxPerNight')]: totalPricePerPaxPerNight,
        [hotelsfield('hotels', hotelRowToMatch.id, 'totalPrice')]: totalPrice,
        [hotelsfield('hotels', hotelRowToMatch.id, 'hotelRooms')]: getHotelRoomsObject(hotelRoomList),
      }
      autosaveNewStep('Update hotel', updateObj, 'u')

      if (shouldSaveMapping) {
        const newHotelMappingKey = hotelRowToMatch.sourceHotelName;
        const newRoomMapping = hotelRowToMatch.sourceRoomType.replace(/^(\d+)x/i, '').trim();

        const updateObj: Partial<HotelPricesType> = {
          hotelNameMappings: [
            ...(selectedHotel.hotelNameMappings || []),
            newHotelMappingKey,
          ],
          roomList: selectedHotel.roomList.map(room => {
            if (room.roomName === selectedRoom.roomName) {
              const newRoom: HotelRoomPricesType = {
                ...room,
                roomNameMappings: [
                  ...(room.roomNameMappings || []),
                  newRoomMapping,
                ],
              }
              return newRoom;
            } else {
              return room;
            }
          }),
        }

        await autosaveDocument(
          updateObj,
          'Add hotel mapping',
          false,
          undefined,
          'UNDOWALL',
          selectedHotel.id,
          selectedHotel.history,
          userSimple,
          db,
          'hotelprices',
          (updateObj: any) => addMetadataModifiedHotelPricesTable(updateObj, userSimple),
          null,
        )
      }

      resetHotelSearch();
    }
  }

  const submitServiceChoice = async () => {
    if (serviceRowToMatch && selectedService && selectedServiceItem) {
      let pricePerPax: number | null = null;
      if (serviceRowToMatch.numOfPax) {
        pricePerPax = getServicePricePerPax(serviceRowToMatch.numOfPax, selectedServiceItem);
      }

      const totalPrice = pricePerPax && serviceRowToMatch.numOfPax ? pricePerPax * serviceRowToMatch.numOfPax : null

      const updateObj: Partial<TripQuotationType> = {
        [servicesfield('services', serviceRowToMatch.id, 'servicePriceId')]: selectedService.id,
        [servicesfield('services', serviceRowToMatch.id, 'servicePriceItemId')]: selectedServiceItem.id,
        [servicesfield('services', serviceRowToMatch.id, 'serviceName')]: `${selectedService.serviceName} ${selectedServiceItem.priceItemName}`.trim(),
        [servicesfield('services', serviceRowToMatch.id, 'pricePerPax')]: pricePerPax,
        [servicesfield('services', serviceRowToMatch.id, 'totalPrice')]: totalPrice,
      }
      autosaveNewStep('Update service', updateObj, 'u')

      if (shouldSaveMapping) {
        const newMappingObj: ServiceMappingType = {
          serviceName: serviceRowToMatch.sourceServiceName.trim(), // audley service name
          details: automatchOnDetails ? serviceRowToMatch.serviceDetails.trim() : '', // audley service details
        }

        const updateObj: Partial<ServicePriceCatalogType> = {
          priceItems: selectedService.priceItems.map(priceItem => {
            if (priceItem.id === selectedServiceItem.id) {
              const newPriceItem: ServicePriceItemType = {
                ...priceItem,
                itemMappings: [
                  ...(priceItem.itemMappings || []),
                  newMappingObj,
                ]
              }
              return newPriceItem;
            } else {
              return priceItem;
            }
          })
        };

        await autosaveDocument(
          updateObj,
          'Add service mapping',
          false,
          undefined,
          'UNDOWALL',
          selectedService.id,
          selectedService.history,
          userSimple,
          db,
          'serviceprices',
          (updateObj: any) => addMetadataModifiedServicePricesTable(updateObj, userSimple),
          null,
        )
      }
      resetServiceSearch();
    }
  }

  const seenDates = new Set<string>();
  const overlappingDates = new Set<string>();
  for (const [index, row] of quotation.hotelList.entries()) {
    if (index > 0 && quotation.hotelList[index - 1].checkinDateiso === row.checkinDateiso && quotation.hotelList[index - 1].checkoutDateiso === row.checkoutDateiso) {
      // same group with identical dates
      continue;
    }
    for (let dateiso = row.checkinDateiso; dateiso < row.checkoutDateiso; dateiso = addDaysIso(dateiso, 1)) {
      if (seenDates.has(dateiso)) {
        overlappingDates.add(dateiso);
      } else {
        seenDates.add(dateiso);
      }
    }
  }

  const tabNavigation = (tabKey: -1 | 1 | undefined, prevId: string, nextId: string) => {
    if (tabKey === -1) {
      setEditedCell(prevId || null);
    } else if (tabKey === 1) {
      setEditedCell(nextId || null);
    } else {
      setEditedCell(null);
    }
  }

  const nopriceServices = quotation.serviceList.filter(row => row.totalPrice === null).length;
  const nopriceHotels = quotation.hotelList.filter(row => row.totalPrice === null).length;
  const nopriceItems = nopriceServices + nopriceHotels;

  return (
    <div>
      <Helmet><title>Quotation {quotation.generalInfo.agencyItineraryRefWithVersion}</title></Helmet>

      <TopWhiteBarEditControls
        whiteBarActive={true}
        enableEditing={enableEditing}
        setEnableEditing={setEnableEditing}
        saveStatus={saveStatus}
        setSaveStatus={setSaveStatus}
        autosaveUndoRedoStep={autosaveUndoRedoStep}
        history={quotation.history}
        divFloatingTotals={null}
        userIsAllowedToEdit={true}
      >
        <div className='tw-flex tw-gap-8 tw-items-center'>
          {quotation.generalInfo.agencyItineraryRefWithVersion}
          <CheckboxSwitch id='chkShowCsvSource' label='Show CSV' checked={showCsvSource} onChange={(e) => {
            setShowCsvSource(e.target.checked)
          }} />
          <ButtonTW onClick={() => exportToExcel(quotation)}>Export to Excel File</ButtonTW>
        </div>
      </TopWhiteBarEditControls>
      <ModalTW
        title='Search hotels'
        okLabel='OK'
        show={showHotelSearchModal}
        callbackClose={() => resetHotelSearch()}
        onSubmit={(e) => {
          e.preventDefault();
          submitRoomChoice();
        }}
        body={
          <div className='tw-w-[50vw]'>
            {hotelRowToMatch && (
              <div>
                <div>
                  <div className='tw-p-1'>
                    <span className='tw-font-bold tw-mr-1'>
                      Source hotel:
                    </span>
                    {hotelRowToMatch.sourceHotelName}
                  </div>
                  <div className='tw-p-1'>
                    <span className='tw-font-bold tw-mr-1'>
                      Source room:
                    </span>
                    {hotelRowToMatch.sourceRoomType}
                  </div>
                  <div className='tw-p-1'>
                    <span className='tw-font-bold tw-mr-1'>
                      Pax for this room:
                    </span>
                    {hotelRowToMatch.numOfPax}
                  </div>
                  <div className='tw-p-1'>
                    <span className='tw-font-bold tw-mr-1'>
                      Period:
                    </span>
                    {dateisoFormatJpShort(hotelRowToMatch.checkinDateiso)} - {dateisoFormatJpShort(hotelRowToMatch.checkoutDateiso)}&nbsp;
                    ({currentSeasons?.join(' and ')} season)
                  </div>
                </div>
                {!selectedHotel ? (
                  <HotelSearch
                    hotelSearchQuery={hotelSearchQuery}
                    setHotelSearchQuery={setHotelSearchQuery}
                    hotels={filteredHotelPrices}
                    setSelectedHotel={setSelectedHotel}
                  />
                ) : (
                  <div className='tw-w-[50vw]'>
                    <div className='tw-my-2 tw-font-bold tw-text'>
                      <span className='tw-mr-1'>
                        {selectedHotel.facilityName}
                      </span>
                      <DeleteButton onClick={() => {
                        setSelectedHotel(undefined);
                        setHotelSearchQuery('');
                        setFilteredHotelPrices(hotelPrices);
                      }} />
                    </div>
                    <div className='tw-h-[400px] tw-overflow-scroll'>
                      <RoomSelector
                        selectedRoom={selectedRoom}
                        selectedOccupancy={selectedOccupancy}
                        roomList={selectedHotel.roomList}
                        setSelectedOccupancy={setSelectedOccupancy}
                        setSelectedRoom={setSelectedRoom}
                        seasons={getSeasons(hotelRowToMatch.checkinDateiso, hotelRowToMatch.checkoutDateiso)}
                        numOfPax={hotelRowToMatch.numOfPax}
                      />
                    </div>
                    <div className='tw-mt-4'>
                      <label title='Next time you import a quotation request, these values will be automatically selected if the source is the same'>
                        <input type='checkbox' className='tw-mr-1' checked={shouldSaveMapping} onChange={(e) => setShouldSaveMapping(e.target.checked)} />
                        Use this selection for auto-matching
                      </label>
                    </div>
                    <div className={shouldSaveMapping ? '' : 'tw-opacity-0'}>
                      <table className='
                      [&>*>tr>*]:tw-border [&>*>tr>*]:tw-border-solid [&>*>tr>*]:tw-border-slate-400
                      [&>*>tr>*]:tw-py-1 [&>*>tr>*]:tw-px-2 tw-my-2'>
                        <thead>
                          <tr>
                            <th>Audley quotation</th>
                            <th>Hotel database</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{hotelRowToMatch.sourceHotelName}</td>
                            <td>{selectedHotel.facilityName}</td>
                          </tr>
                          <tr>
                            <td>{hotelRowToMatch.sourceRoomType}</td>
                            <td>{selectedRoom?.roomName}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                  </div>
                )}
              </div>)}
          </div>
        } />
      <ModalTW
        title='Search services'
        okLabel='OK'
        show={showServiceSearchModal}
        callbackClose={() => resetServiceSearch()}
        onSubmit={(e) => {
          e.preventDefault();
          submitServiceChoice();
        }}
        body={
          <ServiceSearch
            services={filteredServicePrices}
            serviceRowToMatch={serviceRowToMatch}
            serviceSearchQuery={serviceSearchQuery}
            setServiceSearchQuery={setServiceSearchQuery}
            selectedService={selectedService}
            setSelectedService={setSelectedService}
            selectedServiceItem={selectedServiceItem}
            setSelectedServiceItem={setSelectedServiceItem}
            shouldSaveMapping={shouldSaveMapping}
            setShouldSaveMapping={setShouldSaveMapping}
            automatchOnDetails={automatchOnDetails}
            setAutomatchOnDetails={setAutomatchOnDetails}
          />
        } />
      <div className='tw-p-5'>
        <div>
          <table className='
          [&>*>tr>*]:tw-border-b
          [&>*>tr>*]:tw-border-solid
          [&>*>tr>*]:tw-border-slate-200
          [&>*>tr>*]:tw-py-2
          [&>*>tr>*]:tw-px-2
          [&>*>tr>*]:tw-bg-white
          [&>*>tr>td]:tw-align-top
          [&>*>tr>th]:tw-align-bottom
          tw-mb-5'>
            <tbody>
              <tr>
                <td className='tw-font-bold'>Eighty Days PIC</td>
                <td>
                  <TypeaheadUserList
                    id='inputPersonInCharge'
                    multiple={true}
                    onChange={(array: UserSimpleTeamType[]) => {
                      const usersDesigners: UserSimpleUidType[] = []
                      const usersDesignersUids: string[] = []

                      for (const user of array) {
                        if (usersDesigners.some((u) => u.uid === user.id))
                          // don't allow duplicates
                          continue
                        const newUser: UserSimpleUidType = {
                          uid: user.id,
                          email: user.email,
                          name: user.name,
                        }
                        usersDesigners.push(newUser)
                        usersDesignersUids.push(user.id)
                      }

                      const updateObj: TripQuotationUpdateObjType = {
                        usersDesigners,
                        usersDesignersUids,
                      };
                      autosaveNewStep(`Set travel designers to ‘${array.map((u) => u.name).join(', ')}’`, updateObj, 'u')
                    }}
                    userList={userListSimple}
                    selected={quotation.usersDesigners.map((user) => {
                      const dbUser = userListSimple.find((u) => u.id === user.uid)
                      const newUser: UserSimpleTeamType = {
                        id: user.uid,
                        email: user.email,
                        name: user.name,
                        teamName: dbUser?.teamName || 'Other',
                      }
                      return newUser
                    })}
                    guidesFirst={false}
                    disabled={!enableEditing}
                  />
                </td>
              </tr>
              <tr>
                <td className='tw-font-bold'>Eighty Days Request Code</td>
                <td>
                  <div className='tw-flex tw-gap-2 tw-justify-between'>
                    {quotation.requestCode ? (
                      <RequestCodeLinkToAggregator
                        requestCode={quotation.requestCode}
                        linkId={'request_link'}
                        shownPopup={shownPopup}
                        setShownPopup={setShownPopup}
                      />
                    ) : (
                      requestsWithAudleyRef.length > 0 ? (
                        requestsWithAudleyRef.map(request => (
                          <div key={request.id}>
                            <ButtonTW onClick={() => {
                              const updateObj: TripQuotationUpdateObjType = {
                                requestId: request.id,
                                requestCode: request.requestCode,
                              }
                              autosaveNewStep(`Assign tour request ‘${request.requestCode}’`, updateObj, 'u')
                            }}>Assign {request.requestCode}</ButtonTW>
                          </div>
                        ))
                      ) : (
                        <ButtonTW
                          disabled={!enableEditing}
                          onClick={() => {

                            const newTourRequest = getBlankTourRequest(userSimple, 'Tour Request created from Audley quotation');
                            // @ts-expect-error delete id
                            delete newTourRequest.id;
                            newTourRequest.dateOriginallyReceived = getTodayIso();
                            newTourRequest.usersDesigners = [...quotation.usersDesigners];
                            newTourRequest.usersDesignersUids = [...quotation.usersDesignersUids];
                            newTourRequest.eightyDaysDepartment = 'Audley'
                            newTourRequest.customerType = 'FIT (Other)'
                            newTourRequest.agencyOrPlatform = 'Audley Travel';
                            newTourRequest.agencyOrPlatformId = AUDLEY_ID;
                            newTourRequest.numOfPax = quotation.passengerList.length;

                            // construct request code
                            const twoCharDate = getTwoCharacterDate(newTourRequest.dateOriginallyReceived)
                            if (!twoCharDate) {
                              alert('Invalid date');
                              return;
                            }

                            const audleyRef = quotation.generalInfo.agencyItineraryRef;
                            if (audleyRef.length !== 7) {
                              alert(`Invalid Audley number [${audleyRef}]`);
                              return;
                            }

                            let letterUser = userDetails.singleCharacterCode
                            if (!letterUser)
                              letterUser = '9'

                            // const tentativeCode = `A${twoCharDate}${audleyNum}`
                            // const checkChar = generateCheckCharacter(tentativeCode);
                            // const finalCode = `A${twoCharDate}${checkChar}-${audleyNum.slice(0, -4)}-${audleyNum.slice(-4)}`
                            const finalCode = `A${twoCharDate}${letterUser}-${audleyRef.slice(0, -4)}-${audleyRef.slice(-4)}`

                            console.log('finalCode', finalCode);

                            newTourRequest.requestCode = finalCode;
                            newTourRequest.audleyRefWithVersion = quotation.generalInfo.agencyItineraryRefWithVersion;
                            newTourRequest.audleyRef = audleyRef;

                            checkDupesAndSaveNewTourRequest(db, userDetails, newTourRequest)
                              .then(({ error, addedDocId }) => {
                                if (error) {
                                  window.alert(error);
                                } else {
                                  const updateObj: TripQuotationUpdateObjType = {
                                    requestCode: finalCode,
                                    requestId: addedDocId,
                                  }
                                  autosaveNewStep(`Assign tour request ‘${finalCode}’`, updateObj, 'UNDOWALL');
                                }
                              })
                              .catch((err) => setDbError('Adding new tour request', err))

                          }}>Create Request</ButtonTW>
                      )
                    )}

                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {showCsvSource && (
          <div className='tw-flex tw-gap-4'>
            <table className='tw-text-xs tw-bg-stone-200 [&>*>tr>*]:tw-border [&>*>tr>*]:tw-border-solid [&>*>tr>*]:tw-border-stone-400
    [&>*>tr>*]:tw-p-1 tw-m-1'>
              <tbody>
                {Object.entries(quotation.generalInfo.generalCsvData).map(([key, row]) => {
                  const rowNum = Number(key.match(/^row_(\d+)$/)?.[1]);
                  return [rowNum, row] as [number, string[]];
                })
                  .sort((a, b) => a[0] - b[0])
                  .filter(([rowNum, row]) => row[0] !== 'sep=' && !(row[0].trim() === '' && row.length === 1))
                  .map(([rowNum, row]) => (
                    <tr key={rowNum}>
                      {row.map((cell, i) => <td key={i}>{cell}</td>)}
                    </tr>
                  ))}
              </tbody>
            </table>

            <table className='tw-text-xs tw-bg-stone-200 [&>*>tr>*]:tw-border [&>*>tr>*]:tw-border-solid [&>*>tr>*]:tw-border-stone-400
    [&>*>tr>*]:tw-p-1 tw-m-1'>
              <tbody>
                {quotation.passengerCsvData.map((row, index) => (
                  <tr key={index}>
                    {row.cells.map((cell, i) => <td key={i}>{cell}</td>)}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div className='tw-flex tw-flex-row tw-gap-5'>
          <div className='tw-w-1/4'>
            <table className='
          [&>*>tr>*]:tw-border-b
          [&>*>tr>*]:tw-border-solid
          [&>*>tr>*]:tw-border-slate-200
          [&>*>tr>*]:tw-py-2
          [&>*>tr>*]:tw-px-2
          [&>*>tr>*]:tw-bg-white
          [&>*>tr>td]:tw-align-top
          [&>*>tr>th]:tw-align-bottom'>
              <tbody>
                <tr>
                  <td className='tw-font-bold'>Audley Reference</td>
                  <td>
                    {quotation.generalInfo.agencyItineraryRefWithVersion}
                  </td>
                </tr>
                <tr>
                  <td className='tw-font-bold'>Audley Owner</td>
                  <td>
                    <EditableField
                      tableid={'tableid'}
                      rowid={'rowid'}
                      fieldname={'agencyOwner'}
                      validationType={''}
                      currentValue={quotation.generalInfo.agencyOwner || ''}
                      isClickableToEdit={enableEditing}
                      editedCell={editedCell}
                      setEditedCell={setEditedCell}
                      callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                        const updateObj: TripQuotationUpdateObjType = {
                          'generalInfo.agencyOwner': dbvalue,
                        }
                        autosaveNewStep(`Change agency owner to ‘${dbvalue}’`, updateObj, 'u')
                        tabNavigation(tabKey, '', 'tableid_rowid_businessUnit');
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='tw-font-bold'>Business Unit</td>
                  <td>
                    <EditableField
                      tableid={'tableid'}
                      rowid={'rowid'}
                      fieldname={'businessUnit'}
                      validationType={''}
                      currentValue={quotation.generalInfo.agencyBusinessUnit || ''}
                      isClickableToEdit={enableEditing}
                      editedCell={editedCell}
                      setEditedCell={setEditedCell}
                      callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                        const updateObj: TripQuotationUpdateObjType = {
                          'generalInfo.agencyBusinessUnit': dbvalue,
                        }
                        autosaveNewStep(`Change agency business unit to ‘${dbvalue}’`, updateObj, 'u')
                        tabNavigation(tabKey, 'tableid_rowid_agencyOwner', 'tableid_rowid_primaryContact');
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='tw-font-bold'>Primary Contact</td>
                  <td>
                    <EditableField
                      tableid={'tableid'}
                      rowid={'rowid'}
                      fieldname={'primaryContact'}
                      validationType={''}
                      currentValue={quotation.generalInfo.agencyPrimaryContact || ''}
                      isClickableToEdit={enableEditing}
                      editedCell={editedCell}
                      setEditedCell={setEditedCell}
                      callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                        const updateObj: TripQuotationUpdateObjType = {
                          'generalInfo.agencyPrimaryContact': dbvalue,
                        }
                        autosaveNewStep(`Change agency primary contact to ‘${dbvalue}’`, updateObj, 'u')
                        tabNavigation(tabKey, 'tableid_rowid_businessUnit', 'tableid_rowid_paxName');
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='tw-w-1/4'>
            <table className='
          [&>*>tr>*]:tw-border-b
          [&>*>tr>*]:tw-border-solid
          [&>*>tr>*]:tw-border-slate-200
          [&>*>tr>*]:tw-py-2
          [&>*>tr>*]:tw-px-2
          [&>*>tr>*]:tw-bg-white
          [&>*>tr>td]:tw-align-top
          [&>*>tr>th]:tw-align-bottom'>
              <tbody>

                <tr>
                  <td className='tw-font-bold'>Client Name</td>
                  <td>
                    <EditableField
                      tableid={'tableid'}
                      rowid={'rowid'}
                      fieldname={'paxName'}
                      validationType={''}
                      currentValue={quotation.generalInfo.paxName}
                      isClickableToEdit={enableEditing}
                      editedCell={editedCell}
                      setEditedCell={setEditedCell}
                      callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                        const updateObj: TripQuotationUpdateObjType = {
                          'generalInfo.paxName': dbvalue,
                        }
                        autosaveNewStep(`Change pax name to ‘${dbvalue}’`, updateObj, 'u')
                        tabNavigation(tabKey, 'tableid_rowid_primaryContact', 'tableid_rowid_numOfPax');
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='tw-font-bold'># of Pax</td>
                  <td>
                    <EditableField
                      tableid={'tableid'}
                      rowid={'rowid'}
                      fieldname={'numOfPax'}
                      validationType={''}
                      currentValue={quotation.generalInfo.numOfPax || 0}
                      isClickableToEdit={enableEditing}
                      editedCell={editedCell}
                      setEditedCell={setEditedCell}
                      callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                        const updateObj: TripQuotationUpdateObjType = {
                          'generalInfo.numOfPax': dbvalue,
                        }
                        autosaveNewStep(`Change number of pax to ‘${dbvalue}’`, updateObj, 'u')
                        tabNavigation(tabKey, 'tableid_rowid_paxName', '');
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='tw-font-bold'>Trip start date</td>
                  <td>
                    <EditableFieldDatepicker
                      currentValue_jst0={quotation.generalInfo.tripStartDateiso ? jst0_from_iso(quotation.generalInfo.tripStartDateiso) : null}
                      isClickableToEdit={enableEditing}
                      callbackCommitChange={(date_jst0) => {
                        const tripStartDateiso = date_jst0 ? iso_from_jst0(date_jst0) : ''
                        const updateObj: TripQuotationUpdateObjType = {
                          'generalInfo.tripStartDateiso': tripStartDateiso,
                        }
                        if (tripStartDateiso && quotation.generalInfo.tripEndDateiso) {
                          const days = 1 + getSpanDaysExactIso(tripStartDateiso, quotation.generalInfo.tripEndDateiso)
                          updateObj['generalInfo.tripDurationDays'] = days;
                        }

                        autosaveNewStep(`Change start date to ‘${date_jst0 ? dateFormatJpShort(date_jst0) : ''}’`, updateObj, 'u')
                        setEditedCell(null)
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='tw-font-bold'>Trip end date</td>
                  <td>
                    <EditableFieldDatepicker
                      currentValue_jst0={quotation.generalInfo.tripEndDateiso ? jst0_from_iso(quotation.generalInfo.tripEndDateiso) : null}
                      isClickableToEdit={enableEditing}
                      callbackCommitChange={(date_jst0) => {
                        const tripEndDateiso = date_jst0 ? iso_from_jst0(date_jst0) : ''
                        const updateObj: TripQuotationUpdateObjType = {
                          'generalInfo.tripEndDateiso': tripEndDateiso,
                        }
                        if (quotation.generalInfo.tripStartDateiso && tripEndDateiso) {
                          const days = 1 + getSpanDaysExactIso(quotation.generalInfo.tripStartDateiso, tripEndDateiso)
                          updateObj['generalInfo.tripDurationDays'] = days;
                        }

                        autosaveNewStep(`Change end date to ‘${date_jst0 ? dateFormatJpShort(date_jst0) : ''}’`, updateObj, 'u')
                        setEditedCell(null)
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='tw-font-bold'># of days</td>
                  <td>
                    <span className={(quotation.generalInfo.tripDurationDays || 0) <= 0 ? 'tw-text-red-500' : ''}>
                      {quotation.generalInfo.tripDurationDays}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='tw-w-1/4'>
            {quotation.passengerList && (
              <table className='
          [&>*>tr>*]:tw-border-b
          [&>*>tr>*]:tw-border-solid
          [&>*>tr>*]:tw-border-slate-200
          [&>*>tr>*]:tw-py-2
          [&>*>tr>*]:tw-px-2
          [&>*>tr>*]:tw-bg-white
          [&>*>tr>td]:tw-align-top
          [&>*>tr>th]:tw-align-bottom'>
                <thead>
                  <tr>
                    <th>First name</th>
                    <th>Last name</th>
                    <th>Date of birth</th>
                  </tr>
                </thead>
                <tbody>
                  {quotation.passengerList.map((passenger) => (
                    <tr key={passenger.id}>
                      <td>{passenger.firstName}</td>
                      <td>{passenger.lastName}</td>
                      <td>{dateisoFormatJpShort(passenger.dateOfBirth)}</td>
                    </tr>)
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div>
          {nopriceItems && (
            <div className='tw-flex tw-gap-4 tw-items-center
            tw-border tw-border-solid tw-border-blue-900/30 tw-bg-blue-100/50 tw-p-4 tw-rounded tw-my-4 tw-text-blue-950'>
              <TiWarningOutline className='tw-text-3xl' />
              <div>
                There are {nopriceHotels ? `${nopriceHotels} hotel(s)` : ''}
                {nopriceHotels && nopriceServices ? ' and ' : ''}
                {nopriceServices ? `${nopriceServices} service(s) ` : ''}
                without a price. Please fill in all prices before sending to client.
              </div>
            </div>
          )}
        </div>
        {userrole_isDev(userDetails.roles) && (
          <div>
            <CheckboxSwitch id='chkShowRowIds' label='DEV: Show row ids' checked={showRowIds} onChange={(e) => {
              setShowRowIds(e.target.checked)
            }} />
          </div>
        )}
        <div className='tw-mt-5'>
          <table className='
           tw-my-2
          [&>*>tr>*]:tw-border-solid
          [&>*>tr>*]:tw-border-slate-200
          [&>*>tr>*]:tw-py-2
          [&>*>tr>*]:tw-px-2
          [&>*>tr>*]:tw-bg-white
          [&>*>tr>td]:tw-align-top
          [&>*>tr>th]:tw-align-bottom
          tw-h-1' // tw-h-1 needed for div height 100% below. see https://stackoverflow.com/a/59030659/
          >
            <thead>
              <tr className='[&>th]:tw-border-b-2'>
                <th></th>
                <th>Check-in date</th>
                <th>Check-out date</th>
                <th title='Calculated from check-in and check-out dates'>Nights</th>
                <th>{/* expand chevron */}</th>
                <th>City</th>
                <th>Hotel name</th>
                <th>Room type</th>
                <th>Meals</th>
                <th># of Rooms</th>
                <th>Pax</th>
                <th>Price per pax per night</th>
                <th>BAR rate</th>
                <th>Total</th>
                <th>Actions</th>
                <th className='!tw-bg-transparent'></th>
                <th>Booking Status</th>
                <th>Booking Remarks</th>
                <th className='!tw-bg-transparent'></th>
                <th>80days internal memo</th>
              </tr>
            </thead>
            <tbody>
              {quotation.hotelList.map((row, index) => {
                const showExtended = extendedInfoShownIds.includes(row.id)

                let dateSpan: number;
                const sameDateRows: HotelTripQuotationRowType[] = [];
                if (index > 0 && row.checkinDateiso === quotation.hotelList[index - 1].checkinDateiso && row.checkoutDateiso === quotation.hotelList[index - 1].checkoutDateiso) {
                  dateSpan = 0;
                  sameDateRows.push(row);
                } else {
                  dateSpan = 0;
                  for (let i = index; i < quotation.hotelList.length; i++) {
                    if (row.checkinDateiso === quotation.hotelList[i].checkinDateiso && row.checkoutDateiso === quotation.hotelList[i].checkoutDateiso) {
                      dateSpan
                        += 1
                        + (extendedInfoShownIds.includes(quotation.hotelList[i].id) ? 1 : 0);
                      // + (showCsvSource ? 1 : 0);
                      sameDateRows.push(quotation.hotelList[i]);
                    } else {
                      break;
                    }
                  }
                  if (showCsvSource) dateSpan++;
                }

                const discontinuousDates = index > 0 && row.checkinDateiso !== quotation.hotelList[index - 1].checkoutDateiso;

                const updateRoomListPricePerPax = ({ pricePerPaxPerNight }: { pricePerPaxPerNight: number | null }) => {
                  const newHotelRooms = row.hotelRoomList.map(room => {
                    return {
                      ...room,
                      hotelNightList: room.hotelNightList.map(night => {
                        return {
                          ...night,
                          pricePerPax: pricePerPaxPerNight,
                          totalPrice: (night.numOfPax && pricePerPaxPerNight) ? night.numOfPax * pricePerPaxPerNight : null,
                        }
                      }),
                    }
                  });
                  return newHotelRooms;
                }

                const updateRoomListNumOfPax = (numOfPaxAllRooms: number | null) => {
                  const { numOfPaxPerRoom_min, excessPax } = getPaxPerRoom(numOfPaxAllRooms, row.numOfRooms);
                  const newHotelRooms = row.hotelRoomList.map((room, iRoom) => {
                    const numOfPaxPerRoom = (numOfPaxPerRoom_min && excessPax !== null) ? numOfPaxPerRoom_min + (iRoom < excessPax ? 1 : 0) : null;
                    return {
                      ...room,
                      hotelNightList: room.hotelNightList.map(night => {
                        return {
                          ...night,
                          numOfPax: numOfPaxPerRoom,
                          totalPrice: (numOfPaxPerRoom && night.pricePerPax) ? numOfPaxPerRoom * night.pricePerPax : null,
                        }
                      })
                    }
                  });
                  return newHotelRooms;
                }

                const borderTop = (showCsvSource && dateSpan > 0) ? '' : 'tw-border-t-2';

                const blueBubbleCell = (
                  <div className='tw-flex tw-flex-col tw-h-full tw-justify-evenly tw-gap-0.5 tw-relative _tw-top-[-0.75em]'>
                    {[...Array(row.numOfNights || 0).keys()].map((i) => {
                      const dateiso = addDaysIso(row.checkinDateiso, i);
                      const isOverlapping = overlappingDates.has(dateiso);
                      const highlight = isOverlapping || (i === 0 && discontinuousDates); // could be discontinuous without overlapping, e.g. if there is a gap in the dates.
                      return (
                        <div key={i} className={
                          `tw-border tw-px-2 tw-border-solid tw-rounded
                                 ${highlight ? 'tw-border-red-300 tw-bg-red-50' : 'tw-border-blue-300 tw-bg-blue-50'}
                                   tw-grow tw-flex tw-flex-col tw-justify-center tw-text-sm`}>
                          <div className='tw-whitespace-nowrap tw-text-center'>
                            Day {1 + getSpanDaysExactIso(quotation.generalInfo.tripStartDateiso, dateiso)}
                          </div>
                          <div className='tw-whitespace-nowrap tw-text-center'>
                            {dateisoFormatDayMonth(dateiso)}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )

                return (
                  <React.Fragment key={row.id}>
                    {showCsvSource && dateSpan > 0 && (
                      <tr>
                        <td rowSpan={dateSpan} className='tw-border-t-2 !tw-p-0'>
                          {blueBubbleCell}
                        </td>

                        <td colSpan={17} className='tw-border-t-2 !tw-p-0'>
                          <CsvSourceTable
                            sourceCsvHeaders={quotation.sourceCsvHeaders?.slice(0, 10)}
                            csvdatarows={sameDateRows.map(row => row.csvdata).filter(row => row !== null)}
                          />
                        </td>
                      </tr>
                    )}
                    <tr>
                      {dateSpan > 0 && !showCsvSource && (
                        <td rowSpan={dateSpan} className='tw-border-t-2 !tw-p-0'>
                          {blueBubbleCell}
                        </td>
                      )}
                      <td className={`tw-min-w-28 ${dateSpan > 0 ? borderTop : ''} ${dateSpan === 0 ? '[&_input]:!tw-text-white' : ''}`}>
                        <div className='tw-whitespace-nowrap'>
                          {row.checkinDateiso !== row.sourceCheckinDateiso && dateSpan > 0 && (
                            <span className='tw-text-xl tw-mr-1 tw-text-red-500' title={`Audley csv has a different date: ${dateisoFormatJp(row.sourceCheckinDateiso)}`}>
                              <TiWarningOutline />
                            </span>
                          )}
                          <EditableFieldDatepicker
                            currentValue_jst0={row.checkinDateiso ? jst0_from_iso(row.checkinDateiso) : null}
                            isClickableToEdit={enableEditing}
                            callbackCommitChange={(date_jst0) => {
                              const checkinDateiso = date_jst0 ? iso_from_jst0(date_jst0) : ''
                              let numOfNights = checkinDateiso && row.checkoutDateiso ? getSpanDaysExactIso(checkinDateiso, row.checkoutDateiso) : null;
                              let checkoutDateiso = row.checkoutDateiso
                              if (typeof numOfNights === 'number' && numOfNights <= 0) {
                                // checkin date was set after checkout date: move checkout date back
                                checkoutDateiso = addDaysIso(checkinDateiso, 1);
                                numOfNights = 1;
                              }
                              const updateObj: Record<string, any> = {}
                              for (const sameDateRow of sameDateRows) {
                                updateObj[hotelsfield('hotels', sameDateRow.id, 'checkinDateiso')] = checkinDateiso;
                                updateObj[hotelsfield('hotels', sameDateRow.id, 'checkoutDateiso')] = checkoutDateiso;
                                updateObj[hotelsfield('hotels', sameDateRow.id, 'numOfNights')] = numOfNights;
                                updateObj[hotelsfield('hotels', sameDateRow.id, 'hotelRooms')] = getHotelRoomsObject(getHotelRoomList(row.numOfRooms, row.numOfPax, checkinDateiso, checkoutDateiso));
                                updateObj[hotelsfield('hotels', sameDateRow.id, 'hotelPriceId')] = null;
                              }
                              autosaveNewStep(`Change accommodation check-in to ‘${checkinDateiso}’`, updateObj, 'u')
                              setEditedCell(null)
                            }}
                          />
                        </div>
                      </td>
                      <td className={`tw-min-w-28 ${dateSpan > 0 ? borderTop : ''} ${dateSpan === 0 ? '[&_input]:!tw-text-white' : ''}`}>
                        <div className='tw-whitespace-nowrap'>
                          {row.checkoutDateiso !== row.sourceCheckoutDateiso && dateSpan > 0 && (
                            <span className='tw-text-xl tw-mr-1 tw-text-red-500' title={`Audley csv has a different date: ${dateisoFormatJp(row.sourceCheckoutDateiso)}`}>
                              <TiWarningOutline />
                            </span>
                          )}
                          <EditableFieldDatepicker
                            currentValue_jst0={row.checkoutDateiso ? jst0_from_iso(row.checkoutDateiso) : null}
                            isClickableToEdit={enableEditing}
                            callbackCommitChange={(date_jst0) => {
                              const checkoutDateiso = date_jst0 ? iso_from_jst0(date_jst0) : ''
                              let numOfNights = row.checkinDateiso && checkoutDateiso ? getSpanDaysExactIso(row.checkinDateiso, checkoutDateiso) : null;
                              let checkinDateiso = row.checkinDateiso
                              if (typeof numOfNights === 'number' && numOfNights <= 0) {
                                // checkout date was set before checkin date: move checkin date earlier
                                checkinDateiso = addDaysIso(checkoutDateiso, -1);
                                numOfNights = 1;
                              }
                              if (typeof numOfNights === 'number' && numOfNights <= 0)
                                return; // reject invalid inputs checkin<=checkout
                              const updateObj: Record<string, any> = {}
                              for (const sameDateRow of sameDateRows) {
                                updateObj[hotelsfield('hotels', sameDateRow.id, 'checkinDateiso')] = checkinDateiso;
                                updateObj[hotelsfield('hotels', sameDateRow.id, 'checkoutDateiso')] = checkoutDateiso;
                                updateObj[hotelsfield('hotels', sameDateRow.id, 'numOfNights')] = numOfNights;
                                updateObj[hotelsfield('hotels', sameDateRow.id, 'hotelRooms')] = getHotelRoomsObject(getHotelRoomList(row.numOfRooms, row.numOfPax, checkinDateiso, checkoutDateiso));
                                updateObj[hotelsfield('hotels', sameDateRow.id, 'hotelPriceId')] = null;
                              }
                              autosaveNewStep(`Change accommodation check-out to ‘${checkoutDateiso}’`, updateObj, 'u')
                              setEditedCell(null)
                            }}
                          />
                        </div>
                      </td>
                      <td className={`${dateSpan > 0 ? borderTop : ''} ${dateSpan === 0 ? 'tw-text-white' : ''}`}>
                        {row.numOfNights}
                        <SupplierNoteAlert note={row.supplierNote} />
                        <ItemNoteAlert note={row.itemNote} />
                      </td>
                      <td className={borderTop}>
                        {row.hotelRoomList.length > 0 && (
                          <div onClick={() => {
                            if (showExtended) {
                              setExtendedInfoShownIds(extendedInfoShownIds.filter((x) => x !== row.id))
                            } else {
                              setExtendedInfoShownIds([...extendedInfoShownIds, row.id]);
                            }
                          }}><i className={`bi ${showExtended ? 'bi-chevron-down' : 'bi-chevron-right'} tw-cursor-pointer`}></i>
                          </div>
                        )}
                      </td>
                      <td className={`!tw-p-0 ${borderTop}`}>
                        <SourceDataGrayBox onArrowClick={() => {
                          const updateObj: Partial<TripQuotationType> = {
                            [hotelsfield('hotels', row.id, 'hotelPlace')]: row.sourceHotelPlace,
                          }
                          autosaveNewStep(`Change accommodation place to ‘${row.sourceHotelPlace}’`, updateObj, 'u')
                        }}>
                          {row.sourceHotelPlace}
                        </SourceDataGrayBox>
                        <div className='tw-p-2'>
                          <EditableField
                            tableid={'hotelList'}
                            rowid={row.id}
                            fieldname={'hotelPlace'}
                            validationType={''}
                            currentValue={row.hotelPlace}
                            isClickableToEdit={enableEditing}
                            editedCell={editedCell}
                            setEditedCell={setEditedCell}
                            callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                              const updateObj: Partial<TripQuotationType> = {
                                [hotelsfield('hotels', row.id, 'hotelPlace')]: dbvalue,
                              }
                              autosaveNewStep(`Change accommodation place to ‘${dbvalue}’`, updateObj, 'u')
                              tabNavigation(tabKey, '', `hotelList_${row.id}_hotelName`);
                            }}
                          />
                        </div>
                      </td>
                      <td className={`tw-min-w-48 !tw-p-0 ${borderTop}`}>
                        <SourceDataGrayBox onArrowClick={() => {
                          const updateObj: Partial<TripQuotationType> = {
                            [hotelsfield('hotels', row.id, 'hotelName')]: row.sourceHotelName,
                          }
                          autosaveNewStep(`Change accommodation facility name to ‘${row.sourceHotelName}’`, updateObj, 'u')
                        }}>
                          {row.sourceHotelName}
                        </SourceDataGrayBox>
                        <div className='tw-p-2'>
                          <EditableField
                            tableid={'hotelList'}
                            rowid={row.id}
                            fieldname={'hotelName'}
                            validationType={''}
                            currentValue={row.hotelName}
                            isClickableToEdit={enableEditing}
                            editedCell={editedCell}
                            setEditedCell={setEditedCell}
                            callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                              const updateObj: Partial<TripQuotationType> = {
                                [hotelsfield('hotels', row.id, 'hotelName')]: dbvalue,
                                [hotelsfield('hotels', row.id, 'hotelPriceId')]: null,
                                // [hotelsfield('hotels', row.id, 'hotelRooms')]: null,
                              }
                              autosaveNewStep(`Change accommodation facility name to ‘${dbvalue}’`, updateObj, 'u')
                              tabNavigation(tabKey, `hotelList_${row.id}_hotelPlace`, `hotelList_${row.id}_roomType`);
                            }}
                          />
                        </div>
                      </td>
                      <td className={`tw-min-w-48 !tw-p-0 ${borderTop}`}>
                        <SourceDataGrayBox onArrowClick={() => {
                          const updateObj: Partial<TripQuotationType> = {
                            [hotelsfield('hotels', row.id, 'roomType')]: row.sourceRoomType,
                          }
                          autosaveNewStep(`Change accommodation room type to ‘${row.sourceRoomType}’`, updateObj, 'u')
                        }}>
                          {row.sourceRoomType}
                        </SourceDataGrayBox>
                        <div className='tw-p-2'>
                          <EditableField
                            tableid={'hotelList'}
                            rowid={row.id}
                            fieldname={'roomType'}
                            validationType={''}
                            currentValue={row.roomType}
                            isClickableToEdit={enableEditing}
                            editedCell={editedCell}
                            setEditedCell={setEditedCell}
                            callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                              const updateObj: Partial<TripQuotationType> = {
                                [hotelsfield('hotels', row.id, 'roomType')]: dbvalue,
                                // [hotelsfield('hotels', row.id, 'hotelRooms')]: null,
                                [hotelsfield('hotels', row.id, 'hotelPriceId')]: null,
                              }
                              autosaveNewStep(`Change accommodation room type to ‘${dbvalue}’`, updateObj, 'u')
                              tabNavigation(tabKey, `hotelList_${row.id}_hotelName`, `hotelList_${row.id}_numOfRooms`);
                            }}
                          />
                        </div>
                      </td>
                      <td className={borderTop}>
                        <div className='tw-flex tw-gap-1'>
                          <CheckboxButton
                            checked={row.breakfastIncluded}
                            disabled={!enableEditing}
                            onClick={() => {
                              if (!enableEditing)
                                return;
                              const newVal = !row.breakfastIncluded;
                              let updateObj: Partial<TripQuotationType> = {
                                [hotelsfield('hotels', row.id, 'breakfastIncluded')]: newVal,
                              }
                              if (newVal === false) { // you can't have dinner without breakfast
                                updateObj = {
                                  ...updateObj,
                                  [hotelsfield('hotels', row.id, 'dinnerIncluded')]: false,
                                }
                              }
                              autosaveNewStep(`Change accommodation breakfast to ‘${newVal}’`, updateObj, 'u')
                              setEditedCell(null)
                            }}
                          >B</CheckboxButton>
                          <CheckboxButton
                            checked={row.dinnerIncluded}
                            disabled={!enableEditing}
                            onClick={() => {
                              if (!enableEditing)
                                return;
                              const newVal = !row.dinnerIncluded;
                              let updateObj: Partial<TripQuotationType> = {
                                [hotelsfield('hotels', row.id, 'dinnerIncluded')]: newVal,
                              }
                              if (newVal === true) { // you can't have dinner without breakfast
                                updateObj = {
                                  ...updateObj,
                                  [hotelsfield('hotels', row.id, 'breakfastIncluded')]: true,
                                }
                              }
                              autosaveNewStep(`Change accommodation dinner to ‘${newVal}’`, updateObj, 'u')
                              setEditedCell(null)
                            }}>D</CheckboxButton>
                        </div>
                        <div className='tw-text-center tw-whitespace-nowrap'>
                          {getMealPlan(row.breakfastIncluded, row.dinnerIncluded)}
                          {row.sourceMealCode !== getMealPlan(row.breakfastIncluded, row.dinnerIncluded) && (
                            <span className='tw-text-xl tw-mr-1 tw-text-red-500' title={`Audley csv has a different meal data: “${row.sourceMeals}” ${row.sourceMealCode}`}>
                              <TiWarningOutline />
                            </span>
                          )}
                        </div>
                      </td>
                      <td className={`tw-whitespace-nowrap ${borderTop}`}>
                        <EditableField
                          tableid={'hotelList'}
                          rowid={row.id}
                          fieldname={'numOfRooms'}
                          validationType={'number'}
                          currentValue={row.numOfRooms}
                          isClickableToEdit={enableEditing}
                          editedCell={editedCell}
                          setEditedCell={setEditedCell}
                          callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                            const newNumOfRooms = dbvalue;
                            const updateObj: Record<string, any> = {
                              [`hotels.${row.id}.numOfRooms`]: newNumOfRooms,
                              // [hotelsfield('hotels', row.id, 'hotelRooms')]: null,
                              // [hotelsfield('hotels', row.id, 'hotelPriceId')]: null,
                            }

                            let newHotelRoomsList: HotelRoomType[] = [];
                            if (typeof newNumOfRooms === 'number' && newNumOfRooms > 0) {
                              newHotelRoomsList = getHotelRoomList(newNumOfRooms, row.numOfPax, row.checkinDateiso, row.checkoutDateiso);
                              newHotelRoomsList = applyHotelRoomPrice(newHotelRoomsList, row.pricePerPaxPerNight, null);
                            }
                            updateObj[hotelsfield('hotels', row.id, 'hotelRooms')] = getHotelRoomsObject(newHotelRoomsList);

                            autosaveNewStep(`Change numOfRooms to ‘${dbvalue}’`, updateObj, 'u')
                            tabNavigation(tabKey, `hotelList_${row.id}_roomType`, `hotelList_${row.id}_numOfPax`);
                          }}
                          getDisplayValue={(value) => {
                            if (typeof value === 'number') {
                              const list = [];
                              for (let i = 0; i < value; i++) {
                                list.push(<MdBedroomParent key={i} className='tw-text-[1.5em] tw-text-slate-700' />);
                              }
                              // return <><MdBedroomParent className='tw-text-[1.5em]' /> {`${value} ${value === 1 ? 'room' : 'rooms'}`}</>;
                              return (
                                <>
                                  <div>{list}</div>
                                  <div>{`${value} ${value === 1 ? 'room' : 'rooms'}`}</div>
                                </>
                              );
                            } else {
                              return value;
                            }
                          }}
                        />
                      </td>
                      <td className={`tw-whitespace-nowrap ${borderTop}`}>
                        <EditableField
                          tableid={'hotelList'}
                          rowid={row.id}
                          fieldname={'numOfPax'}
                          validationType={'number'}
                          currentValue={row.numOfPax}
                          isClickableToEdit={enableEditing}
                          editedCell={editedCell}
                          setEditedCell={setEditedCell}
                          callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                            const numOfPaxAllRooms = convertToNumberOrNull(dbvalue) || null // don't allow zero pax, convert zero to null
                            const newHotelRooms = updateRoomListNumOfPax(numOfPaxAllRooms);
                            const updateObj: Record<string, any> = {
                              [hotelsfield('hotels', row.id, 'numOfPax')]: numOfPaxAllRooms,
                              [hotelsfield('hotels', row.id, 'hotelRooms')]: getHotelRoomsObject(newHotelRooms),
                              // [hotelsfield('hotels', row.id, 'hotelPriceId')]: null,
                            }
                            if (numOfPaxAllRooms !== null) {
                              if (row.numOfNights) {
                                if (row.pricePerPaxPerNight !== null) {
                                  // numOfPax & numOfNights & pricePerPax --> totalPrice
                                  updateObj[hotelsfield('hotels', row.id, 'totalPrice')] = numOfPaxAllRooms * row.pricePerPaxPerNight * row.numOfNights;
                                } else if (row.totalPrice !== null && numOfPaxAllRooms > 0) {
                                  // numOfPax & totalPrice --> pricePerPax
                                  updateObj[hotelsfield('hotels', row.id, 'pricePerPaxPerNight')] = Math.round(row.totalPrice / (numOfPaxAllRooms * row.numOfNights));
                                }
                              }
                            } else {
                              // numOfPax was deleted
                              if (row.pricePerPaxPerNight !== null) {
                                // numOfPax deleted & pricePerPax present --> totalPrice deleted
                                updateObj[hotelsfield('hotels', row.id, 'totalPrice')] = null;
                              } else {
                                // no pricePerPax present -> don't touch total (leave it if present) as it's ok to have a total with no numOfPax and no pricePerPax
                              }
                            }
                            autosaveNewStep(`Change accommodation number of pax to ‘${numOfPaxAllRooms}’`, updateObj, 'u')
                            tabNavigation(tabKey, `hotelList_${row.id}_numOfRooms`, `hotelList_${row.id}_pricePerPax`);
                          }}
                          getDisplayValue={(value) => {
                            if (typeof value === 'number') {
                              const list = [];
                              for (let i = 0; i < value; i++) {
                                list.push(<FaPerson key={i} className='tw-text-[1.2em] tw-text-slate-700 tw-mx-[-0.15em]' />);
                              }
                              return (
                                <>
                                  <div>{list}</div>
                                  <div>{value} pax</div>
                                  {(row.numOfRooms ?? 0) > 1 && (row.numOfPax ?? 0) > 0 && <div>(in {row.numOfRooms} rooms)</div>}
                                </>
                              );
                            } else {
                              return value;
                            }
                          }}
                        />
                      </td>
                      <td className={borderTop}>
                        <EditableField
                          tableid={'hotelList'}
                          rowid={row.id}
                          fieldname={'pricePerPaxPerNight'}
                          validationType={'number'}
                          currentValue={row.pricePerPaxPerNight}
                          isClickableToEdit={enableEditing}
                          editedCell={editedCell}
                          setEditedCell={setEditedCell}
                          callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                            const inputPricePerPaxPerNight = convertToNumberOrNull(dbvalue)
                            let pricePerPaxPerNight = inputPricePerPaxPerNight;
                            const updateObj: Record<string, any> = {}
                            if (pricePerPaxPerNight !== null) {
                              pricePerPaxPerNight = roundUp10(pricePerPaxPerNight);
                              if (row.numOfNights !== null) {
                                if (row.numOfPax !== null) {
                                  // numOfPax & numOfNights & pricePerPaxPerNight --> totalPrice
                                  updateObj[hotelsfield('hotels', row.id, 'totalPrice')] = row.numOfPax * row.numOfNights * pricePerPaxPerNight;
                                } else if (row.totalPrice !== null && Number.isInteger(row.totalPrice / (pricePerPaxPerNight * row.numOfNights))) {
                                  // if pricePerPax present but numOfPax is not but can be calculated
                                  updateObj[hotelsfield('hotels', row.id, 'numOfPax')] = Math.round(row.totalPrice / (pricePerPaxPerNight * row.numOfNights));
                                } else {
                                  // numOfPax is not present, and can't be calculated, so totalPrice must be set to null
                                  updateObj[hotelsfield('hotels', row.id, 'totalPrice')] = null;
                                }
                              } else {
                                // numOfNights is missing, set total price to null
                                updateObj[hotelsfield('hotels', row.id, 'totalPrice')] = null;
                              }
                            } else {
                              // pricePerPax was deleted
                              if (row.numOfPax !== null) {
                                // pricePerPax deleted & numOfPax present --> totalPrice deleted
                                updateObj[hotelsfield('hotels', row.id, 'totalPrice')] = null;
                              } else {
                                // no numOfPax present -> don't touch total (leave it if present) as it's ok to have a total with no numOfPax and no pricePerPax
                              }
                            }

                            const newHotelRooms = updateRoomListPricePerPax({ pricePerPaxPerNight });
                            updateObj[hotelsfield('hotels', row.id, 'pricePerPaxPerNight')] = pricePerPaxPerNight;
                            updateObj[hotelsfield('hotels', row.id, 'hotelRooms')] = getHotelRoomsObject(newHotelRooms);

                            autosaveNewStep(`Change accommodation price per pax per night to ‘${pricePerPaxPerNight}’`, updateObj, 'u')
                            tabNavigation(tabKey, `hotelList_${row.id}_numOfPax`, `hotelList_${row.id}_totalPrice`);
                          }}
                        />
                      </td>
                      <td className={borderTop}>
                        <CheckboxButton
                          checked={row.isBarRate}
                          colorScheme='red'
                          disabled={!enableEditing}
                          onClick={() => {
                            const newVal = !row.isBarRate;
                            const updateObj: Partial<TripQuotationType> = {
                              [hotelsfield('hotels', row.id, 'isBarRate')]: newVal,
                            }
                            autosaveNewStep(`Change accommodation BAR Rate to ‘${newVal}’`, updateObj, 'u')
                            setEditedCell(null)
                          }}>BAR</CheckboxButton>
                      </td>
                      <td className={borderTop}>
                        <EditableField
                          tableid={'hotelList'}
                          rowid={row.id}
                          fieldname={'totalPrice'}
                          validationType={'number'}
                          currentValue={row.totalPrice}
                          isClickableToEdit={enableEditing}
                          editedCell={editedCell}
                          setEditedCell={setEditedCell}
                          callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                            const inputTotalPrice = convertToNumberOrNull(dbvalue);
                            let newTotalPrice = inputTotalPrice; // could be different from inputTotalPrice due to rounding at pricePerPaxPerNight level
                            const updateObj: Record<string, any> = {}
                            let newPricePerPaxPerNight = undefined; // undefined -> no change;  null -> set it to null
                            let newNumOfPax = undefined;
                            if (newTotalPrice !== null) {
                              if (row.numOfNights !== null) {
                                if (row.numOfPax !== null && row.numOfPax > 0) {
                                  // numOfPax & numOfNights & totalPrice --> pricePerPaxPerNight
                                  newPricePerPaxPerNight = roundUp10(newTotalPrice / (row.numOfPax * row.numOfNights));
                                  newTotalPrice = newPricePerPaxPerNight * row.numOfPax * row.numOfNights;
                                } else if (row.pricePerPaxPerNight !== null && Number.isInteger(newTotalPrice / (row.pricePerPaxPerNight * row.numOfNights))) {
                                  // if pricePerPax present but numOfPax is not but can be calculated
                                  newNumOfPax = Math.round(newTotalPrice / (row.pricePerPaxPerNight * row.numOfNights));
                                } else {
                                  // numOfPax is not present, and can't be calculated, so pricePerPax must be set to null
                                  newPricePerPaxPerNight = null;
                                }
                              } else {
                                // num of nights is missing, delete price per pax
                                newPricePerPaxPerNight = null;
                              }
                            } else {
                              // totalPrice was deleted
                              if (row.numOfPax !== null) {
                                // totalPrice deleted & numOfPax present --> newPricePerPaxPerNight deleted
                                newPricePerPaxPerNight = null;
                              } else {
                                // no numOfPax present -> don't touch newPricePerPaxPerNight (leave it if present) as it's ok to have a newPricePerPaxPerNight with no numOfPax and no totalPrice
                              }
                            }

                            updateObj[hotelsfield('hotels', row.id, 'totalPrice')] = newTotalPrice;

                            if (newPricePerPaxPerNight !== undefined) {
                              updateObj[hotelsfield('hotels', row.id, 'pricePerPaxPerNight')] = newPricePerPaxPerNight;
                              const newHotelRooms = updateRoomListPricePerPax({ pricePerPaxPerNight: newPricePerPaxPerNight });
                              updateObj[hotelsfield('hotels', row.id, 'hotelRooms')] = getHotelRoomsObject(newHotelRooms);
                            }

                            if (newNumOfPax !== undefined) {
                              updateObj[hotelsfield('hotels', row.id, 'numOfPax')] = newNumOfPax;
                              const newHotelRooms = updateRoomListNumOfPax(newNumOfPax);
                              updateObj[hotelsfield('hotels', row.id, 'hotelRooms')] = getHotelRoomsObject(newHotelRooms);
                            }

                            autosaveNewStep(`Change accommodation total price to ‘${newTotalPrice}’`, updateObj, 'u')
                            tabNavigation(tabKey, `hotelList_${row.id}_pricePerPax`, `hotelList_${row.id}_bookingRemarks`);
                          }}
                        />
                      </td>
                      <td className={borderTop}>
                        {enableEditing && (
                          <div className='tw-flex tw-justify-end'>
                            <SearchButton onClick={() => {
                              setShowHotelSearchModal(true);
                              setHotelRowToMatch(row);
                              if (row.hotelPriceId) {
                                const hotel = hotelPrices.find(hotel => hotel.id === row.hotelPriceId);
                                setSelectedHotel(hotel);
                                const roomType = hotel?.roomList.find(room => room.roomName === row.roomType);
                                if (roomType) {
                                  setSelectedRoom(roomType);
                                  const occupancy = roomType.occupancyList.find(occ => occ.numOfPaxPerRoom === row.numOfPax);
                                  if (occupancy) setSelectedOccupancy(occupancy);
                                }
                              }
                              setCurrentSeasons(getSeasons(row.checkinDateiso, row.checkoutDateiso) ?? undefined);
                            }} className='tw-mr-1' />
                            <DeleteButton onClick={() => {
                              const updateObj: Partial<TripQuotationRawType> = {
                                [`hotels.${row.id}`]: deleteField(),
                              }
                              autosaveNewStep('Delete accommodation row', updateObj, 'u')
                            }} />

                            <MoveUpDownButtons index={index} array={quotation.hotelList} setArray={(array) => {
                              const updateObj: Partial<TripQuotationRawType> = {
                                hotels: getHotelsObject(array),
                              }
                              autosaveNewStep('Reorder accommodation rows', updateObj, 'u')
                            }} />
                          </div>
                        )}
                      </td>
                      <td className='!tw-bg-transparent'></td>
                      <td className={borderTop}>
                        <select disabled={!enableEditing} className='tw-p-1 tw-rounded'
                          value={row.bookingStatus}
                          style={{ backgroundColor: statusColors[row.bookingStatus] + 'C0' }}
                          onChange={(e) => {
                            const newStatus = e.target.value;
                            console.log(newStatus)
                            const updateObj: Partial<TripQuotationType> = {
                              [hotelsfield('hotels', row.id, 'bookingStatus')]: newStatus,
                            }
                            autosaveNewStep(`Change accommodation booking status to ‘${newStatus}’`, updateObj, 'u')
                            setEditedCell(null)
                          }}>
                          {bookingStatuses.map(status =>
                            <option key={status} value={status}>{status}</option>
                          )}
                        </select>
                      </td>
                      <td className={`tw-min-w-48 ${borderTop}`}>
                        <EditableField
                          tableid={'hotelList'}
                          rowid={row.id}
                          fieldname={'bookingRemarks'}
                          validationType={''}
                          currentValue={row.bookingRemarks}
                          isClickableToEdit={enableEditing}
                          editedCell={editedCell}
                          setEditedCell={setEditedCell}
                          callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                            const updateObj: Partial<TripQuotationType> = {
                              [hotelsfield('hotels', row.id, 'bookingRemarks')]: dbvalue,
                            }
                            autosaveNewStep(`Change hotel booking remarks to ‘${dbvalue}’`, updateObj, 'u')
                            tabNavigation(tabKey, `hotelList_${row.id}_totalPrice`, `hotelList_${row.id}_internalMemo`);
                          }}
                        />
                      </td>
                      <td className='!tw-bg-transparent'></td>
                      <td className={`tw-min-w-48 ${borderTop}`}>
                        {showRowIds && row.id}
                        <EditableField
                          tableid={'hotelList'}
                          rowid={row.id}
                          fieldname={'internalMemo'}
                          validationType={''}
                          currentValue={row.internalMemo}
                          isClickableToEdit={enableEditing}
                          editedCell={editedCell}
                          setEditedCell={setEditedCell}
                          callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                            const updateObj: Partial<TripQuotationType> = {
                              [hotelsfield('hotels', row.id, 'internalMemo')]: dbvalue,
                            }
                            autosaveNewStep(`Change hotel internal memo to ‘${dbvalue}’`, updateObj, 'u')
                            tabNavigation(tabKey, `hotelList_${row.id}_bookingRemarks`, '');
                          }}
                        />
                      </td>
                    </tr>
                    {showExtended && (
                      <tr>
                        <td colSpan={4}></td>
                        <td colSpan={10}>
                          {!nightsFirst ? (
                            [1].map(() => {

                              const roomRows: JSX.Element[][] = [];
                              let grandTotal = 0;
                              let totalNumPax = 0;
                              let overallNumOfNights: number | null = row.hotelRoomList[0].hotelNightList.length;

                              row.hotelRoomList.forEach((room, roomIndex) => {
                                let roomTotal = 0;
                                let roomTotalPerPax = 0;
                                let overallNumOfPax = room.hotelNightList[0]?.numOfPax || null;
                                const tableRowsOneRoom: JSX.Element[] = []
                                room.hotelNightList.forEach((night, nightIndex) => {
                                  roomTotal += night.totalPrice || 0;
                                  roomTotalPerPax += night.pricePerPax || 0;
                                  if (night.numOfPax !== overallNumOfPax)
                                    overallNumOfPax = null;
                                  tableRowsOneRoom.push(
                                    <tr key={night.id}>
                                      {nightIndex === 0 && (
                                        <td rowSpan={room.hotelNightList.length + 1} className='tw-align-top'>
                                          Room {roomIndex + 1}
                                        </td>
                                      )}
                                      <td>Night {nightIndex + 1}: {dateisoFormatJpShort(night.dateiso)}</td>
                                      <td>{formatNum(night.pricePerPax)}</td>
                                      <td>{night.numOfPax}</td>
                                      <td>{formatNum(night.totalPrice)}</td>
                                    </tr>
                                  );
                                });
                                tableRowsOneRoom.push(
                                  <tr key='row_total'>
                                    <td className='tw-bg-blue-100/50 !tw-border-t-2'>Room total for {room.hotelNightList.length} {room.hotelNightList.length === 1 ? 'night' : 'nights'}</td>
                                    <td className='tw-bg-blue-100/50 !tw-border-t-2'>{formatNum(roomTotalPerPax)}</td>
                                    <td className='tw-bg-blue-100/50 !tw-border-t-2'>{overallNumOfPax}</td>
                                    <td className='tw-bg-blue-100/50 !tw-border-t-2'>{formatNum(roomTotal)}</td>
                                  </tr>
                                )
                                roomRows.push(tableRowsOneRoom);
                                grandTotal += roomTotal;
                                totalNumPax += overallNumOfPax || 0;
                                if (overallNumOfNights !== room.hotelNightList.length) {
                                  overallNumOfNights = null;
                                }
                              })


                              return (
                                <table key='1' className='
                      [&>*>tr>*]:tw-border [&>*>tr>*]:tw-border-solid [&>*>tr>*]:tw-border-slate-400
                      [&>*>tr>*]:tw-py-1 [&>*>tr>*]:tw-px-2 tw-my-2'>
                                  <thead>
                                    <tr>
                                      <th className='tw-bg-slate-200'>Room</th>
                                      <th className='tw-bg-slate-200'>Night</th>
                                      <th className='tw-bg-slate-200'>Price per person per night</th>
                                      <th className='tw-bg-slate-200'>Pax per room</th>
                                      <th className='tw-bg-slate-200'>Price per room per night</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {roomRows}
                                    <tr>
                                      <td className='tw-bg-blue-200/80 !tw-border-t-2' colSpan={2}>Grand total for {row.hotelRoomList.length} {row.hotelRoomList.length === 1 ? 'room' : 'rooms'} × {overallNumOfNights || '??'} {overallNumOfNights === 1 ? 'night' : 'nights'}</td>
                                      <td className='tw-bg-blue-200/80 !tw-border-t-2'>{grandTotal && totalNumPax ? formatNum(grandTotal / totalNumPax) : null}</td>
                                      <td className='tw-bg-blue-200/80 !tw-border-t-2'>{totalNumPax}</td>
                                      <td className='tw-bg-blue-200/80 !tw-border-t-2'>{formatNum(grandTotal)}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              );
                            })
                          ) : (
                            [1].map(() => {
                              const dateMap = new Map<string, { room: HotelRoomType, night: HotelNightType }[]>();
                              for (const room of row.hotelRoomList) {
                                for (const night of room.hotelNightList) {
                                  const dateData = dateMap.get(night.dateiso) || [];
                                  dateData.push({ room, night });
                                  dateMap.set(night.dateiso, dateData);
                                }
                              }

                              const dates = Array.from(dateMap.keys()).sort();

                              const nightRows: JSX.Element[][] = [];
                              let grandTotal = 0;
                              let overallNumPax: number | null | undefined = undefined; // undefined=not set yet; null=not all the same
                              let totalPricePerPax = 0;

                              dates.forEach((dateiso, nightIndex) => {
                                let nightTotal = 0;
                                let nightTotalNumOfPax = 0;

                                const rooms = dateMap.get(dateiso)!;
                                const tableRows: JSX.Element[] = []
                                let overallPricePerPax = rooms[0].night.pricePerPax || null;
                                rooms.forEach(({ room, night }, roomIndex) => {

                                  nightTotal += night.totalPrice || 0;
                                  nightTotalNumOfPax += night.numOfPax || 0;
                                  if (night.pricePerPax !== overallPricePerPax)
                                    overallPricePerPax = null;
                                  tableRows.push(
                                    <tr key={night.id}>
                                      {roomIndex === 0 && (
                                        <td rowSpan={rooms.length + 1} className='tw-align-top'>
                                          Night {nightIndex + 1}: {dateisoFormatJpShort(dateiso)}
                                        </td>
                                      )}
                                      <td>Room {roomIndex + 1}</td>
                                      <td>{formatNum(night.pricePerPax)}</td>
                                      <td>{night.numOfPax}</td>
                                      <td>{formatNum(night.totalPrice)}</td>
                                    </tr>
                                  );


                                });
                                tableRows.push(
                                  <tr key='row_total'>
                                    <td className='tw-bg-blue-100/50 !tw-border-t-2'>Night total for {rooms.length} {rooms.length === 1 ? 'room' : 'rooms'}</td>
                                    <td className='tw-bg-blue-100/50 !tw-border-t-2'>{formatNum(overallPricePerPax)}</td>
                                    <td className='tw-bg-blue-100/50 !tw-border-t-2'>{nightTotalNumOfPax}</td>
                                    <td className='tw-bg-blue-100/50 !tw-border-t-2'>{formatNum(nightTotal)}</td>
                                  </tr>
                                )
                                nightRows.push(tableRows);
                                grandTotal += nightTotal;
                                totalPricePerPax += overallPricePerPax || 0;
                                if (overallNumPax === undefined) {
                                  overallNumPax = nightTotalNumOfPax;
                                } else if (overallNumPax !== nightTotalNumOfPax) {
                                  overallNumPax = null;
                                }
                              })

                              return (
                                <table key='1' className='
                                    [&>*>tr>*]:tw-border [&>*>tr>*]:tw-border-solid [&>*>tr>*]:tw-border-slate-400
                                    [&>*>tr>*]:tw-py-1 [&>*>tr>*]:tw-px-2 tw-my-2'>
                                  <thead>
                                    <tr>
                                      <th className='tw-bg-slate-200'>Night</th>
                                      <th className='tw-bg-slate-200'>Room</th>
                                      <th className='tw-bg-slate-200'>Price per person per night</th>
                                      <th className='tw-bg-slate-200'>Pax per room</th>
                                      <th className='tw-bg-slate-200'>Price per room per night</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {nightRows}
                                    <tr>
                                      <td className='tw-bg-blue-200/80 !tw-border-t-2' colSpan={2}>Grand total for {dates.length} {dates.length === 1 ? 'night' : 'nights'} × {row.hotelRoomList.length} {row.hotelRoomList.length === 1 ? 'room' : 'rooms'}</td>
                                      <td className='tw-bg-blue-200/80 !tw-border-t-2'>{formatNum(totalPricePerPax)}</td>
                                      <td className='tw-bg-blue-200/80 !tw-border-t-2'>{overallNumPax}</td>
                                      <td className='tw-bg-blue-200/80 !tw-border-t-2'>{formatNum(grandTotal)}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              );
                            })
                          )}
                          <div>
                            <ButtonTW variant='blue_outline' onClick={() => {
                              setNightsFirst(!nightsFirst)
                            }}>
                              {!nightsFirst ? (
                                <>Rooms <GoArrowBoth /> Nights</>
                              ) : (
                                <>Nights <GoArrowBoth /> Rooms</>
                              )}
                            </ButtonTW>
                          </div>
                        </td>
                        <td className='!tw-bg-transparent'></td>
                        <td colSpan={2}></td>
                        <td className='!tw-bg-transparent'></td>
                        <td></td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
            </tbody>
            <tfoot>
              <tr className='[&>td]:tw-border-t-2'>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>Total</td>
                <td>{formatNum(hotelsTotal)}</td>
                <td>
                  {enableEditing && (
                    <AddButton onClick={() => {
                      const newHotelRow: HotelTripQuotationRowType = {
                        id: nano_id(),
                        csvdata: null,
                        sourceCheckinDateiso: '',
                        sourceCheckoutDateiso: '',
                        sourceMeals: '',
                        sourceMealCode: '',
                        checkinDateiso: '',
                        checkoutDateiso: '',
                        sourceHotelPlace: '',
                        hotelPlace: '',
                        serviceType: 'Accommodation',
                        roomType: '',
                        sourceRoomType: '',
                        supplierNote: '',
                        itemNote: '',
                        passengers: '',
                        bookingStatus: 'RQ',
                        bookingRemarks: '',
                        internalMemo: '',
                        hotelName: '',
                        sourceHotelName: '',
                        breakfastIncluded: false,
                        dinnerIncluded: false,
                        isBarRate: false,
                        numOfNights: null,
                        numOfPax: null,
                        pricePerPaxPerNight: null,
                        totalPrice: null,
                        numOfRooms: null,
                        hotelPriceId: null,
                        hotelRoomList: [],
                      };

                      const hotelRows: HotelTripQuotationRowType[] = [...quotation.hotelList, newHotelRow]
                      const updateObj: Partial<TripQuotationRawType> = {
                        hotels: getHotelsObject(hotelRows),
                      }

                      autosaveNewStep('Add accommodation  row', updateObj, 'u')
                    }} />
                  )}
                </td>
                <td className='!tw-bg-transparent'></td>
                <td></td>
                <td></td>
                <td className='!tw-bg-transparent'></td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className='tw-mt-5'>
          <table className='
           tw-my-2
          [&>*>tr>*]:tw-border-solid
          [&>*>tr>*]:tw-border-slate-200
          [&>*>tr>*]:tw-py-2
          [&>*>tr>*]:tw-px-2
          [&>*>tr>*]:tw-bg-white
          [&>*>tr>td]:tw-align-top
          [&>*>tr>th]:tw-align-bottom
          tw-h-1' // tw-h-1 needed for div height 100% below. see https://stackoverflow.com/a/59030659/
          >
            <thead>
              <tr>
                <th></th>
                <th>Date</th>
                <th>Service type</th>
                <th>Service name</th>
                <th>Details</th>
                <th>Pax</th>
                <th>Price per person</th>
                <th>Total</th>
                <th>Actions</th>
                <th className='!tw-bg-transparent'></th>
                <th>Booking Status</th>
                <th>Booking Remarks</th>
                <th className='!tw-bg-transparent'></th>
                <th>80days internal memo</th>
              </tr>
            </thead>
            <tbody>
              {quotation.serviceList.map((row, index) => {

                let dateSpan: number;
                const sameDateRows: ServiceTripQuotationRowType[] = [];
                if (index > 0 && row.dateiso === quotation.serviceList[index - 1].dateiso) {
                  dateSpan = 0;
                  sameDateRows.push(row);
                } else {
                  dateSpan = 0;
                  for (let i = index; i < quotation.serviceList.length; i++) {
                    if (row.dateiso === quotation.serviceList[i].dateiso) {
                      dateSpan += showCsvSource ? 2 : 1;
                      sameDateRows.push(quotation.serviceList[i]);
                    } else {
                      break;
                    }
                  }
                }

                const borderTop = showCsvSource ? '' : 'tw-border-t-2';
                const csvColumnCount = row.serviceType === 'Rail' ? 12 : 10;


                const discontinuousDates = index > 0 && row.dateiso !== addDaysIso(quotation.serviceList[index - 1].dateiso, 1);

                const blueBubbleCell = (
                  <div className='tw-flex tw-flex-col tw-h-full tw-justify-evenly tw-gap-0.5 tw-relative _tw-top-[-0.75em]'>
                    {row.dateiso && (
                      <div className={
                        `tw-border tw-px-2 tw-border-solid tw-rounded
                                 ${discontinuousDates ? 'tw-border-red-300 tw-bg-red-50' : 'tw-border-blue-300 tw-bg-blue-50'}
                                   tw-grow tw-flex tw-flex-col tw-justify-center tw-text-sm`}>
                        <div className='tw-whitespace-nowrap tw-text-center'>
                          Day {1 + getSpanDaysExactIso(quotation.generalInfo.tripStartDateiso, row.dateiso)}
                        </div>
                        <div className='tw-whitespace-nowrap tw-text-center'>
                          {dateisoFormatDayMonth(row.dateiso)}
                        </div>
                      </div>
                    )}
                  </div>
                )

                return (
                  <React.Fragment key={row.id}>
                    {showCsvSource && (
                      <tr>
                        {dateSpan > 0 && (
                          <td rowSpan={dateSpan} className='tw-border-t-2'>
                            {blueBubbleCell}
                          </td>
                        )}
                        <td colSpan={11} className='tw-border-t-2'>
                          <CsvSourceTable
                            sourceCsvHeaders={quotation.sourceCsvHeaders?.slice(0, csvColumnCount)}
                            csvdatarows={row.csvdata ? [row.csvdata] : []}
                          />
                        </td>
                      </tr>
                    )}
                    <tr>
                      {!showCsvSource && dateSpan > 0 && (
                        <td rowSpan={dateSpan} className={borderTop}>
                          {blueBubbleCell}
                        </td>
                      )}
                      <td className={`tw-min-w-28 ${dateSpan > 0 ? borderTop : ''} ${dateSpan === 0 ? '[&_input]:!tw-text-white' : ''}`}>
                        <EditableFieldDatepicker
                          currentValue_jst0={row.dateiso ? jst0_from_iso(row.dateiso) : null}
                          isClickableToEdit={enableEditing}
                          callbackCommitChange={(date_jst0) => {
                            const dateiso = date_jst0 ? iso_from_jst0(date_jst0) : ''
                            const updateObj: Partial<TripQuotationType> = {
                              [servicesfield('services', row.id, 'dateiso')]: dateiso,
                            }
                            autosaveNewStep(`Change service date to ‘${dateiso}’`, updateObj, 'u')
                            setEditedCell(null)
                          }}
                        />
                      </td>
                      <td className={borderTop}>
                        {!row.csvdata ? ( // editable if row did not come from csv
                          <select disabled={!enableEditing} value={row.serviceType} onChange={(e) => {
                            const newServiceType = e.target.value;
                            const updateObj: Partial<TripQuotationType> = {
                              [servicesfield('services', row.id, 'serviceType')]: newServiceType,
                            }
                            autosaveNewStep(`Change service type to ‘${newServiceType}’`, updateObj, 'u')
                            setEditedCell(null)
                          }}>
                            <option value='Package_Item'>Package_Item</option>
                            <option value='Transfer'>Transfer</option>
                            <option value='Activity'>Activity</option>
                            <option value='Rail'>Rail</option>
                          </select>
                        ) : (
                          row.serviceType.replaceAll('_', ' ')
                        )}
                        <SupplierNoteAlert note={row.supplierNote} />
                        <ItemNoteAlert note={row.itemNote} />
                      </td>
                      <td className={`tw-min-w-48 !tw-p-0 ${borderTop}`}>
                        <SourceDataGrayBox onArrowClick={() => {
                          const updateObj: Partial<TripQuotationType> = {
                            [servicesfield('services', row.id, 'serviceName')]: row.sourceServiceName,
                          }
                          autosaveNewStep(`Change service name to ‘${row.sourceServiceName}’`, updateObj, 'u')
                        }}>
                          {row.sourceServiceName}
                        </SourceDataGrayBox>
                        <div className='tw-p-2'>
                          <EditableField
                            tableid={'serviceList'}
                            rowid={row.id}
                            fieldname={'serviceName'}
                            validationType={''}
                            currentValue={row.serviceName}
                            isClickableToEdit={enableEditing}
                            editedCell={editedCell}
                            setEditedCell={setEditedCell}
                            callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                              const updateObj: Partial<TripQuotationType> = {
                                [servicesfield('services', row.id, 'serviceName')]: dbvalue,
                              }
                              autosaveNewStep(`Change service name to ‘${dbvalue}’`, updateObj, 'u')
                              tabNavigation(tabKey, '', `serviceList_${row.id}_serviceDetails`);
                            }}
                          />
                        </div>
                      </td>
                      <td className={`tw-min-w-48 !tw-p-0 ${borderTop}`}>
                        <SourceDataGrayBox onArrowClick={() => {
                          const updateObj: Partial<TripQuotationType> = {
                            [servicesfield('services', row.id, 'serviceDetails')]: row.sourceServiceDetails,
                          }
                          autosaveNewStep(`Change service details to ‘${row.sourceServiceDetails}’`, updateObj, 'u')
                        }}>
                          {row.sourceServiceDetails}
                        </SourceDataGrayBox>
                        <div className='tw-p-2'>
                          <EditableField
                            tableid={'serviceList'}
                            rowid={row.id}
                            fieldname={'serviceDetails'}
                            validationType={''}
                            currentValue={row.serviceDetails}
                            isClickableToEdit={enableEditing}
                            editedCell={editedCell}
                            setEditedCell={setEditedCell}
                            callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                              const updateObj: Partial<TripQuotationType> = {
                                [servicesfield('services', row.id, 'serviceDetails')]: dbvalue,
                              }
                              autosaveNewStep(`Change service details to ‘${dbvalue}’`, updateObj, 'u')
                              tabNavigation(tabKey, `serviceList_${row.id}_serviceName`, `serviceList_${row.id}_numOfPax`);
                            }}
                          />
                        </div>
                      </td>
                      <td className={`tw-whitespace-nowrap ${borderTop}`}>
                        <EditableField
                          tableid={'serviceList'}
                          rowid={row.id}
                          fieldname={'numOfPax'}
                          validationType={'number'}
                          currentValue={row.numOfPax}
                          isClickableToEdit={enableEditing}
                          editedCell={editedCell}
                          setEditedCell={setEditedCell}
                          callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                            const numOfPax = convertToNumberOrNull(dbvalue) || null // don't allow zero pax, convert zero to null
                            const updateObj: Record<string, number | null> = {
                              [servicesfield('services', row.id, 'numOfPax')]: numOfPax,
                            }
                            if (numOfPax !== null) {
                              if (row.pricePerPax !== null) {
                                // numOfPax & pricePerPax --> totalPrice
                                updateObj[servicesfield('services', row.id, 'totalPrice')] = numOfPax * row.pricePerPax;
                              } else if (row.totalPrice !== null && numOfPax > 0) {
                                // numOfPax & totalPrice --> pricePerPax
                                updateObj[servicesfield('services', row.id, 'pricePerPax')] = Math.round(row.totalPrice / numOfPax);
                              }
                            } else {
                              // numOfPax was deleted
                              if (row.pricePerPax !== null) {
                                // numOfPax deleted & pricePerPax present --> totalPrice deleted
                                updateObj[servicesfield('services', row.id, 'totalPrice')] = null;
                              } else {
                                // no pricePerPax present -> don't touch total (leave it if present) as it's ok to have a total with no numOfPax and no pricePerPax
                              }
                            }
                            autosaveNewStep(`Change service number of pax to ‘${numOfPax}’`, updateObj, 'u')
                            tabNavigation(tabKey, `serviceList_${row.id}_serviceDetails`, `serviceList_${row.id}_pricePerPax`);
                          }}
                          getDisplayValue={(value) => {
                            if (typeof value === 'number') {
                              const list = [];
                              for (let i = 0; i < value; i++) {
                                list.push(<FaPerson key={i} className='tw-text-[1.2em] tw-text-slate-700 tw-mx-[-0.15em]' />);
                              }
                              return (
                                <>
                                  <div>{list}</div>
                                  <div>{value} pax</div>
                                </>
                              );
                            } else {
                              return value;
                            }
                          }}
                        />
                      </td>
                      <td className={`${borderTop}`}>
                        <EditableField
                          tableid={'serviceList'}
                          rowid={row.id}
                          fieldname={'pricePerPax'}
                          validationType={'number'}
                          currentValue={row.pricePerPax}
                          isClickableToEdit={enableEditing}
                          editedCell={editedCell}
                          setEditedCell={setEditedCell}
                          callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                            const pricePerPax = convertToNumberOrNull(dbvalue)
                            const updateObj: Record<string, number | null> = {
                              [servicesfield('services', row.id, 'pricePerPax')]: pricePerPax,
                            }
                            if (pricePerPax !== null) {
                              if (row.numOfPax !== null) {
                                // numOfPax & pricePerPax --> totalPrice
                                updateObj[servicesfield('services', row.id, 'totalPrice')] = row.numOfPax * pricePerPax;
                              } else if (row.totalPrice !== null && Number.isInteger(row.totalPrice / pricePerPax)) {
                                // if pricePerPax present but numOfPax is not but can be calculated
                                updateObj[servicesfield('services', row.id, 'numOfPax')] = Math.round(row.totalPrice / pricePerPax);
                              } else {
                                // numOfPax is not present, and can't be calculated, so totalPrice must be set to null
                                updateObj[servicesfield('services', row.id, 'totalPrice')] = null;
                              }
                            } else {
                              // pricePerPax was deleted
                              if (row.numOfPax !== null) {
                                // pricePerPax deleted & numOfPax present --> totalPrice deleted
                                updateObj[servicesfield('services', row.id, 'totalPrice')] = null;
                              } else {
                                // no numOfPax present -> don't touch total (leave it if present) as it's ok to have a total with no numOfPax and no pricePerPax
                              }
                            }
                            autosaveNewStep(`Change service price per pax to ‘${pricePerPax}’`, updateObj, 'u')
                            tabNavigation(tabKey, `serviceList_${row.id}_numOfPax`, `serviceList_${row.id}_totalPrice`);
                          }}
                        />
                      </td>
                      <td className={`${borderTop}`}>
                        <EditableField
                          tableid={'serviceList'}
                          rowid={row.id}
                          fieldname={'totalPrice'}
                          validationType={'number'}
                          currentValue={row.totalPrice}
                          isClickableToEdit={enableEditing}
                          editedCell={editedCell}
                          setEditedCell={setEditedCell}
                          callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                            const totalPrice = convertToNumberOrNull(dbvalue)
                            const updateObj: Record<string, number | null> = {
                              [servicesfield('services', row.id, 'totalPrice')]: totalPrice,
                            }
                            if (totalPrice !== null) {
                              if (row.numOfPax !== null && row.numOfPax > 0) {
                                // numOfPax & totalPrice --> pricePerPax
                                updateObj[servicesfield('services', row.id, 'pricePerPax')] = Math.round(totalPrice / row.numOfPax);
                              } else if (row.pricePerPax !== null && Number.isInteger(totalPrice / row.pricePerPax)) {
                                // if pricePerPax present but numOfPax is not but can be calculated
                                updateObj[servicesfield('services', row.id, 'numOfPax')] = Math.round(totalPrice / row.pricePerPax);
                              } else {
                                // numOfPax is not present, and can't be calculated, so pricePerPax must be set to null
                                updateObj[servicesfield('services', row.id, 'pricePerPax')] = null;
                              }
                            } else {
                              // totalPrice was deleted
                              if (row.numOfPax !== null) {
                                // totalPrice deleted & numOfPax present --> pricePerPax deleted
                                updateObj[servicesfield('services', row.id, 'pricePerPax')] = null;
                              } else {
                                // no numOfPax present -> don't touch pricePerPax (leave it if present) as it's ok to have a pricePerPax with no numOfPax and no totalPrice
                              }
                            }
                            autosaveNewStep(`Change service total price to ‘${totalPrice}’`, updateObj, 'u')
                            tabNavigation(tabKey, `serviceList_${row.id}_pricePerPax`, `serviceList_${row.id}_bookingRemarks`);
                          }}
                        />
                      </td>
                      <td className={`${borderTop}`}>
                        {enableEditing && (
                          <div className='tw-flex tw-justify-end'>
                            {['Activity', 'Package_Item'].includes(row.serviceType) && <SearchButton onClick={() => {
                              setShowServiceSearchModal(true);
                              setServiceRowToMatch(row);
                              if (row.servicePriceId) {
                                const service = servicePrices.find(service => service.id === row.servicePriceId);
                                setSelectedService(service);
                                if (row.servicePriceItemId) {
                                  const item = service?.priceItems.find(item => item.id === row.servicePriceItemId);
                                  setSelectedServiceItem(item);
                                }
                              }
                            }} />}

                            <MoveUpDownButtons index={index} array={quotation.serviceList} setArray={(array) => {
                              const updateObj: Partial<TripQuotationRawType> = {
                                services: getServicesObject(array),
                              }
                              autosaveNewStep('Reorder service rows', updateObj, 'u')
                            }} />
                            <DeleteButton onClick={() => {
                              const updateObj: Partial<TripQuotationRawType> = {
                                [`services.${row.id}`]: deleteField(),
                              }
                              autosaveNewStep('Delete service row', updateObj, 'u')
                            }} className='tw-ml-1' />
                          </div>
                        )}
                      </td>
                      <td className='!tw-bg-transparent'></td>
                      <td className={`${borderTop}`}>
                        <select disabled={!enableEditing} className='tw-p-1 tw-rounded'
                          value={row.bookingStatus}
                          style={{ backgroundColor: statusColors[row.bookingStatus] + 'C0' }}
                          onChange={(e) => {
                            const newStatus = e.target.value;
                            const updateObj: Partial<TripQuotationType> = {
                              [servicesfield('services', row.id, 'bookingStatus')]: newStatus,
                            }
                            autosaveNewStep(`Change service booking status to ‘${newStatus}’`, updateObj, 'u')
                            setEditedCell(null)
                          }}>
                          {bookingStatuses.map(status =>
                            <option key={status} value={status}>{status}</option>
                          )}
                        </select>
                      </td>
                      <td className={`tw-min-w-48 ${borderTop}`}>
                        <EditableField
                          tableid={'serviceList'}
                          rowid={row.id}
                          fieldname={'bookingRemarks'}
                          validationType={''}
                          currentValue={row.bookingRemarks}
                          isClickableToEdit={enableEditing}
                          editedCell={editedCell}
                          setEditedCell={setEditedCell}
                          callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                            const updateObj: Partial<TripQuotationType> = {
                              [servicesfield('services', row.id, 'bookingRemarks')]: dbvalue,
                            }
                            autosaveNewStep(`Change service booking remarks to ‘${dbvalue}’`, updateObj, 'u')
                            tabNavigation(tabKey, `serviceList_${row.id}_totalPrice`, `serviceList_${row.id}_internalMemo`);
                          }}
                        />
                      </td>
                      <td className='!tw-bg-transparent'></td>
                      <td className={`tw-min-w-48 ${borderTop}`}>
                        {showRowIds && row.id}
                        <EditableField
                          tableid={'serviceList'}
                          rowid={row.id}
                          fieldname={'internalMemo'}
                          validationType={''}
                          currentValue={row.internalMemo}
                          isClickableToEdit={enableEditing}
                          editedCell={editedCell}
                          setEditedCell={setEditedCell}
                          callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                            const updateObj: Partial<TripQuotationType> = {
                              [servicesfield('services', row.id, 'internalMemo')]: dbvalue,
                            }
                            autosaveNewStep(`Change service internal memo to ‘${dbvalue}’`, updateObj, 'u')
                            tabNavigation(tabKey, `serviceList_${row.id}_bookingRemarks`, '');
                          }}
                        />
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
            <tfoot>
              <tr className='[&>td]:tw-border-t-2'>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>Total</td>
                <td>{formatNum(servicesTotal)}</td>
                <td>
                  {enableEditing && (
                    <AddButton onClick={() => {
                      const newRow: ServiceTripQuotationRowType = {
                        id: nano_id(),
                        csvdata: null,
                        dateiso: '',
                        sourceServicePlace: '',
                        serviceType: 'Package_Item',
                        sourceServiceName: '',
                        serviceName: '',
                        sourceServiceDetails: '',
                        serviceDetails: '',
                        supplierNote: '',
                        itemNote: '',
                        pickUpTime: '',
                        dropOffTime: '',
                        passengers: '',
                        bookingStatus: 'RQ',
                        bookingRemarks: '',
                        internalMemo: '',
                        numOfPax: null,
                        pricePerPax: null,
                        totalPrice: null,
                        servicePriceId: null,
                        servicePriceItemId: null,
                      };

                      const rows: ServiceTripQuotationRowType[] = [...quotation.serviceList, newRow]

                      const updateObj: Partial<TripQuotationRawType> = {
                        services: getServicesObject(rows),
                      }

                      autosaveNewStep('Add new service row', updateObj, 'u')
                    }} />
                  )}
                </td>
                <td className='!tw-bg-transparent'></td>
                <td></td>
                <td></td>
                <td className='!tw-bg-transparent'></td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  )
}
