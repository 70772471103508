import { addDoc, collection, doc, onSnapshot, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { CheckboxSwitch } from 'src/components/Buttons/CheckboxSwitch';
import { DeleteButton } from 'src/components/Buttons/DeleteButton';
import { VariantNameType } from 'src/components/Buttons/util_buttontw';
import { EditableField } from 'src/components/EditableField/EditableField';
import { getLoadingSpinnerOrNull } from 'src/components/Spinner/util_getLoadingSpinnerOrNull';
import { UndoRedoButtons } from 'src/hooks/autosave/UndoRedoButtons';
import { useUndoRedo } from 'src/hooks/autosave/useUndoRedo';
import { autosaveDocument } from 'src/hooks/autosave/util_autosave';
import { getNewHistoryInfoObj } from 'src/hooks/autosave/util_undoredo';
import { useAppContext } from 'src/hooks/useAppContext';
import { HotelOccupancyType, HotelPricesType, HotelRoomPricesType } from 'src/types/types_supplierprices';
import { UserSimpleUidType } from 'src/types/types_user';
import { dateFormatJpShortWithTime } from 'src/util/dateformattools';
import { userrole_isDev } from 'src/util/user_roles';
import { convertHotelPriceDates, serverTimestampAsDate } from 'src/util/util_firestoredates';
import { seasonColors } from './util_hotelprices';
import { addMetadataModifiedHotelPricesTable } from './util_serviceprices';


const buttonVariant: VariantNameType = 'blue_outline'

export function PageHotelPrices() {

  const { db, userDetails, setDbError } = useAppContext()

  const [enableEditing, setEnableEditing] = useState(false)
  const [editedCell, setEditedCell] = useState<string | null>(null)
  const [reorderingMode, setReorderingMode] = useState<[string, 'rooms' | 'occupancies' | '']>(['', ''])

  const { addToCache, getUndoRedoHistoryChanges } = useUndoRedo<HotelPricesType>('hotelpriceshistory')


  const [hotelPrices, setHotelPrices] = useState<HotelPricesType[]>()
  useEffect(() => {
    onSnapshot(
      collection(db, 'hotelprices'),
      (snapshot) => {

        const hotelList: HotelPricesType[] = []
        for (const docu of snapshot.docs) {
          const hotelprice = { ...docu.data(), id: docu.id } as HotelPricesType
          convertHotelPriceDates(hotelprice)
          hotelList.push(hotelprice)
          if (hotelprice.history?.currentStepId) {
            addToCache(hotelprice.history.currentStepId, hotelprice)
          }
        }
        hotelList.sort((a, b) => a.facilityIndex - b.facilityIndex)
        setHotelPrices(hotelList)
      },
      (err) => setDbError(`Error getting hotel prices: ${err}`)
    )
  }, [db, setDbError, addToCache])

  const loadingSpinner = getLoadingSpinnerOrNull([
    ['prices', hotelPrices],
  ])
  if (!hotelPrices)
    return loadingSpinner


  const autosaveNewStep = async (
    pricesTable: HotelPricesType,
    userAction: string,
    updateObj: Partial<HotelPricesType>,
    sUndoWall: 'u' | 'UNDOWALL', // u = undoable
  ) => {
    return autosaveAnyStep(pricesTable, userAction, updateObj, false, undefined, sUndoWall)
  }

  const autosaveAnyStep = async (
    pricesTable: HotelPricesType,
    userAction: string,
    updateObj: any,
    isUndoRedo: boolean,
    undoRedoTargetStep: number | undefined,
    sUndoWall: 'u' | 'UNDOWALL', // u = undoable
  ) => {

    if (!enableEditing) {
      setDbError('Field change despite editing being disabled')
      throw new Error('Field change despite editing being disabled')
    }

    const userSimple: UserSimpleUidType = {
      uid: userDetails.id,
      email: userDetails.email,
      name: userDetails.displayNameEn,
    }

    autosaveDocument(
      updateObj,
      userAction,
      isUndoRedo,
      undoRedoTargetStep,
      sUndoWall,
      pricesTable.id,
      pricesTable.history,
      userSimple,
      db,
      'hotelprices',
      (updateObj: Partial<HotelPricesType>) => addMetadataModifiedHotelPricesTable(updateObj, userSimple),
      null,
    )
      .catch((err) => setDbError(`Autosave [autosaveDocument] quotation id=${pricesTable.id} action=[${userAction}]`, err))
  }

  const autosaveUndoRedoStep = async (hotelprice: HotelPricesType, action: 'Undo' | 'Redo', targetStep: number) => {

    const undoRedoData = await getUndoRedoHistoryChanges(action, targetStep, hotelprice.history)
    if (!undoRedoData)
      // failed to retrieve history step from db
      return

    const { updateObjHistory, targetStepObj } = undoRedoData

    // fields that we remove in general:
    //   - id: we never save id as a field
    //   - _isDeleted: should always be false
    //   - history: specifically tweaked in a precise way by updateObjHistory
    //   - dateCreated, userCreated: immutable metadata
    //   - dateModified, userModified: metadata set upon save
    // general expense fields that we remove:
    //   - tbd

    // delete the field we specifically don't want:
    delete targetStepObj.id
    delete targetStepObj._isDeleted
    delete targetStepObj.history
    // @ts-expect-error field parentDocumentId doesn't exist on type GeneralExpenseType
    delete targetStepObj.parentDocumentId

    delete targetStepObj.facilityIndex; // persistent field

    delete targetStepObj.dateCreated;
    delete targetStepObj.userCreated;
    delete targetStepObj.dateModified;
    delete targetStepObj.userModified;

    // add more immutable fields as needed:
    // here we should basically add all fields that are not editable through the CRUD UI

    const updateObj: Partial<HotelPricesType> = {
      ...targetStepObj,
      ...updateObjHistory,
    }

    autosaveAnyStep(
      hotelprice,
      action, // this isn't actually used
      updateObj,
      true,
      targetStep,
      'u', // undo/redo step is always undoable
    )
  }

  const cities: { cityName: string, duplicateCity: boolean, hotels: HotelPricesType[] }[] = []
  let currentCity: string | undefined = undefined
  for (const hotel of hotelPrices) {
    if (hotel.city === currentCity) {
      cities.at(-1)!.hotels.push(hotel)
    } else {
      const duplicateCity = cities.some(city => city.cityName === hotel.city)
      cities.push({ cityName: hotel.city, duplicateCity, hotels: [hotel] })
      currentCity = hotel.city
    }
  }

  return (
    <div className='tw-p-4'>

      <div className='tw-flex tw-gap-4'>
        <div className='__left_column tw-min-w-[14em]'>
          <div className='__hotel_list
           tw-sticky tw-top-[5.2em] tw-h-[calc(100vh-5.5em)] tw-overflow-scroll
            [&>ul]:tw-block [&>ul]:tw-p-0 [&>ul>li]:tw-block
            [&>ul>li>ul]:tw-block [&>ul>li>ul]:tw-p-0 [&>ul>li>ul>li]:tw-block
            '>

            <a className='tw-block tw-un tw-font-bold tw-text-center tw-border-0 tw-border-solid tw-border-slate-400 tw-mb-4 tw-bg-blue-200 tw-no-underline'
              href='#top'>
              Hotel List
            </a>
            <ul>
              {cities.map((city, iCity) => {
                return (
                  <li key={iCity}>
                    <b className={city.duplicateCity ? 'tw-italic' : ''}>{city.cityName || '[no city name]'}</b>
                    <ul>
                      {city.hotels.map(hotel => {
                        return (
                          <li key={hotel.id}>
                            <i className='bi bi-chevron-right'></i> <a href={`#${hotel.id}`} className='tw-no-underline'>{hotel.facilityName || '[no hotel name]'}</a>
                          </li>
                        )
                      })}
                    </ul>
                  </li>
                )
              })}
            </ul>

            <a className='tw-block tw-un tw-font-bold tw-text-center tw-border-0 tw-border-solid tw-border-slate-400 tw-mb-4 tw-bg-blue-200 tw-no-underline'
              href='#reorder-hotels'>
              Reorder Hotels
            </a>

          </div>

        </div>
        <div className='__right_column'>

          <a className='__anchor' id='top' style={{
            display: 'block',
            position: 'relative',
            left: '-14em',
            top: '-5em',
          }}></a>

          <h1 className='tw-text-[1.5em] tw-mt-4'>Hotel Prices</h1>

          <div>
            <CheckboxSwitch
              id='chkEnableEditing'
              label='Edit mode'
              disabled={!userrole_isDev(userDetails.roles)}
              checked={enableEditing}
              onChange={(e) => {
                setEditedCell(null);
                setEnableEditing(e.target.checked);
              }}
            />
          </div>

          <table className='
          [&>*>tr>*]:tw-border [&>*>tr>*]:tw-border-solid [&>*>tr>*]:tw-border-slate-400
          [&>*>tr>*]:tw-py-1 [&>*>tr>*]:tw-px-2'>
            <tbody>
              {hotelPrices.map((hotelprice) => {
                // if (hotelprice.facilityType === 'Activity') {
                //   // todo
                // } else {

                const reorderingModeActive = reorderingMode[0] === hotelprice.id

                const roomRows: JSX.Element[] = []
                if (reorderingModeActive && reorderingMode[1] === 'rooms') {
                  roomRows.push(
                    <tr key='reorder'>
                      <td colSpan={10}>
                        <ReactSortable animation={300} ghostClass='ghostClass' list={hotelprice.roomList.map((row, index) => ({ ...row, id: index }))} setList={(newList) => {
                          if (newList.map(row => row.id).join(',') === [...Array(newList.length).keys()].join(','))
                            // no change
                            return

                          const newRoomList = newList.map(row => {
                            const { id, ...rest } = row
                            return rest
                          })

                          const updateObj: Partial<HotelPricesType> = {
                            roomList: newRoomList,
                          }

                          autosaveNewStep(hotelprice, 'Change room order', updateObj, 'u')
                          setEditedCell(null)

                          // to avoid glitching, temporarily set the new order in the local state too
                          setHotelPrices(hotelPrices.map(hotel => {
                            if (hotel.id === hotelprice.id) {
                              const newHotel: HotelPricesType = { ...hotel, roomList: newRoomList }
                              return newHotel
                            } else {
                              return hotel
                            }
                          }))
                        }} className='tw-flex tw-flex-col tw-gap-1 tw-w-64'>
                          {hotelprice.roomList.map((room, index) => (
                            <div key={index} className='tw-border tw-border-solid tw-border-slate-500 tw-p-2 tw-rounded tw-bg-white tw-cursor-default'>
                              {room.roomName}
                            </div>
                          ))}
                        </ReactSortable>
                      </td>
                    </tr>
                  )
                } else {

                  hotelprice.roomList.forEach((room, iRoom) => {
                    const newPaxPerRoom = room.occupancyList
                      .map(occupancy => occupancy.numOfPaxPerRoom)
                      .reduce((acc, nxt) =>
                        typeof acc === 'number' && typeof nxt === 'number'
                          ? Math.max(acc, nxt + 1)
                          : '', 1)
                    room.occupancyList.forEach((occupancy, iOccupancy) => {
                      roomRows.push(
                        <tr key={`row_${iRoom}_${iOccupancy}`} className='[&>td]:tw-bg-white'>
                          {iOccupancy === 0 && (
                            <>
                              <td rowSpan={room.occupancyList.length}>
                                <EditableField
                                  tableid={hotelprice.id}
                                  rowid={`room_${iRoom}`}
                                  fieldname={'roomName'}
                                  validationType={''}
                                  currentValue={room.roomName || ''}
                                  isClickableToEdit={enableEditing}
                                  editedCell={editedCell}
                                  setEditedCell={setEditedCell}
                                  callbackCommitChange={(dbvalue: any) => {
                                    const updateObj: Partial<HotelPricesType> = {
                                      roomList: hotelprice.roomList.map((room, index) => {
                                        if (index === iRoom) {
                                          const newRoom: HotelRoomPricesType = {
                                            ...room,
                                            roomName: dbvalue,
                                          }
                                          return newRoom
                                        } else {
                                          return room
                                        }
                                      })
                                    }
                                    autosaveNewStep(hotelprice, `Change room name to ‘${dbvalue}’`, updateObj, 'u')
                                    setEditedCell(null)
                                  }}
                                />

                              </td>
                              <td rowSpan={room.occupancyList.length}>
                                {room.roomNameMappings && <span>
                                  {room.roomNameMappings.map(currentMapping =>
                                    <div key={currentMapping}>
                                      <span className='tw-mr-2'>
                                        {currentMapping}
                                      </span>
                                      <DeleteButton onClick={() => {
                                        const newMappings = room.roomNameMappings.filter(mapping => mapping !== currentMapping);
                                        const updateObj: Partial<HotelPricesType> = {
                                          roomList: hotelprice.roomList.map((room, index) => {
                                            if (index === iRoom) {
                                              const newRoom: HotelRoomPricesType = {
                                                ...room,
                                                roomNameMappings: newMappings,
                                              }
                                              return newRoom
                                            } else {
                                              return room
                                            }
                                          })
                                        }
                                        autosaveNewStep(hotelprice, `Remove room mapping ‘${currentMapping}’`, updateObj, 'u')
                                      }} />
                                    </div>
                                  )}
                                </span>}
                              </td>
                              <td rowSpan={room.occupancyList.length}>
                                <EditableField
                                  tableid={hotelprice.id}
                                  rowid={`room_${iRoom}`}
                                  fieldname={'roomSqm'}
                                  validationType={''}
                                  currentValue={room.roomSqm || ''}
                                  isClickableToEdit={enableEditing}
                                  editedCell={editedCell}
                                  setEditedCell={setEditedCell}
                                  callbackCommitChange={(dbvalue: any) => {
                                    const updateObj: Partial<HotelPricesType> = {
                                      roomList: hotelprice.roomList.map((room, index) => {
                                        if (index === iRoom) {
                                          const newRoom: HotelRoomPricesType = {
                                            ...room,
                                            roomSqm: dbvalue,
                                          }
                                          return newRoom
                                        } else {
                                          return room
                                        }
                                      })
                                    }
                                    autosaveNewStep(hotelprice, `Change room sqm to ‘${dbvalue}’`, updateObj, 'u')
                                    setEditedCell(null)
                                  }}
                                />
                              </td>
                            </>
                          )}
                          {(reorderingModeActive && reorderingMode[1] === 'occupancies' && room.occupancyList.length > 1) ? (
                            iOccupancy === 0 && (
                              <td colSpan={7} rowSpan={room.occupancyList.length}>
                                <ReactSortable animation={300} ghostClass='ghostClass' list={room.occupancyList.map((row, index) => ({ ...row, id: index }))} setList={(newList) => {
                                  if (newList.map(row => row.id).join(',') === [...Array(newList.length).keys()].join(','))
                                    // no change
                                    return

                                  const newOccupancyList = newList.map(row => {
                                    const { id, ...rest } = row
                                    return rest
                                  })

                                  const updateObj: Partial<HotelPricesType> = {
                                    roomList: hotelprice.roomList.map((room, index) => {
                                      if (index === iRoom) {
                                        const newRoom: HotelRoomPricesType = {
                                          ...room,
                                          occupancyList: newOccupancyList
                                        }
                                        return newRoom
                                      } else {
                                        return room
                                      }
                                    })
                                  }

                                  autosaveNewStep(hotelprice, 'Change occupancy order', updateObj, 'u')
                                  setEditedCell(null)

                                  // to avoid glitching, temporarily set the new order in the local state too
                                  setHotelPrices(hotelPrices.map(hotel => {
                                    if (hotel.id === hotelprice.id) {
                                      const newHotel: HotelPricesType = {
                                        ...hotel,
                                        roomList: hotel.roomList.map((room, index) => {
                                          if (index === iRoom) {
                                            const newRoom: HotelRoomPricesType = {
                                              ...room,
                                              occupancyList: newOccupancyList
                                            }
                                            return newRoom
                                          } else {
                                            return room
                                          }
                                        }),
                                      }
                                      return newHotel
                                    } else {
                                      return hotel
                                    }
                                  }))
                                }} className='tw-flex tw-flex-col tw-gap-1 tw-w-32'>
                                  {room.occupancyList.map((occupancy, index) => (
                                    <div key={index} className='tw-border tw-border-solid tw-border-slate-500 tw-p-2 tw-rounded tw-bg-white tw-cursor-default'>
                                      {occupancy.numOfPaxPerRoom}
                                    </div>
                                  ))}
                                </ReactSortable>
                              </td>
                            )
                          ) : (
                            <>
                              <td>
                                <EditableField
                                  tableid={hotelprice.id}
                                  rowid={`room_${iRoom}_occupancy_${iOccupancy}`}
                                  fieldname={'numOfPaxPerRoom'}
                                  validationType={''}
                                  currentValue={occupancy.numOfPaxPerRoom}
                                  isClickableToEdit={enableEditing}
                                  editedCell={editedCell}
                                  setEditedCell={setEditedCell}
                                  callbackCommitChange={(dbvalue: any) => {
                                    const newValue = Number(dbvalue) || dbvalue // e.g. 5+ cannot be parsed as number
                                    const updateObj: Partial<HotelPricesType> = {
                                      roomList: hotelprice.roomList.map((room, index) => {
                                        if (index === iRoom) {
                                          const newRoom: HotelRoomPricesType = {
                                            ...room,
                                            occupancyList: room.occupancyList.map((occupancy, index) => {
                                              if (index === iOccupancy) {
                                                const newOccupancy: HotelOccupancyType = {
                                                  ...occupancy,
                                                  numOfPaxPerRoom: newValue,
                                                }
                                                return newOccupancy
                                              } else {
                                                return occupancy
                                              }
                                            })
                                          }
                                          return newRoom
                                        } else {
                                          return room
                                        }
                                      })
                                    }
                                    autosaveNewStep(hotelprice, `Change occupancy pax per room to ‘${newValue}’`, updateObj, 'u')
                                    setEditedCell(null)
                                  }}
                                />
                              </td>
                              {(['lowSeason', 'mediumSeason', 'highSeason', 'specialSeason'] as const).map((season, iSeason) => {
                                return (
                                  <td key={season}>
                                    <EditableField
                                      tableid={hotelprice.id}
                                      rowid={`room_${iRoom}_occupancy_${iOccupancy}`}
                                      fieldname={season}
                                      validationType={'number'}
                                      currentValue={occupancy[season]}
                                      isClickableToEdit={enableEditing}
                                      editedCell={editedCell}
                                      setEditedCell={setEditedCell}
                                      callbackCommitChange={(dbvalue: any) => {
                                        const newValue = dbvalue === null || dbvalue === undefined || dbvalue === '' ? null : Number(dbvalue)
                                        if (typeof newValue === 'number' && isNaN(newValue))
                                          return;
                                        const updateObj: Partial<HotelPricesType> = {
                                          roomList: hotelprice.roomList.map((room, index) => {
                                            if (index === iRoom) {
                                              const newRoom: HotelRoomPricesType = {
                                                ...room,
                                                occupancyList: room.occupancyList.map((occupancy, index) => {
                                                  if (index === iOccupancy) {
                                                    const newOccupancy: HotelOccupancyType = {
                                                      ...occupancy,
                                                      [season]: newValue,
                                                    }
                                                    return newOccupancy
                                                  } else {
                                                    return occupancy
                                                  }
                                                })
                                              }
                                              return newRoom
                                            } else {
                                              return room
                                            }
                                          })
                                        }
                                        autosaveNewStep(hotelprice, `Change ${season} price to ‘${newValue}’`, updateObj, 'u')
                                        setEditedCell(null)
                                      }}
                                    />
                                  </td>
                                )
                              })}
                              <td>
                                <EditableField
                                  tableid={hotelprice.id}
                                  rowid={`room_${iRoom}_occupancy_${iOccupancy}`}
                                  fieldname={'notes'}
                                  validationType={''}
                                  currentValue={occupancy.notes}
                                  isClickableToEdit={enableEditing}
                                  editedCell={editedCell}
                                  setEditedCell={setEditedCell}
                                  callbackCommitChange={(dbvalue: any) => {
                                    const updateObj: Partial<HotelPricesType> = {
                                      roomList: hotelprice.roomList.map((room, index) => {
                                        if (index === iRoom) {
                                          const newRoom: HotelRoomPricesType = {
                                            ...room,
                                            occupancyList: room.occupancyList.map((occupancy, index) => {
                                              if (index === iOccupancy) {
                                                const newOccupancy: HotelOccupancyType = {
                                                  ...occupancy,
                                                  notes: dbvalue,
                                                }
                                                return newOccupancy
                                              } else {
                                                return occupancy
                                              }
                                            })
                                          }
                                          return newRoom
                                        } else {
                                          return room
                                        }
                                      })
                                    }
                                    autosaveNewStep(hotelprice, `Change occupancy notes to ‘${dbvalue}’`, updateObj, 'u')
                                    setEditedCell(null)
                                  }}
                                />
                              </td>
                              <td>
                                {enableEditing && (
                                  <div className='tw-flex tw-gap-1'>
                                    {room.occupancyList.length > 1 ? (
                                      <ButtonTW variant={buttonVariant} className='tw-whitespace-nowrap' onClick={() => {
                                        const updateObj: Partial<HotelPricesType> = {
                                          roomList: hotelprice.roomList.map((room, index) => {
                                            if (index === iRoom) {
                                              const newRoom: HotelRoomPricesType = {
                                                ...room,
                                                occupancyList: room.occupancyList.filter((_, index) => index !== iOccupancy)
                                              }
                                              return newRoom
                                            } else {
                                              return room
                                            }
                                          })
                                        }
                                        autosaveNewStep(hotelprice, 'Delete occupancy', updateObj, 'u')
                                      }}>Delete occupancy</ButtonTW>
                                    ) : (
                                      <ButtonTW variant={buttonVariant} className='tw-whitespace-nowrap' onClick={() => {
                                        const updateObj: Partial<HotelPricesType> = {
                                          roomList: hotelprice.roomList.filter((_, index) => index !== iRoom)
                                        }
                                        autosaveNewStep(hotelprice, 'Delete room type', updateObj, 'u')
                                      }}>Delete room type</ButtonTW>
                                    )}
                                    {iOccupancy === room.occupancyList.length - 1 && (
                                      <ButtonTW variant={buttonVariant} className='tw-whitespace-nowrap' onClick={() => {
                                        const newOccupancy: HotelOccupancyType = {
                                          numOfPaxPerRoom: newPaxPerRoom,
                                          lowSeason: null,
                                          mediumSeason: null,
                                          highSeason: null,
                                          specialSeason: null,
                                          notes: '',
                                        }
                                        const updateObj: Partial<HotelPricesType> = {
                                          roomList: hotelprice.roomList.map((room, index) => {
                                            if (index === iRoom) {
                                              const newRoom: HotelRoomPricesType = {
                                                ...room,
                                                occupancyList: [
                                                  ...room.occupancyList.slice(0, iOccupancy + 1),
                                                  newOccupancy,
                                                  ...room.occupancyList.slice(iOccupancy + 1),
                                                ]
                                              }
                                              return newRoom
                                            } else {
                                              return room
                                            }
                                          })
                                        }
                                        autosaveNewStep(hotelprice, 'Add new occupancy', updateObj, 'u')
                                      }}>Add occupancy</ButtonTW>
                                    )}
                                  </div>
                                )}
                              </td>
                            </>
                          )}
                        </tr>
                      );
                    })
                  })
                }

                return (
                  <React.Fragment key={hotelprice.id}>
                    <tr>
                      <td rowSpan={2 + roomRows.length + 1}
                        className='tw-align-top tw-font-bold tw-bg-slate-300'>
                        <a className='__anchor' id={hotelprice.id} style={{
                          display: 'block',
                          position: 'relative',
                          left: '-14em',
                          top: '-5em',
                        }}></a>
                        <EditableField
                          tableid={hotelprice.id}
                          rowid={'hotelInfo'}
                          fieldname={'city'}
                          validationType={''}
                          currentValue={hotelprice.city}
                          isClickableToEdit={enableEditing}
                          editedCell={editedCell}
                          setEditedCell={setEditedCell}
                          callbackCommitChange={(dbvalue: any) => {
                            const updateObj: Partial<HotelPricesType> = {
                              city: dbvalue,
                            }
                            autosaveNewStep(hotelprice, `Change hotel city to ‘${dbvalue}’`, updateObj, 'u')
                            setEditedCell(null)
                          }}
                          hasButtonForEditing={true}
                        />
                      </td>
                      <td colSpan={10} className='tw-bg-slate-100'>
                        <div className='tw-flex tw-justify-between'>

                          <table className='[&>tbody>tr>td:first-child]:tw-pe-16'>
                            <tbody>
                              <tr className='tw-font-bold'>
                                <td>Hotel name:</td>
                                <td>
                                  <EditableField
                                    tableid={hotelprice.id}
                                    rowid={'hotelInfo'}
                                    fieldname={'facilityName'}
                                    validationType={''}
                                    currentValue={hotelprice.facilityName}
                                    isClickableToEdit={enableEditing}
                                    editedCell={editedCell}
                                    setEditedCell={setEditedCell}
                                    callbackCommitChange={(dbvalue: any) => {
                                      const updateObj: Partial<HotelPricesType> = {
                                        facilityName: dbvalue,
                                      }
                                      autosaveNewStep(hotelprice, `Change hotel name to ‘${dbvalue}’`, updateObj, 'u')
                                      setEditedCell(null)
                                    }}
                                    hasButtonForEditing={true}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Hotel notes:</td>
                                <td>
                                  <EditableField
                                    tableid={hotelprice.id}
                                    rowid={'hotelInfo'}
                                    fieldname={'notes'}
                                    validationType={''}
                                    currentValue={hotelprice.notes}
                                    isClickableToEdit={enableEditing}
                                    editedCell={editedCell}
                                    setEditedCell={setEditedCell}
                                    callbackCommitChange={(dbvalue: any) => {
                                      const updateObj: Partial<HotelPricesType> = {
                                        notes: dbvalue,
                                      }
                                      autosaveNewStep(hotelprice, `Change notes to ‘${dbvalue}’`, updateObj, 'u')
                                      setEditedCell(null)
                                    }}
                                    hasButtonForEditing={true}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Meals included:</td>
                                <td>
                                  <EditableField
                                    tableid={hotelprice.id}
                                    rowid={'hotelInfo'}
                                    fieldname={'mealsIncluded'}
                                    validationType={''}
                                    currentValue={hotelprice.mealsIncluded}
                                    isClickableToEdit={enableEditing}
                                    editedCell={editedCell}
                                    setEditedCell={setEditedCell}
                                    callbackCommitChange={(dbvalue: any) => {
                                      const updateObj: Partial<HotelPricesType> = {
                                        mealsIncluded: dbvalue,
                                      }
                                      autosaveNewStep(hotelprice, `Change meals included to ‘${dbvalue}’`, updateObj, 'u')
                                      setEditedCell(null)
                                    }}
                                    hasButtonForEditing={true}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Notes on meals:</td>
                                <td>
                                  <EditableField
                                    tableid={hotelprice.id}
                                    rowid={'hotelInfo'}
                                    fieldname={'notesMeal'}
                                    validationType={''}
                                    currentValue={hotelprice.notesMeal}
                                    isClickableToEdit={enableEditing}
                                    editedCell={editedCell}
                                    setEditedCell={setEditedCell}
                                    callbackCommitChange={(dbvalue: any) => {
                                      const updateObj: Partial<HotelPricesType> = {
                                        notesMeal: dbvalue,
                                      }
                                      autosaveNewStep(hotelprice, `Change meals notes to ‘${dbvalue}’`, updateObj, 'u')
                                      setEditedCell(null)
                                    }}
                                    hasButtonForEditing={true}
                                  />
                                </td>
                              </tr>
                              {hotelprice.hotelNameMappings && <tr>
                                <td>Mappings</td>
                                <td>
                                  {hotelprice.hotelNameMappings.map(currentMapping =>
                                    <div key={currentMapping}>
                                      <span className='tw-mr-2'>
                                        {currentMapping}
                                      </span>
                                      <DeleteButton onClick={() => {
                                        const newMappings = hotelprice.hotelNameMappings.filter(mapping => mapping !== currentMapping);
                                        const updateObj: Partial<HotelPricesType> = {
                                          hotelNameMappings: newMappings,
                                        }
                                        autosaveNewStep(hotelprice, `Remove hotel mapping ‘${currentMapping}’`, updateObj, 'u')
                                      }} />
                                    </div>
                                  )}
                                </td>
                              </tr>}
                            </tbody>
                          </table>

                          <div className='tw-flex tw-p-2 tw-gap-2'>
                            <UndoRedoButtons
                              whiteBarActive={true}
                              enableEditing={enableEditing}
                              autosaveUndoRedoStep={(action: 'Undo' | 'Redo', targetStep: number) => autosaveUndoRedoStep(hotelprice, action, targetStep)}
                              history={hotelprice.history}
                            />
                          </div>
                        </div>

                      </td>
                    </tr>
                    <tr>
                      <th>Room type</th>
                      <th>Mappings</th>
                      <th>Sqm per room</th>
                      <th>Pax per room</th>
                      <th className={seasonColors.lo}>Low season</th>
                      <th className={seasonColors.md}>Medium season</th>
                      <th className={seasonColors.hi}>High season</th>
                      <th className={seasonColors.sp}>Special season</th>
                      <th>Notes</th>
                      <th>Actions</th>
                    </tr>
                    {roomRows}
                    <tr>
                      <td colSpan={10}>
                        {enableEditing ? (
                          <div className='tw-flex tw-gap-1'>
                            {!reorderingModeActive ? (
                              <>
                                <ButtonTW variant={buttonVariant} onClick={() => {
                                  const newOccupancy: HotelOccupancyType = {
                                    numOfPaxPerRoom: 1,
                                    lowSeason: null,
                                    mediumSeason: null,
                                    highSeason: null,
                                    specialSeason: null,
                                    notes: '',
                                  }
                                  const newRoomType: HotelRoomPricesType = {
                                    roomName: '',
                                    roomSqm: '',
                                    occupancyList: [newOccupancy],
                                    roomNameMappings: [],
                                  }
                                  const updateObj: Partial<HotelPricesType> = {
                                    roomList: [
                                      ...hotelprice.roomList,
                                      newRoomType,
                                    ]
                                  }
                                  autosaveNewStep(hotelprice, 'Add new room type', updateObj, 'u')
                                }}>Add room type</ButtonTW>
                                <ButtonTW variant={buttonVariant} onClick={() => {
                                  setReorderingMode([hotelprice.id, 'rooms'])
                                }}>Reorder rooms</ButtonTW>
                                <ButtonTW variant={buttonVariant} onClick={() => {
                                  setReorderingMode([hotelprice.id, 'occupancies'])
                                }}>Reorder occupancies</ButtonTW>
                              </>
                            ) : (
                              <ButtonTW variant={buttonVariant} onClick={() => {
                                setReorderingMode(['', ''])
                              }}>Reordering done</ButtonTW>
                            )}
                          </div>
                        ) : '\u00a0'}
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={10} className='!tw-border-l-0 !tw-border-r-0'></td>
                    </tr>

                  </React.Fragment>
                )

              })}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={11}>
                  <ButtonTW variant={buttonVariant} onClick={() => {
                    const userSimple: UserSimpleUidType = {
                      uid: userDetails.id,
                      email: userDetails.email,
                      name: userDetails.displayNameEn,
                    }

                    const newOccupancy: HotelOccupancyType = {
                      numOfPaxPerRoom: 1,
                      lowSeason: null,
                      mediumSeason: null,
                      highSeason: null,
                      specialSeason: null,
                      notes: '',
                    }
                    const newRoom: HotelRoomPricesType = {
                      roomName: '',
                      roomSqm: '',
                      occupancyList: [newOccupancy],
                      roomNameMappings: [],
                    }
                    const newHotel: Omit<HotelPricesType, 'id'> = {
                      _isDeleted: false,
                      history: getNewHistoryInfoObj(userSimple, 'Newly created'),
                      facilityKey: '',
                      city: '',
                      facilityName: '',
                      facilityType: 'Accommodation',
                      facilityIndex: hotelPrices.length,
                      notes: '',
                      mealsIncluded: '',
                      notesMeal: '',
                      roomList: [newRoom],
                      dateCreated: serverTimestampAsDate(),
                      dateModified: serverTimestampAsDate(),
                      userCreated: userSimple,
                      userModified: userSimple,
                      hotelNameMappings: [],
                    }
                    addDoc(collection(db, 'hotelprices'), newHotel)
                  }}>Add Hotel</ButtonTW>
                </td>
              </tr>
            </tfoot>
          </table>


          <a className='__anchor' id='reorder-hotels' style={{
            display: 'block',
            position: 'relative',
            left: '-14em',
            top: '-5em',
          }}></a>
          <h1 className='tw-text-[1.5em] tw-mt-8'>Hotel List</h1>

          <div>
            {reorderingMode[0] === 'hotelList' ? (
              <>
                <ReactSortable animation={300} ghostClass='ghostClass' list={hotelPrices} setList={(newList) => {
                  if (newList.map(row => row.facilityIndex).join(',') === [...Array(newList.length).keys()].join(','))
                    // no change
                    return

                  newList.forEach((hotel, index) => {
                    if (hotel.facilityIndex !== index) {
                      const updateObj: Partial<HotelPricesType> = {
                        facilityIndex: index,
                      }
                      updateDoc(doc(db, 'hotelprices', hotel.id), updateObj)
                      console.log(`Change index of ${hotel.facilityName} from ${hotel.facilityIndex} to ${index}`)
                    }
                  })

                  setEditedCell(null)

                  // to avoid glitching, temporarily set the new order in the local state too
                  setHotelPrices(newList)
                }} className='tw-flex tw-flex-col tw-gap-1 tw-w-[30em]'>
                  {hotelPrices.map((hotel, index) => (
                    <div key={hotel.id} className='tw-border tw-border-solid tw-border-slate-500 tw-p-2 tw-rounded tw-bg-white tw-cursor-default'>
                      <b>{hotel.city}</b>{' '}
                      {hotel.facilityName}
                    </div>
                  ))}
                </ReactSortable>
                <ButtonTW variant={buttonVariant} onClick={() => setReorderingMode(['', ''])}>Done reordering</ButtonTW>
              </>
            ) : (
              <>
                <table className='
                  [&>*>tr>*]:tw-border [&>*>tr>*]:tw-border-solid [&>*>tr>*]:tw-border-slate-400
                  [&>*>tr>*]:tw-py-1 [&>*>tr>*]:tw-px-2'>
                  <thead>
                    <tr className='[&>th]:tw-bg-slate-400'>
                      <th>City</th>
                      <th>Hotel name</th>
                      <th>Last modified</th>
                      <th>User</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cities.map((city) => {
                      return city.hotels.map((hotel, index) => (
                        <tr key={hotel.id}>
                          {index === 0 && (
                            <td rowSpan={city.hotels.length} className='tw-bg-slate-300'>
                              <b>{city.cityName}</b>
                            </td>
                          )}
                          <td className='tw-bg-white'>
                            {hotel.facilityName}
                          </td>
                          <td className='tw-bg-slate-200'>
                            {dateFormatJpShortWithTime(hotel.dateModified)}
                          </td>
                          <td className='tw-bg-slate-200'>
                            {hotel.userModified.name}
                          </td>
                        </tr>
                      ))
                    })}
                  </tbody>
                </table>
                <ButtonTW variant={buttonVariant} onClick={() => setReorderingMode(['hotelList', ''])}>Reorder hotels</ButtonTW>
              </>
            )}

          </div>

        </div>
      </div>

    </div>
  )
}
