import { RiArrowDownDoubleFill } from 'react-icons/ri';


interface SourceDataGrayBoxProps {
  children: React.ReactNode;
  onArrowClick?: () => void;
}

export function SourceDataGrayBox({
  children,
  onArrowClick,
}: SourceDataGrayBoxProps) {

  if (!children)
    return null;

  return (
    <div className='
      tw-text-sm tw-text-slate-800 tw-bg-slate-200
      tw-border-r-0.25 tw-border-b-0.25 tw-border-l-0 tw-border-t-0
      tw-border-solid tw-border-slate-400
      tw-p-0.5 tw-relative
    '>
      {children}
      {onArrowClick && (
        <div className='tw-absolute tw-right-1 tw-bottom-0 tw-text-lg tw-text-slate-600 tw-cursor-pointer' onClick={onArrowClick}>
          <RiArrowDownDoubleFill />
        </div>
      )}
    </div>
  )

}
