import { addDoc, collection, onSnapshot, query, QuerySnapshot } from 'firebase/firestore';
import Papa from 'papaparse';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { CheckboxSwitch } from 'src/components/Buttons/CheckboxSwitch';
import { getLoadingSpinnerOrNull } from 'src/components/Spinner/util_getLoadingSpinnerOrNull';
import { getNewHistoryInfoObj } from 'src/hooks/autosave/util_undoredo';
import { useAppContext } from 'src/hooks/useAppContext';
import { HotelPricesType, ServicePriceCatalogType } from 'src/types/types_supplierprices';
import { coll_tripquotations, TripQuotationRawType, TripQuotationType } from 'src/types/types_tripquotations';
import { UserSimpleUidType } from 'src/types/types_user';
import { dateFormatJpWithTime } from 'src/util/dateformattools';
import { convertHotelPriceDates, convertQuotationDates, convertServicePriceDates, serverTimestampAsDate } from 'src/util/util_firestoredates';
import { parsePassengers, parseQuotationRequest } from './tripQuotationParsingUtils';

export function PageQuotationList() {

  const navigate = useNavigate()
  const fileInput = useRef<HTMLInputElement>(null);
  const [quotations, setQuotations] = useState<TripQuotationType[]>([]);
  const [showIdColumn, setShowIdColumn] = useState(false);
  const [files, setFiles] = useState<{ services: File, paxlist: File } | null>(null);
  const [uploadError, setUploadError] = useState('');

  const { db, setDbError, userDetails } = useAppContext()

  useEffect(() => {
    const processSnapshot = function (snapshot: QuerySnapshot) {
      console.log(`Retrieved ${snapshot.docs.length} docs from firestore collection 'tripquotations'`)

      const list: TripQuotationType[] = []
      for (const docu of snapshot.docs) {
        const quotation = { ...docu.data(), id: docu.id } as TripQuotationType
        convertQuotationDates(quotation);
        list.push(quotation)
      }
      const now = new Date().getTime()
      list.sort((a, b) => ((a.dateCreated?.getTime() || now) - (b.dateCreated?.getTime() || now)) * -1)
      setQuotations(list)
    }
    const q = query(collection(db, coll_tripquotations))
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting trip quotation list', err));

    return unsubscribe
  }, [db, setDbError])

  const [hotelPrices, setHotelPrices] = useState<HotelPricesType[]>()
  useEffect(() => {
    onSnapshot(
      collection(db, 'hotelprices'),
      (snapshot) => {

        const hotelList: HotelPricesType[] = []
        for (const docu of snapshot.docs) {
          const hotelprice = { ...docu.data(), id: docu.id } as HotelPricesType
          convertHotelPriceDates(hotelprice)
          hotelList.push(hotelprice)
        }
        hotelList.sort((a, b) => a.facilityIndex - b.facilityIndex)
        setHotelPrices(hotelList)
      },
      (err) => setDbError(`Error getting hotel prices: ${err}`)
    )
  }, [db, setDbError])

  const [servicePrices, setServicePrices] = useState<ServicePriceCatalogType[]>()
  useEffect(() => {
    onSnapshot(
      collection(db, 'serviceprices'),
      (snapshot) => {
        const serviceList: ServicePriceCatalogType[] = []
        for (const docu of snapshot.docs) {
          const serviceprice = { ...docu.data(), id: docu.id } as ServicePriceCatalogType
          convertServicePriceDates(serviceprice)
          serviceList.push(serviceprice)
        }
        setServicePrices(serviceList)
      },
      (err) => setDbError(`Error getting service prices: ${err}`)
    )
  }, [db, setDbError])

  const loadingSpinner = getLoadingSpinnerOrNull([
    ['quotations', quotations],
    ['hotelPrices', hotelPrices],
    ['servicePrices', servicePrices],
  ])
  if (!quotations || !hotelPrices || !servicePrices)
    return loadingSpinner

  const hasParseErrors = (parseResult: Papa.ParseResult<string[]>) => {
    if (parseResult.errors.length > 0) {
      let errorMessage = ''
      parseResult.errors.forEach((err) => {
        errorMessage += err + '\n'
      })
      console.error(errorMessage);
      return true;
    }
    return false;
  }

  const handleSubmit = () => {
    if (!files) return;
    const reader = new FileReader();
    reader.onerror = (e) => console.error('error reading passengers file', e)
    reader.onload = function (e) {
      const passengerCsvContent = e.target?.result as string;
      Papa.parse(passengerCsvContent, {
        header: false,
        complete: async (parseResultPaxList: Papa.ParseResult<string[]>) => {
          if (hasParseErrors(parseResultPaxList)) return;
          const passengers = parsePassengers(parseResultPaxList.data);

          const reader = new FileReader();
          reader.onerror = (e) => console.error('error reading services file', e)
          reader.onload = function (e) {

            const quotationCsvContent = e.target?.result as string;
            Papa.parse(quotationCsvContent, {
              header: false,
              complete: async (parseResult: Papa.ParseResult<string[]>) => {

                if (hasParseErrors(parseResult)) return;
                const passengerCount = passengers ? Object.entries(passengers).length : 0;
                const { generalInfo, services, hotels, csvHeaders } = parseQuotationRequest(parseResult.data, passengerCount, hotelPrices, servicePrices);

                const userSimple: UserSimpleUidType = {
                  uid: userDetails.id,
                  email: userDetails.email,
                  name: userDetails.displayNameEn,
                }

                const newTripQuotation: Omit<TripQuotationRawType, 'id'> = {
                  _isDeleted: false,
                  history: getNewHistoryInfoObj(userSimple, 'Newly created trip quotation'),
                  sourceCsvName: files.services.name,
                  sourceCsv: quotationCsvContent,
                  sourceCsvHeaders: csvHeaders,
                  generalInfo,
                  services,
                  hotels,
                  usersDesigners: [userSimple],
                  usersDesignersUids: [userSimple.uid],
                  dateCreated: serverTimestampAsDate(),
                  dateModified: serverTimestampAsDate(),
                  requestCode: '',
                  requestId: '',
                  userCreated: userSimple,
                  userModified: userSimple,
                  passengers,
                  passengerCsvData: parseResultPaxList.data.map(row => ({ cells: row })),
                }

                const result = await addDoc(collection(db, coll_tripquotations), newTripQuotation);
                navigate(`/quotations/edit/${result.id}`);
              }
            })
          };
          reader.readAsText(files.services);
        }
      })
    };
    reader.readAsText(files.paxlist);
  }

  return (
    <div>
      <div>
        <div className='tw-p-10'>
          <div>
            <div className='tw-mb-4'>
              <h5>Upload a quotation request</h5>
              <div>Select two CSV files: Services and Passenger List.</div>
            </div>
            <table className='[&>*>tr>*]:tw-border-solid [&>*>tr>*]:tw-border-slate-400 [&>*>tr>*]:tw-border [&>*>tr>*]:tw-p-2 tw-mb-4'>
              <tbody>
                <tr>
                  <th>Services CSV file</th>
                  <td className='tw-min-w-64'>{files?.services?.name}</td>
                </tr>
                <tr>
                  <th>Pax list CSV file</th>
                  <td>{files?.paxlist?.name}</td>
                </tr>
              </tbody>
            </table>
            {!files ? (
              <div>
                {uploadError && (
                  <div className='tw-mb-2'>
                    <div className='tw-text-red-600 tw-border tw-border-solid tw-border-red-600 tw-bg-red-100 tw-rounded tw-p-2 tw-inline-block'>
                      {uploadError}
                      <i className='bi bi-x-circle tw-ml-4 tw-cursor-pointer' onClick={() => {
                        setUploadError('');
                        fileInput.current!.value = '';// clear the file input so that onChange gets triggered again even if user selects the same set of files again
                      }} />
                    </div>
                  </div>
                )}
                <ButtonTW onClick={() => {
                  fileInput.current?.click();
                }}>Choose Files</ButtonTW>
                <div className='tw-hidden'>
                  <input type='file' ref={fileInput} accept='.csv' multiple className='tw-mr-2'
                    onChange={(e) => {

                      const elemInputFile = e.target

                      const filelist = elemInputFile.files // elemInputFile.value is just the file path and name, without the actual file data

                      if (!filelist) return;
                      if (filelist.length < 2) {
                        setUploadError(`Select 2 files at the same time: Services CSV and Passengers CSV (${filelist.length} files selected)`);
                        return;
                      }
                      if (filelist.length > 2) {
                        setUploadError(`Too many files (${filelist.length} files selected). Select 2 files.`);
                        return;
                      }

                      const files = Array.from(filelist);

                      const servicesfiles = files.filter(f => f.name.toLowerCase().includes('services'));
                      const paxlistfiles = files.filter(f => f.name.toLowerCase().includes('passenger list'));

                      if (servicesfiles.length === 0) {
                        setUploadError('Services file not found');
                        return;
                      }
                      if (paxlistfiles.length === 0) {
                        setUploadError('Passengers file not found');
                        return;
                      }
                      if (servicesfiles.length > 1) {
                        setUploadError('Multiple services files found');
                        return;
                      }
                      if (paxlistfiles.length > 1) {
                        setUploadError('Multiple passengers files found');
                        return;
                      }

                      setFiles({ services: servicesfiles[0], paxlist: paxlistfiles[0] });
                      setUploadError('');
                    }} />
                </div>
              </div>
            ) : (
              <div>
                <div className='tw-mt-2 tw-flex tw-gap-2'>
                  <ButtonTW onClick={() => {
                    handleSubmit();
                  }}>Create Quotation</ButtonTW>
                  <ButtonTW variant='blue_outline' onClick={() => {
                    setFiles(null);
                    setUploadError('');
                  }}>Cancel</ButtonTW>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <div className='tw-m-10'>
          <h5>List of quotations</h5>
          <p>
            This is for testing purposes.
          </p>
          <table className='[&>*>tr>*]:tw-border-solid [&>*>tr>*]:tw-border-slate-400 [&>*>tr>*]:tw-border [&>*>tr>*]:tw-p-1'>
            <thead>
              <tr className='[&>*]:tw-bg-slate-300'>
                <th>Actions</th>
                {showIdColumn && <th>ID</th>}
                <th>Source CSV</th>
                <th>Pax name</th>
                <th>Client agency data</th>
                <th>Travel designer</th>
                <th>Trip Dates</th>
                <th>Modified</th>
              </tr>
            </thead>
            <tbody className='[&>tr>*]:tw-bg-white [&>tr>td.idcol]:tw-bg-blue-100'>
              {quotations.map(quotation => {
                return (
                  <tr key={quotation.id}>
                    <td className='tw-text-center'>
                      <ButtonTW variant='blue_outline' to={`/quotations/edit/${quotation.id}`}>Edit</ButtonTW>
                    </td>
                    {showIdColumn && (
                      <td className='idcol'>{quotation.id}</td>
                    )}
                    <td>
                      {quotation.sourceCsvName}
                    </td>
                    <td>
                      {quotation.generalInfo.paxName}
                    </td>
                    <td>
                      <div>{quotation.generalInfo.agencyBusinessUnit}</div>
                      <div>{quotation.generalInfo.agencyOwner}</div>
                      <div>{quotation.generalInfo.agencyPrimaryContact}</div>
                      <div>{quotation.generalInfo.agencyItineraryRefWithVersion}</div>
                    </td>
                    <td>
                      {quotation.usersDesigners.map(user => user.name).join(', ')}
                    </td>
                    <td>
                      <div>{quotation.generalInfo.tripStartDateiso}</div>
                      {(quotation.generalInfo.tripStartDateiso || quotation.generalInfo.tripEndDateiso) && <div>→</div>}
                      <div>{quotation.generalInfo.tripEndDateiso}</div>
                    </td>
                    <td>
                      <div>{dateFormatJpWithTime(quotation.dateModified)}</div>
                      <div>
                        {quotation.userCreated.name}
                        {quotation.userCreated.uid !== quotation.userModified.uid && ` (${quotation.userModified.name})`}
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>

          <div>
            <CheckboxSwitch id='chkShowIdColumn' label='Show ID column'
              checked={showIdColumn} onChange={(e) => {
                setShowIdColumn(e.target.checked)
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
