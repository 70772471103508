import { useState } from 'react'
import { Form } from 'react-bootstrap'
import { Tooltip } from 'react-tooltip'
import { ButtonTW } from 'src/components/Buttons/ButtonTW'
import { EditableField } from 'src/components/EditableField/EditableField'
import { EditableFieldDatepicker } from 'src/components/EditableFieldDatepicker/EditableFieldDatepicker'
import { EditableFieldTypeahead } from 'src/components/EditableFieldTypeahead/EditableFieldTypeahead'
import { useAppContext } from 'src/hooks/useAppContext'
import { TourRequestSi2PaymentType, TourRequestSi2QuotationType, TourRequestType } from 'src/types/types_tourrequest'
import { UserSimpleTeamType } from 'src/types/types_user'
import { dateFormatJpShort } from 'src/util/dateformattools'
import { dateiso_from_jst, getJstMidnightBasedOnJstTime, getTodayJST } from 'src/util/datetools'
import { formatNum } from 'src/util/util_formatnum'
import { EditableFieldTourRequest } from './EditableFieldTourRequest'


interface SalesInfo2CrudProps {
  tourRequest: TourRequestType;
  userListSimple: UserSimpleTeamType[];
  enableEditing: boolean;
  autosaveNewStep: (
    userAction: string,
    updateObj: any,
    sUndoWall: 'u' | 'UNDOWALL', // u = undoable
  ) => Promise<void>;
}

export function SalesInfo2Crud({
  tourRequest,
  userListSimple,
  enableEditing,
  autosaveNewStep,
}: SalesInfo2CrudProps) {

  const { userDetails } = useAppContext()

  const [editedCell, setEditedCell] = useState<string | null>(null) // id of table + '_' + id of invoice being edited + '_' + name of field being edited

  const si2 = tourRequest.salesInfo2

  const totalInvoiced = si2.payments.reduce((acc, current) => {
    return acc + Number(current.paymentAmount) // Number() is to convert '' to 0
  }, 0)

  const totalReceived = si2.payments.reduce((acc, current) => {
    return acc + (current.isPaid ? Number(current.paymentAmount) : 0) // Number() is to convert '' to 0
  }, 0)

  const totalInvoicedButNotReceived = si2.payments.reduce((acc, current) => {
    return acc + (!current.isPaid ? Number(current.paymentAmount) : 0) // Number() is to convert '' to 0
  }, 0)

  const diff = totalInvoiced - (totalReceived + totalInvoicedButNotReceived)
  if (diff !== 0) {
    // something's not right
    console.log('diff not zero', diff)
  }

  let totalAmount: number | null = 0
  let balanceDue: number | null = null
  let balanceNotInvoiced: number | null = null

  let atLeastOneQuotedAmount = false
  for (const quotation of si2.quotations) {
    if (quotation.superseded) {
      continue
    }
    atLeastOneQuotedAmount = true
    totalAmount += quotation.quotedAmount ?? 0
  }

  if (atLeastOneQuotedAmount) {
    balanceDue = totalAmount - totalReceived
    balanceNotInvoiced = totalAmount - totalInvoiced
  } else {
    totalAmount = null
    balanceDue = null
    balanceNotInvoiced = null
  }

  const getPaymentAmountsCache = (newPayments: TourRequestSi2PaymentType[]) => {
    return newPayments
      .filter((p) => p && (p.paymentAmount || p.paymentAmount === 0))
      .map((p) => p.paymentAmount)
  }

  const getPaymentDatesCache = (newPayments: TourRequestSi2PaymentType[]) => {
    return newPayments
      .filter((p) => p && p.datePaymentReceived)
      .map((p) => dateiso_from_jst(p.datePaymentReceived!))
  }


  return (
    <div className='col-all sales-info2'>

      {/* <h6 className='mt-4 mb-2'>Person in charge</h6>

      <div>
        <TypeaheadUserList
          id='inputPersonInCharge'
          multiple={true}
          onChange={(array) => {
            autosaveNewStep(`Set travel designers to ‘${array.map((u) => u.name).join(', ')}’`, {
              'salesInfo2.usersInCharge': array,
            })
          }}
          userList={userListSimple}
          selected={tourRequest.salesInfo2.usersInCharge}
          guidesFirst={false}
          disabled={!enableEditing}
        />
      </div> */}

      <h6 className='mt-4 mb-2'>Quotations</h6>

      <Tooltip id='workflow-widget-tooltip' place='top' variant='dark' />

      <table className='table column-borders mb-1' style={{ width: '50em' }}>
        <thead>
          <tr>
            <th>Date sent to customer</th>
            <th>Quotation amount</th>
            <th>
              Superseded
              {' '}
              <a
                data-tooltip-id='workflow-widget-tooltip'
                data-tooltip-html={"'Superseded' means this quotation was replaced by a subsequent one,<br>"
                  + 'so it can be ignored when calculating the total amount to be invoiced.<br>'
                  + "(Tick 'superseded' instead of deleting a past quotation if you would like<br>"
                  + 'to keep a record of past quotations.)'}
                data-tooltip-place='bottom'
              ><i className='bi bi-info-circle'></i></a>
            </th>
            <th>Memo</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {tourRequest.salesInfo2.quotations.map((quotation, index) => {

            const efprops = {
              tableid: 'quotations',
              rowid: `${index}`,
              isClickableToEdit: enableEditing,
              editedCell,
              setEditedCell,
            }

            return (
              <tr key={index}>
                <td>
                  <EditableFieldDatepicker
                    isClickableToEdit={enableEditing}
                    currentValue_jst0={quotation.dateQuotationSent ? getJstMidnightBasedOnJstTime(quotation.dateQuotationSent) : null} // TODO: sanitize db data and remove getJstMidnightBasedOnJstTime() call
                    callbackCommitChange={(date_jst0) => {
                      autosaveNewStep(`Set quotation [${1 + index}] date to ‘${dateFormatJpShort(date_jst0)}’`, {
                        'salesInfo2.quotations': tourRequest.salesInfo2.quotations.map((q, i) => {
                          if (i === index) {
                            return { ...q, dateQuotationSent: date_jst0 }
                          } else {
                            return q
                          }
                        }),
                      }, 'u')

                      setEditedCell('')
                    }}
                  />
                  {/* {dateFormatJpShort()} */}
                </td>
                <td style={quotation.superseded ? { textDecoration: 'line-through' } : undefined}>
                  <EditableField
                    {...efprops}
                    fieldname='quotedAmount'
                    validationType='number'
                    currentValue={formatNum(quotation.quotedAmount)}
                    callbackCommitChange={(dbvalue) => {
                      autosaveNewStep(`Set quotation [${1 + index}] amount to ‘${formatNum(dbvalue)}’`, {
                        'salesInfo2.quotations': tourRequest.salesInfo2.quotations.map((q, i) => {
                          if (i === index) {
                            return { ...q, quotedAmount: dbvalue }
                          } else {
                            return q
                          }
                        }),
                      }, 'u')

                      setEditedCell('')
                    }}
                  />
                </td>
                <td className='text-center'>
                  <Form.Check type='checkbox' id={`chkQuotationSuperseded${index}`} checked={!!quotation.superseded} style={{ fontSize: '1.5em' }}
                    disabled={!enableEditing}
                    onChange={(e) => {
                      const value = e.target.checked
                      autosaveNewStep(`Set quotation [${1 + index}] superseded to ‘${value ? 'yes' : 'no'}’`, {
                        'salesInfo2.quotations': tourRequest.salesInfo2.quotations.map((q, i) => {
                          if (i === index) {
                            return { ...q, superseded: value }
                          } else {
                            return q
                          }
                        }),
                      }, 'u')
                    }} />
                </td>
                <td>
                  <EditableField
                    {...efprops}
                    fieldname='memorandum'
                    validationType=''
                    currentValue={quotation.memorandum ?? ''}
                    callbackCommitChange={(dbvalue) => {
                      autosaveNewStep(`Set quotation [${1 + index}] memo to ‘${dbvalue}’`, {
                        'salesInfo2.quotations': tourRequest.salesInfo2.quotations.map((q, i) => {
                          if (i === index) {
                            return { ...q, memorandum: dbvalue }
                          } else {
                            return q
                          }
                        }),
                      }, 'u')

                      setEditedCell('')
                    }}
                  />
                </td>
                <td>
                  <ButtonTW variant='blue_outline'
                    disabled={!enableEditing}
                    onClick={() => {
                      const empty = !quotation.quotedAmount
                      if (!empty && !window.confirm('Are you sure?'))
                        return

                      autosaveNewStep(`Delete quotation [${1 + index}]`, {
                        'salesInfo2.quotations': tourRequest.salesInfo2.quotations.filter((q, i) => i !== index),
                      }, 'u')
                    }}>Delete</ButtonTW>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>

      <div>
        Total price (excluding superseded quotations): <b>{formatNum(totalAmount)}</b>
      </div>

      <div className='mb-2'>
        <ButtonTW variant='blue_outline'
          disabled={!enableEditing}
          onClick={() => {
            const quotationNew: TourRequestSi2QuotationType = {
              dateQuotationSent: getTodayJST(),
              quotedAmount: null,
              superseded: false,
              memorandum: '',
            }

            autosaveNewStep('Add quotation', {
              'salesInfo2.quotations': [
                ...tourRequest.salesInfo2.quotations,
                quotationNew,
              ],
            }, 'u')
          }}>Add quotation</ButtonTW>
      </div>


      <h6 className='mt-4 mb-2'>Invoices and payments</h6>

      <table className='table column-borders mb-1'>
        <thead>
          <tr>
            <th>Date invoice sent</th>
            <th>Type</th>{/* [deposit/balance/fullamount] */}
            <th>Percent</th>
            <th>Amount</th>
            <th>Date due</th>
            <th>Payment method</th>
            <th>Payment received?</th>
            <th>Date payment received</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {tourRequest.salesInfo2.payments.map((payment, index) => {

            const efprops = {
              tableid: 'payments',
              rowid: `${index}`,
              isClickableToEdit: enableEditing,
              editedCell,
              setEditedCell,
            }

            const paymentDateMissing = (payment.isPaid && !payment.datePaymentReceived)

            return (
              <tr key={index}>
                <td>
                  <EditableFieldDatepicker
                    isClickableToEdit={enableEditing}
                    currentValue_jst0={getJstMidnightBasedOnJstTime(payment.dateInvoiceSent)} // TODO: sanitize db data and remove getJstMidnightBasedOnJstTime() call
                    callbackCommitChange={(date_jst0) => {
                      autosaveNewStep(`Set invoice [${1 + index}] date sent to ‘${dateFormatJpShort(date_jst0)}’`, {
                        'salesInfo2.payments': tourRequest.salesInfo2.payments.map((p, i) => {
                          if (i === index) {
                            return { ...p, dateInvoiceSent: date_jst0 }
                          } else {
                            return p
                          }
                        }),
                      }, 'u')

                      setEditedCell('')
                    }}
                  />
                </td>
                <td>
                  <EditableFieldTypeahead
                    {...efprops}
                    fieldname='paymentType'
                    currentValue={payment.paymentType}
                    callbackCommitChange={(dbvalue) => {
                      autosaveNewStep(`Set invoice [${1 + index}] type to ‘${dbvalue}’`, {
                        'salesInfo2.payments': tourRequest.salesInfo2.payments.map((p, i) => {
                          if (i === index) {
                            return { ...p, paymentType: dbvalue }
                          } else {
                            return p
                          }
                        }),
                      }, 'u')

                      setEditedCell('')
                    }}
                    typeaheadOptionsList={['Deposit', 'Balance', 'Full amount', 'Additional']}
                    menuMinWidth='9.375rem'
                  />
                </td>
                <td>
                  <EditableField
                    {...efprops}
                    fieldname='percent'
                    validationType='number'
                    currentValue={payment.percent ?? ''}
                    callbackCommitChange={(dbvalue) => {
                      const percent = dbvalue

                      const paymentNew = {
                        ...payment,
                        percent,
                      }

                      if (percent && totalAmount && percent >= 0 && percent <= 100) {
                        paymentNew.paymentAmount = Math.floor(totalAmount * percent / 100)
                      }

                      const newPayments = tourRequest.salesInfo2.payments.map((p, i) => (i === index) ? paymentNew : p)

                      const updateObj = {
                        'salesInfo2.payments': newPayments,
                        paymentAmountsCache: getPaymentAmountsCache(newPayments), // this is needed as we might change the paymentAmount when changing the percentage
                      }

                      autosaveNewStep(`Set invoice [${1 + index}] percent to ‘${percent}${percent ? '%' : ''}’`, updateObj, 'u')

                      setEditedCell('')
                    }}
                    useSpan={true}
                  />
                  {payment.percent ? '%' : ''}
                </td>
                <td>
                  <EditableField
                    {...efprops}
                    fieldname='paymentAmount'
                    validationType='number'
                    currentValue={formatNum(payment.paymentAmount)}
                    callbackCommitChange={(dbvalue) => {
                      // !! what is dbvalue if user input is empty string?
                      const newPayments = tourRequest.salesInfo2.payments.map((p, i) => {
                        if (i === index) {
                          const newPayment: TourRequestSi2PaymentType = { ...p, paymentAmount: dbvalue }
                          return newPayment
                        } else {
                          return p
                        }
                      })

                      const updateObj = {
                        'salesInfo2.payments': newPayments,
                        // we generate new cache here. Note: we cannot generate cache in main autosave function upon saving, because we cannot use the current tourrequest object as it doesn't reflect the user's changes yet.
                        paymentAmountsCache: getPaymentAmountsCache(newPayments),
                      }

                      autosaveNewStep(`Set invoice [${1 + index}] amount to ‘${formatNum(dbvalue)}’`, updateObj, 'u')

                      setEditedCell('')
                    }}
                  />
                </td>
                <td>
                  <EditableFieldDatepicker
                    isClickableToEdit={enableEditing}
                    currentValue_jst0={payment.dateDue}
                    callbackCommitChange={(date_jst0) => {
                      autosaveNewStep(`Set invoice [${1 + index}] date due to ‘${dateFormatJpShort(date_jst0)}’`, {
                        'salesInfo2.payments': tourRequest.salesInfo2.payments.map((p, i) => {
                          if (i === index) {
                            return { ...p, dateDue: date_jst0 }
                          } else {
                            return p
                          }
                        }),
                      }, 'u')

                      setEditedCell('')
                    }}
                  />
                </td>
                <td>
                  <EditableFieldTypeahead
                    {...efprops}
                    fieldname='paymentMethod'
                    currentValue={payment.paymentMethod}
                    callbackCommitChange={(dbvalue) => {
                      autosaveNewStep(`Set invoice [${1 + index}] payment method to ‘${dbvalue}’`, {
                        'salesInfo2.payments': tourRequest.salesInfo2.payments.map((p, i) => {
                          if (i === index) {
                            return { ...p, paymentMethod: dbvalue }
                          } else {
                            return p
                          }
                        }),
                      }, 'u')

                      setEditedCell('')
                    }}
                    typeaheadOptionsList={['Flywire', 'Veritrans', 'Domestic transfer', 'International transfer (Swift)']}
                    menuMinWidth='15rem'
                  />
                </td>
                <td className='text-center'>
                  <Form.Check type='checkbox' id={`chkInvoicePaid${index}`} checked={payment.isPaid} style={{ fontSize: '1.5em' }}
                    disabled={!enableEditing}
                    onChange={(e) => {
                      const dbvalue = e.target.checked
                      autosaveNewStep(`Set invoice [${1 + index}] payment received to ‘${dbvalue ? 'yes' : 'no'}’`, {
                        'salesInfo2.payments': tourRequest.salesInfo2.payments.map((p, i) => {
                          if (i === index) {
                            return { ...p, isPaid: dbvalue }
                          } else {
                            return p
                          }
                        }),
                      }, 'u')
                    }} />
                </td>
                <td className={paymentDateMissing ? 'dateMissing' : undefined}>
                  <EditableFieldDatepicker
                    isClickableToEdit={enableEditing}
                    currentValue_jst0={payment.datePaymentReceived}
                    callbackCommitChange={(date_jst0) => {
                      const newPayments = tourRequest.salesInfo2.payments.map((p, i) => {
                        if (i === index) {
                          const newPayment: TourRequestSi2PaymentType = { ...p, datePaymentReceived: date_jst0 }
                          return newPayment
                        } else {
                          return p
                        }
                      })

                      // we generate new cache here. Note: we cannot generate cache in main autosave function upon saving, because we cannot use the current tourrequest object as it doesn't reflect the user's changes yet.

                      const updateObj = {
                        'salesInfo2.payments': newPayments,
                        paymentDatesCache: getPaymentDatesCache(newPayments),
                      }

                      autosaveNewStep(`Set invoice [${1 + index}] date payment received to ‘${dateFormatJpShort(date_jst0)}’`, updateObj, 'u')

                      setEditedCell('')
                    }}
                  />
                  {paymentDateMissing && <b>Please input payment date</b>}
                </td>
                <td>
                  <ButtonTW variant='blue_outline'
                    disabled={!enableEditing}
                    onClick={(e) => {
                      if (!window.confirm('Are you sure?'))
                        return

                      const newPayments = tourRequest.salesInfo2.payments.filter((p, i) => i !== index)

                      autosaveNewStep(`Delete invoice/payment [${1 + index}]`, {
                        'salesInfo2.payments': newPayments,
                        // we generate new cache here. Note: we cannot generate cache in main autosave function upon saving, because we cannot use the current tourrequest object as it doesn't reflect the user's changes yet.
                        paymentAmountsCache: getPaymentAmountsCache(newPayments),
                        paymentDatesCache: getPaymentDatesCache(newPayments),
                      }, 'u')
                    }}>Delete</ButtonTW>
                </td>
              </tr>
            )
          })}

        </tbody>
      </table>
      <div>
        <ButtonTW variant='blue_outline'
          disabled={!enableEditing}
          onClick={() => {
            const paymentNew: TourRequestSi2PaymentType = {
              dateInvoiceSent: getTodayJST(),
              paymentType: '',
              percent: null,
              paymentAmount: null,
              dateDue: null,
              paymentMethod: '',
              isPaid: false,
              datePaymentReceived: null,
              freeeStatus: null,
            }

            autosaveNewStep('Add invoice/payment', {
              'salesInfo2.payments': [
                ...tourRequest.salesInfo2.payments,
                paymentNew,
              ],
            }, 'u')
          }}>Add invoice/payment</ButtonTW>
      </div>


      <h6 className='mt-4 mb-2'>Invoicing summary</h6>

      <div className='payment-summary-table'>

        <div>
          Total price:
        </div>
        <div></div>
        <div className='text-end'>
          <b>{formatNum(totalAmount)}</b>
        </div>

        <div>
          Total invoiced:
        </div>
        <div>▲</div>
        <div className='text-end underline'>
          <b>{formatNum(totalInvoiced)}</b>
        </div>

        <div>
          Balance not yet invoiced:
        </div>
        <div></div>
        <div className='text-end'>
          <b className={balanceNotInvoiced ? 'text-red' : 'text-green'}>{formatNum(balanceNotInvoiced)}</b>
        </div>

      </div>


      <h6 className='mt-4 mb-2'>Payment summary</h6>

      <div className='payment-summary-table'>

        <div>
          Total price:
        </div>
        <div></div>
        <div className='text-end'>
          <b>{formatNum(totalAmount)}</b>
        </div>

        <div>
          Total received:
        </div>
        <div>▲</div>
        <div className='text-end underline'>
          <b>{formatNum(totalReceived)}</b>
        </div>

        <div>
          Balance due:
        </div>
        <div></div>
        <div className='text-end'>
          <b className={balanceDue ? 'text-red' : 'text-green'}>{formatNum(balanceDue)}</b>
        </div>

      </div>



      <h6 className='mt-4 mb-2'>Note</h6>
      <EditableFieldTourRequest
        tableid='tourrequest'
        rowid='salesInfo2'
        isClickableToEdit={enableEditing}
        editedCell={editedCell}
        isTextArea={true}
        textareaRows={5}
        setEditedCell={setEditedCell}
        fieldname='note'
        validationType=''
        currentValue={tourRequest.salesInfo2.note ?? ''}
        callbackCommitChange={(dbvalue) => {
          autosaveNewStep(`Set sales info note to ‘${dbvalue}’`, {
            'salesInfo2.note': dbvalue,
          }, 'u')

          setEditedCell('')
        }}
      />

    </div>
  )
}
